import { useCallback, useEffect, useState } from "react";
import { CATEGORIES } from "./enums/ev_enums";
import Icon from "@mdi/react";
import { mdiArrowBottomLeft, mdiPencil } from "@mdi/js";
import ModalDialog from "../../../new_components/common/modal";
import FileDropZone from "../../../../../../new_components/common/file_drop_zone";
import { parse } from "papaparse";
import {
  ToastType,
  useToast,
} from "../../../../../../../context_providers/toast/toast_ctx";
import { requiredFields } from "./enums/required_fields";
import {
  getEVDataRepo,
  postBulkEVData,
  postEVehicle,
  putEVehicle,
  putEVehicleStatus,
} from "../../../../services/ev_service";
import { EVehicle, EVehicleListFilter } from "./interface/e_vehicle_interface";
import ToggleButton from "../../../new_components/form_components/toggle_button";
import Pagination from "../../../../../../new_components/common/pagination";
import { ceil, debounce } from "lodash";
import EVehicleDetailSection from "./components/ev_detail_section";
import SearchFieldWidget from "../../../../../../new_components/search_field";
import LoadingWidget from "../../../../../../../context_providers/modal/loader";
import { toQueryString } from "../../../../../../../service/api_client";

const defaultFilter: EVehicleListFilter = {
  page: 1,
  count: 20,
  status: true,
};

function EvListing() {
  const { showToast } = useToast();
  const [category, setCategory] = useState<string>(CATEGORIES[0].key);
  const [count, setCount] = useState(defaultFilter.count);
  const [page, setPage] = useState(defaultFilter.page);
  const [total, setTotal] = useState(0);
  const [eVehicles, setEvehicles] = useState<EVehicle[]>([]);
  const [showImport, setShowImport] = useState(false);
  const [showCreateVehicle, setShowCreateVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<EVehicle>();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<EVehicleListFilter>(defaultFilter);

  const createVehicle = async (data: EVehicle) => {
    setLoading(true);
    const res = await postEVehicle(data);
    if (res.success) {
      setLoading(false);
      // setVehicles((old) => [res.data, ...old]);
      showToast({ type: ToastType.success, text: res.message });
      setShowCreateVehicle(false);
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error });
    }
  };

  const getEVehicles = useCallback(
    async (prop?: EVehicleListFilter) => {
      console.log("get vehicles with props called", prop);
      let search: string | undefined = prop?.search;
      setLoading(true);
      const res = await getEVDataRepo({ category, search, page, count });

      if (res.success) {
        setEvehicles(res.data);
        setTotal(res.total);
        console.log(eVehicles);
        setLoading(false);
      } else {
        setLoading(false);
        showToast({
          type: ToastType.error,
          text: res.error,
        });
      }
    },
    [category, page, count, eVehicles, showToast]
  );

  const updateVehicle = async (data: EVehicle) => {
    setLoading(true);
    const res = await putEVehicle(data);
    if (res.success) {
      setLoading(false);
      showToast({ type: ToastType.success, text: res.message });
      setShowCreateVehicle(false);
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.error });
    }
  };

  const onChangeVisibility = async (id: string, status: boolean) => {
    setLoading(true);
    const res = await putEVehicleStatus({ id, status });
    if (res.success) {
      setLoading(false);
      getEVehicles();
      showToast({ type: ToastType.success, text: res.message });
    } else {
      setLoading(false);
      showToast({ type: ToastType.error, text: res.message });
    }
  };

  const debouncedHandler = useCallback(
    debounce((prop: EVehicleListFilter) => {
      if (prop) getEVehicles(prop);
      else setEvehicles([]);
    }, 100),
    [filter, category, page]
  );
  // useEffect(() => {
  //   getEVehicles();
  // }, [getEVehicles]);

  useEffect(() => {
    // Ensure page is within bounds
    const maxPage = ceil(total / count);
    if (page > maxPage && maxPage > 0) {
      setPage(maxPage);
    }
  }, [total, page, count]);

  useEffect(() => {
    debouncedHandler(filter);
  }, [debouncedHandler, filter, page]);

  const bulkUploadVehicle = async (file: File) => {
    const res = await postBulkEVData(file);
    if (res.success) {
      showToast({
        type: ToastType.success,
        text: res.message,
      });
      getEVehicles();
    } else {
      showToast({
        type: ToastType.error,
        text: res.error,
      });
    }
  };

  const handleFileDrop = (files: File[]) => {
    if (files.length === 0) return;

    const file = files[0];

    parse<EVehicle>(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.trim(),
      complete: (result) => {
        const rows = result.data;

        if (rows.length === 0) {
          showToast({
            type: ToastType.warning,
            text: "No records found in the file!",
          });
          return;
        }
        const missingFields = requiredFields.filter(
          (field) => !Object.keys(rows[0]).includes(field)
        );

        if (missingFields.length > 0) {
          showToast({
            type: ToastType.error,
            text: `Missing fields in the uploaded CSV: ${missingFields.join(
              ", "
            )}`,
          });
          return;
        }
        bulkUploadVehicle(file);
        setShowImport(false);
      },
      error: (err) => {
        showToast({
          type: ToastType.error,
          text: "Error parsing the file. Please check its format.",
        });
      },
    });
  };

  return (
    <>
      <ModalDialog
        onClose={() => setShowCreateVehicle(false)}
        show={showCreateVehicle}
        title="Add vehicle"
      >
        <div style={{ height: "85vh", width: "50vw" }} className="p-5 ">
          <EVehicleDetailSection createVehicle={createVehicle} />
        </div>
      </ModalDialog>
      {selectedVehicle && (
        <ModalDialog
          onClose={() => setSelectedVehicle(undefined)}
          show={selectedVehicle !== undefined}
          title="Edit vehicle"
        >
          <div
            style={{ height: "85vh", width: "50vw" }}
            className="    overflow-y-scroll p-5 "
          >
            <EVehicleDetailSection
              data={selectedVehicle}
              createVehicle={updateVehicle}
            />
          </div>
        </ModalDialog>
      )}

      {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Inventory"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note:</h2>
            <p>Sheet should have the following headers:</p>
            <p className="font-semibold text-purple-500">
              {requiredFields.join(", ")}
            </p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone accept=".csv" onDrop={handleFileDrop} />
          </div>
        </ModalDialog>
      )}
      <div className="rounded-3xl p-1 bg-gray-100 flex flex-wrap justify-between gap-4 items-center">
        <div className="rounded-3xl p-1 bg-gray-100 flex flex-wrap gap-4 items-center">
          {CATEGORIES.map((item) => (
            <div
              key={item.key}
              onClick={() => setCategory(item.key)}
              className={`px-3 py-2 cursor-pointer ${
                category === item.key
                  ? "rounded-3xl bg-green-300 text-white transition duration-300 ease-in-out"
                  : ""
              }`}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <SearchFieldWidget
            defaulValue={filter.search}
            onClear={() => {
              setFilter((o) => ({ ...o, search: "", page: 1 }));
            }}
            onSubmit={(val) => {
              setFilter((o) => ({
                ...o,
                search: val,
                page: 1,
              }));
            }}
          />
          <div
            onClick={() => setShowCreateVehicle(true)}
            className="flex items-center bg-gray-100 px-3 py-2 rounded font-bold hover:bg-gray-400 hover:text-white cursor-pointer"
          >
            Add Vehicle
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </span>
          </div>
          <button
            onClick={() => setShowImport(true)}
            className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center "
          >
            <Icon path={mdiArrowBottomLeft} size="15" />
            Import
          </button>{" "}
          <div className="flex justify-end   text-sm items-center gap-1 "></div>
        </div>
        {/* <div className="flex justify-end   text-sm items-center gap-1 ">
         
        </div> */}
      </div>
      {eVehicles.length > 0 ? (
        <div className="block w-full overflow-y-scroll overflow-x-auto border-t border-blue-50">
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead className="thead-light sticky top-0 select-none z-10">
              <tr className="bg-gray-200">
                <th className="px-5 py-2">Segment type</th>
                <th className="px-5 py-2">Brand</th>
                <th className="px-5 py-2">Model</th>
                <th className="px-5 py-2">Category</th>
                <th className="px-5 py-2">Battery Capacity</th>
                {/* <th className="px-5 py-2">Charging Standard</th> */}
                <th className="px-5 py-2">Connector Type</th>
                <th className="px-5 py-2">Range</th>
                <th className="px-5 py-2">Status</th>
                <th className="px-3 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {eVehicles.map((vehicle, i) => (
                <tr key={i} className="items-center border-b z-0">
                  <td className="text-center px-5 py-2">{vehicle.type}</td>
                  <td className="text-center px-5 py-2">{vehicle.brand}</td>
                  <td className="text-center px-5 py-2 ">{vehicle.model}</td>
                  <td className="text-center px-5 py-2">{vehicle.category}</td>
                  <td className="text-center px-5 py-2">
                    {vehicle.battery_capacity ?? "--"}
                  </td>
                  {/* <td className="text-center px-5 py-2">
                    {vehicle.charging_standard ?? "--"}
                  </td> */}
                  <td className="text-center px-5 py-2">
                    {vehicle.connector_type ?? "--"}
                  </td>
                  <td className="text-center px-5 py-2">
                    {vehicle.range ?? "--"}
                  </td>
                  <td className="text-center px-5 py-2">
                    <ToggleButton
                      onToggle={(v) => {
                        onChangeVisibility(vehicle._id!, v);
                      }}
                      value={vehicle.status}
                      trueLabel="Live"
                      falseLabel="Offline"
                    />
                  </td>
                  <td className="flex justify-center items-center px-2 py-2">
                    <button
                      onClick={() => setSelectedVehicle(vehicle)}
                      className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
                    >
                      <Icon path={mdiPencil} size="15" />
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <p>No electric vehicles found.</p>
        </div>
      )}
      <Pagination
        page={page}
        itemCount={eVehicles.length}
        total={total}
        onChange={(p) => setPage(p)}
        count={count}
      />
      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
}

export default EvListing;
