import { FunctionComponent, useState } from "react";
import { EVehicle } from "../interface/e_vehicle_interface";
import { customEVehicleFormToObject } from "../../../../../infrastructure/utils/formDataToJson";
import { isNaN } from "lodash";

interface VehicleDetailSectionProps {
  createVehicle: (data: EVehicle) => void;
  data?: EVehicle;
}

const EVehicleDetailSection: FunctionComponent<VehicleDetailSectionProps> = (
  props
) => {
  const [data, setData] = useState<EVehicle | undefined>(
    props.data ? { ...props.data } : undefined
  );

  return (
    <div className="items-center rounded shadow grid grid-cols-1 gap-3 p-5 m-5 bg-white">
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const d = customEVehicleFormToObject(
            new FormData(e.currentTarget)
          ) as EVehicle;
          d.battery_capacity = d.battery_capacity ?? "";
          if (isNaN(d.battery_capacity)) {
            d.battery_capacity = "";
          }
          if (isNaN(d.charging_standard)) {
            d.charging_standard = "";
          }
          if (isNaN(d.connector_type)) {
            d.connector_type = "";
          }
          if (isNaN(d.range)) d.range = "";
          console.log(data?._id);
          if (props.data) {
            d.id = props.data._id;
          }
          console.log("d", d);
          props.createVehicle(d);
        }}
      >
        {/* Brand */}
        <div className="flex flex-col mb-4">
          <label htmlFor="brand" className="text-lg">
            Brand
          </label>
          <input
            id="brand"
            name="brand"
            type="text"
            defaultValue={props.data?.brand}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
            placeholder="Enter the Brand name"
            required
          />
        </div>

        {/* Model */}
        <div className="flex flex-col mb-4">
          <label htmlFor="model" className="text-lg">
            Model
          </label>
          <input
            id="model"
            name="model"
            type="text"
            defaultValue={props.data?.model}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
            placeholder="Enter the model name"
            required
          />
        </div>

        {/* Type */}
        <div className="flex flex-col mb-4">
          <label htmlFor="type" className="text-lg">
            Type
          </label>
          <select
            id="type"
            name="type"
            defaultValue={props.data?.type}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
          >
            {["Scooter", "Bike", "Car", "Truck", "3 Wheeler", "Bus"].map(
              (type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              )
            )}
          </select>
        </div>

        {/* Category */}
        <div className="flex flex-col mb-4">
          <label htmlFor="category" className="text-lg">
            Category
          </label>
          <select
            id="category"
            name="category"
            defaultValue={props.data?.category}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
          >
            {["2W", "3W", "4W", "Others"].map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Battery Capacity */}
        <div className="flex flex-col mb-4">
          <label htmlFor="battery_capacity" className="text-lg">
            Battery Capacity
          </label>
          <input
            id="battery_capacity"
            name="battery_capacity"
            type="text"
            defaultValue={props.data?.battery_capacity}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
            placeholder="Enter the battery capacity"
          />
        </div>

        {/* Connector Type */}
        <div className="flex flex-col mb-4">
          <label htmlFor="connector_type" className="text-lg">
            Connector Type
          </label>
          <input
            id="connector_type"
            name="connector_type"
            type="text"
            defaultValue={props.data?.connector_type}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
            placeholder="Enter the connector type"
          />
        </div>

        {/* Range */}
        <div className="flex flex-col mb-4">
          <label htmlFor="range" className="text-lg">
            Range
          </label>
          <input
            id="range"
            name="range"
            type="text"
            defaultValue={props.data?.range}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
            placeholder="Enter the range"
          />
        </div>
        <div className="flex flex-col mb-4">
          <label htmlFor="image_url" className="text-lg">
            Image URL
          </label>
          <input
            id="image_url"
            name="image_url"
            type="url"
            defaultValue={props.data?.image_url}
            // onChange={handleChange}
            className="p-2 bg-gray-100 rounded focus:outline-none"
            placeholder="Enter the link"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center my-5">
          <button
            type="submit"
            className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EVehicleDetailSection;
