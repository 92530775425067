import { BaseModel } from "../../../service/models/base";

export const po_payment_req_status = {
  pending: "Pending",
  accepted: "Accepted",
  rejected: "Rejected",
};
export interface PaymentRequestModel extends BaseModel {
  po_no: string;
  amount: number;
  due_date: Date;
  description: string;
  status: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  reason?: string;
}

export const payment_request_default: PaymentRequestModel = {
  po_no: "",
  amount: 0,
  due_date: new Date(),
  description: "",
  status: po_payment_req_status.pending,
};

export const po_payments_req_status = {
  pending: "Pending for Approval", //when created PR
  approved: "Approved",
  onHold: "On Hold",
  rejected: "Rejected",
  updated: "Update Pending", //even though updated status id on Pending.
};

export const payment_status = {
  pending: "Pending",
  paid: "Paid",
  failed: "Payment Failed",
};

export interface PaymentRequestAgainstInvoiceModel extends BaseModel {
  po_no: string;
  due_date: Date;
  payment_date?: Date;
  invoice_no: string;
  invoice_date: Date;
  invoice_amount: number;
  payment_amount: number;
  category_of_expence: string;
  payment_mode?: string;
  no_of_intallations: number;
  customer_location: string;
  attachments: PaymentAttachmentModel[];
  notes: string;
  pr_status: string;
  payment_status: string;
  spoc?: string;
  utr?: string;
  swift?: string;
  gst_no?: number;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  onhold_reason?: string;
  reject_reason?: string;
  paid_remarks?: string;
  failed_remarks?: string;
  vendor?: string;
  project?: string;
  po_date?: Date;
  po_approval?: string;
  po_id?: string;
}

export interface PaymentRequestAdvanceModel extends BaseModel {
  po_no: string;
  due_date: Date;
  payment_date?: Date;
  percentage: number;
  payment_amount: number;
  pi_no: string;
  pi_date: Date;
  pi_amount: number;
  payment_mode?: string;
  attachments: PaymentAttachmentModel[];
  notes: string;
  pr_status: string;
  payment_status: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  onhold_reason?: string;
  reject_reason?: string;
  paid_remarks?: string;
  failed_remarks?: string;
  spoc?: string;
  utr?: string;
  swift?: string;
  gst_no?: number;
  vendor?: string;
  project?: string;
  po_date?: Date;
  po_approval?: string;
  po_id?: string;
}

export const PaymentModeOptions = {
  bankTransfer: 'Bank Transfer',
  creditCard: "Credit Card",
  pettyCash: "Petty Cash"
}

export const CategoryOfExpenseOptions = {
  raw_material: "Raw Material",
  installation: "Installation",
  installation_and_earthing: "Installation and Earthing",
  installation_with_material: "Installation with material"
}

export type PreSignedUrlReturnModel = [string, File, PaymentAttachmentModel];

// Default state values for both models
export const paymentRequestAdvanceDefault = {
  po_no: "",
  due_date: new Date(),
  payment_amount: 0,
  pi_no: "",
  pi_date: new Date(),
  pi_amount: 0,
  attachments: [],
  notes: "",
  percentage: 0,
  pr_status: "pending",
  payment_status: "pending",
};

export const paymentRequestAgainstInvoiceDefault = {
  po_no: "",
  due_date: new Date(),
  invoice_no: "",
  invoice_date: new Date(),
  invoice_amount: 0,
  payment_amount: 0,
  category_of_expence: "",
  no_of_intallations: 0,
  customer_location: "",
  attachments: [],
  notes: "",
  pr_status: "pending",
  payment_status: "pending",
};

export interface PaymentAttachmentModel extends BaseModel {
  id: string;
  category: string;
  type: string;
  url: string;
}
