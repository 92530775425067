import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ToastType, useToast } from "../../context_providers/toast/toast_ctx";
import { debounce } from "lodash";
import AutoCompleteField from "./common/autocomplete";
import { SearchAssets } from "../../service/repos/hrm/hrm_repo";

interface AssetSearchWidgetProps {
  onSelect: (data: any) => void;
  placeholder?: string;
  emp?: { uid: string; name: string };
  disabled?: boolean;
  withBtn?: boolean;
}

const AssetSearchWidget: FunctionComponent<AssetSearchWidgetProps> = ({
  onSelect,
  placeholder,
  disabled = false,
}) => {
  const { showToast } = useToast();
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  const [assets, setAssets] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search && edit) {
      debouncedHandler(search);
    }
  }, [search]);

  const searchAssets = async (search: string) => {
    setLoading(true);
    const res = await SearchAssets(search);
    if (res.success) {
      setAssets(res.data);
    } else {
      setAssets([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) searchAssets(query);
    }, 500),
    [search]
  );

  return (
    <div className="flex gap-1 items-center w-full">
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={search}
          placeholder={placeholder ?? "Assets"}
          onSelect={(val) => {
            console.log("AutoCompleteField Componenet", val.value);
            setEdit(false);
            setSearch(val.value);
            onSelect(val.value);
          }}
          suggestions={assets.map((e) => ({
            label: (
              <div className="text-xs">
                <b>{e.asset}</b>
              </div>
            ),

            value: e.asset,
          }))}
          onChange={(str) => {
            setSearch(str);
            onSelect(str);
          }}
        />
      ) : (
        <div
          className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border"
          onClick={() => {
            if (!disabled) {
              setEdit(true);
              console.log(disabled);
            }
          }}
        >
          {search || placeholder}
        </div>
      )}
    </div>
  );
};

export default AssetSearchWidget;
