import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../../../../../../context_providers/auth/authProvider";
import { MODULE, ACTION } from "../../../../../../../utils/enums";
import { urls } from "../../../../../../../utils/urls";
import ModalDialog from "../../../../../../new_components/common/modal";
import Pagination from "../../../../../../new_components/common/pagination";
import { VehicleModel } from "../../../../infrastructure/models/car_model";
import { URLS } from "../../../../infrastructure/utils/urls";
import {
  deletetVehicle,
  getVehiclesRepo,
  postVehicleDetail,
  putVehicleDetail,
  putVehicleVisibility,
} from "../../../../services/vehicle_service";
import { useAppSelector } from "../../../../store/hooks";
import DropDownMenu from "../../../new_components/common/drop_down_menu";
import ToggleButton from "../../../new_components/form_components/toggle_button";
import VehicleDetailSection from "./components/vehicle_detail";
import { mdiArrowBottomLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { ToastType } from "../../../../../../../context_providers/toast/toast_ctx";
import FileDropZone from "../../../../../../new_components/common/file_drop_zone";
import { parse } from "papaparse";
interface VehicleListingProps {}

const VehicleListing: FunctionComponent<VehicleListingProps> = () => {
  const { user, isAuthorised } = useAuth();
  const history = useHistory();
  const [showCreateVehicle, setShowCreateVehicle] = useState(false);

  const [category, setCategory] = useState("bike");
  const [count, setCount] = useState(20);
  const [showImport, setShowImport] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [vehicles, setVehicles] = useState<VehicleModel[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleModel>();
  const getVehicles = async () => {
    const res = await getVehiclesRepo({ category, page, count });

    if (res.success) {
      setVehicles(res.data);
      setTotal(res.total);
    } else alert(res.error);
  };
  useEffect(() => {
    getVehicles();
  }, [category, page, count]);
  const createVehicle = async (data: FormData) => {
    const res = await postVehicleDetail(data);
    if (res.success) {
      getVehicles();
      // setVehicles((old) => [res.data, ...old]);
      alert(res.message);
      setShowCreateVehicle(false);
    } else {
      alert(res.error);
    }
  };
  const updateVehicle = async (data: FormData) => {
    const res = await putVehicleDetail(data);
    if (res.success) {
      getVehicles();
      // setVehicles((old) => old.map(v=>v.id===selectedVehicle?.id?));
      alert(res.message);
      setShowCreateVehicle(false);
    } else {
      alert(res.error);
    }
  };
  const onDelete = async (vehicle_id: string) => {
    const str = "DELETE";
    const confirm = prompt(`Enter ${str} to confirm (note : Cannot be undo)`);
    if (confirm !== str) return;
    const res = await deletetVehicle(vehicle_id);
    if (res.success) {
      alert(res.message);
      getVehicles();
    } else {
      alert(res.error);
    }
  };
  const onChangeVisibility = async (id: string, active: boolean) => {
    const res = await putVehicleVisibility({ id, active });
    if (res.success) {
      alert(res.message);
      getVehicles();
    } else {
      alert(res.error);
    }
  };
  function showToast(arg0: { type: ToastType; text: string; }) {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <ModalDialog
        onClose={() => setShowCreateVehicle(false)}
        show={showCreateVehicle}
        title="Add vahicle"
      >
        <div
          style={{ height: "85vh", width: "90vw" }}
          className=" w-screen  overflow-y-scroll p-5 "
        >
          <VehicleDetailSection createVehicle={createVehicle} />
        </div>
      </ModalDialog>
      {/* Import Dialogue */}
      {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Inventory"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              device_id,product_id,product_name,warehouse_id,mobile_number,
              module_imei,sim_imsi,plan_name,sim_no,sim_status,
              status,responsible_email
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<any>(files[0], {
                  transformHeader(header, index) {
                    return header.trim();
                  },
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    // console.log({ res });

                    if (res.length === 0) {
                      showToast({
                        type: ToastType.warning,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("device_id") === -1 ||
                      keys.indexOf("product_id") === -1 ||
                      keys.indexOf("warehouse_id") === -1
                    ) {
                      showToast({
                        type: ToastType.warning,
                        text: "Required fields are [device_id,product_id,product_name,warehouse_id]",
                      });
                      return;
                    }
                    
                    

                    // Bulk upload
                    setShowImport(false);
                    // onSubmit_Bulk(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      {selectedVehicle && (
        <ModalDialog
          onClose={() => setSelectedVehicle(undefined)}
          show={selectedVehicle !== undefined}
          title="Edit vahicle"
        >
          <div
            style={{ height: "85vh", width: "90vw" }}
            className="    overflow-y-scroll p-5 "
          >
            <VehicleDetailSection
              data={selectedVehicle}
              createVehicle={updateVehicle}
            />
          </div>
        </ModalDialog>
      )}
      <div className="flex justify-between my-3">
        {/* <ToggleButton
          onToggle={(v) => setCategory(v ? "bike" : "car")}
          trueLabel="Bike"
          falseLabel="Car"
          value={category === "bike"}
        /> */}
        <div className="rounded-3xl p-1 bg-gray-100 flex items-center">
          <div
            onClick={() => {
              if (category !== "bike") setCategory("bike");
            }}
            className={` px-3 py-2 cursor-pointer ${
              category === "bike"
                ? "rounded-3xl bg-green-300 text-white transition duration-300 ease-in-out"
                : ""
            }`}
          >
            Bikes
          </div>
          <div
            onClick={() => {
              if (category !== "car") setCategory("car");
            }}
            className={`px-3 py-2  cursor-pointer ${
              category === "car"
                ? "rounded-3xl bg-green-300 text-white transition duration-300 ease-in-out"
                : ""
            }`}
          >
            Cars
          </div>
          <div
            onClick={() => {
              if (category !== "3-wheeler") setCategory("3-wheeler");
            }}
            className={`px-3 py-2  cursor-pointer ${
              category === "3-wheeler"
                ? "rounded-3xl bg-green-300 text-white transition duration-300 ease-in-out"
                : ""
            }`}
          >
            3-wheeler
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2">
          {/* Add vehicle Button */}
          <div
            onClick={() => setShowCreateVehicle(true)}
            className="flex items-center bg-gray-100 px-3 py-2 rounded font-bold hover:bg-gray-400 hover:text-white cursor-pointer"
          >
            Add Vehicle
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </span>
          </div>
          {/* Import Vehicle button */}
          <div>
            <button
              onClick={() => setShowImport(true)}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center "
            >
              <Icon path={mdiArrowBottomLeft} size="15"></Icon>
              Import
            </button>
          </div>
        </div>
      </div>
      <div
        style={{ height: "85vh" }}
        className="block w-full overflow-y-scroll overflow-x-auto border-t border-blue-50"
      >
        <table className="items-center w-full bg-transparent border-collapse ">
          <thead className="thead-light sticky top-0 select-none z-10">
            <tr className="bg-gray-200">
              <th className="px-2 py-2">Feature image</th>
              <th className="px-5 py-2">Brand</th>
              <th className="px-5 py-2">Model</th>
              <th className="px-5 py-2">Category</th>
              <th className="px-5 py-2">Segment type</th>
              <th className="px-5 py-2">Display price</th>
              <th className="px-5 py-2">Status</th>
              <th className="px-3 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle, i) => (
              <tr className="border-b z-0">
                <td className=" py-2 px-2">
                  <img
                    src={vehicle.feature_image?.url}
                    alt=""
                    className="h-24 w-24 rounded-full bg-gray-100"
                  />
                </td>
                <td className="px-5 py-2">{vehicle.brand}</td>

                <td className="px-5 py-2 ">{vehicle.model}</td>
                <td className="px-5 py-2">{vehicle.category}</td>
                <td className="px-5 py-2">{vehicle.segment_type}</td>
                <td className="px-5 py-2">{vehicle.display_price}</td>
                <td className="px-5 py-2">
                  <ToggleButton
                    onToggle={(v) => onChangeVisibility(vehicle.id!, v)}
                    value={vehicle.active}
                    trueLabel="Live"
                    falseLabel="Offline"
                  />
                </td>
                <td className="px-2 py-2">
                  <DropDownMenu
                    title={
                      <div className="p-2 m-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </div>
                    }
                    options={[
                      <div
                        onClick={() =>
                          history.push(urls.varientListing, {
                            vehicle: vehicle,
                          })
                        }
                        className="rounded border-b px-2 py-1 hover:bg-gray-100 cursor-pointer"
                      >
                        Varients
                      </div>,
                      <div
                        onClick={() => setSelectedVehicle(vehicle)}
                        className="rounded border-b px-2 py-1 hover:bg-gray-100  cursor-pointer"
                      >
                        Edit
                      </div>,
                      <>
                        {isAuthorised({
                          module: MODULE.content,
                          action: [ACTION.DELETE],
                        }) && (
                          <div
                            onClick={() => onDelete(vehicle.id!)}
                            className="rounded border-b px-2 py-1 flex items-center text-red-500 hover:bg-red-800 hover:text-white  cursor-pointer"
                          >
                            Delete
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 ml-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </div>
                        )}
                      </>,
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>{" "}
      <Pagination
        page={page}
        itemCount={vehicles.length}
        total={total}
        onChange={(p) => {
          setPage(p);
        }}
        count={count}
      />
    </>
  );
};

export default VehicleListing;
