import { mdiRefresh, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModal } from "../../../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { ProcurementProjectModel } from "../../model/project_model";
import { fetchGet, toQueryString } from "../../../../../service/api_client";
import LoadingWidget from "../../../../../context_providers/modal/loader";
import { FieldType } from "../../../../../procurement/components/create_view/form_field";
import TableView from "../../../../../procurement/components/table_comp";
import SearchFieldWidget from "../../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { currencyFormat } from "../../../../../utils/orders_utils";
import { urls } from "../../../../../utils/urls";
import { PurchaseOrderModel } from "../../../model/purchase_order_model";

interface ProjectListScreenProps {}

const ProjectListScreen: FunctionComponent<ProjectListScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project`;
  const { showModal, onClose } = useModal();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<ProcurementProjectModel[]>([]);
  const [search, setSearch] = useState("");
  //   const [fromDate, setFromDate] = useState(new Date());
  //   const [toDate, setToDate] = useState(new Date());
  //   useEffect(() => {
  //     const now = new Date();
  //     let from = new Date(new Date(now).setHours(0, 0, 0, 0));
  //     from = new Date(from.setDate(1));
  //     let to = new Date(new Date(now).setHours(23, 59, 59, 999));
  //     to = new Date(to.setMonth(now.getMonth() + 1));
  //     to = new Date(to.setDate(0));
  //     setFromDate(from);
  //     setToDate(to);
  //   }, []);
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    // fromDate: Date;
    // toDate: Date;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        // fromDate: Date;
        // toDate: Date;
      }) => {
        if (prop) fetchData(prop);
        else setData([]);
      },
      100
    ),
    []
  );

  useEffect(() => {
    debouncedHandler({ page, count, search });
  }, [page, search]);
  const navaigation = useHistory();
  const onSelect = (d: ProcurementProjectModel) => {
    navaigation.push(urls.procurement_project + "/update/" + d.id);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex flex-wrap justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                Procurement Project
              </p>
              <button
                title="Refersh"
                onClick={() => debouncedHandler({ page, count, search })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex mt-2  justify-end   text-sm items-center gap-1 ">
              {/* <div className="text-sm flex items-center gap-2">
                <div className="flex items-center  gap-1">
                  <label htmlFor="fromDate" className="">
                    From
                  </label>
                  <input
                    type="date"
                    name=""
                    id="fromDate"
                    className="p-1 rounded "
                    value={moment(fromDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                      setFromDate(from);
                    }}
                  />
                </div>
                <div className="flex items-center  gap-1">
                  <label htmlFor="toDate" className="">
                    To
                  </label>
                  <input
                    type="date"
                    name=""
                    id="toDate"
                    className="p-1 rounded "
                    value={moment(toDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const now = UTCToLocalDate(e.target.value)!;
                      const to = new Date(
                        new Date(now).setHours(23, 59, 59, 999)
                      );
                      setToDate(to);
                    }}
                  />
                </div>
              </div> */}
              <SearchFieldWidget
                defaulValue={search}
                onClear={() => {
                  setSearch("");
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setPage(1);
                  setSearch(val);

                  // setTimeout(() => {
                  //   setSearch(val);
                  // }, 10);
                }}
              />
              <div
                onClick={() => {
                  navaigation.push(urls.procurement_project + "/create");
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div>
            </div>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>

        <TableView
          headers={[
            {
              key: "id",
              title: "Project ID #",
              type: FieldType.string,
            },
            {
              key: "created_at",
              title: "Date",
              type: FieldType.date,
            },

            {
              key: "name",
              title: "Name",
              type: FieldType.string,
            },
            {
              key: "status",
              title: "Status",
              type: FieldType.string,
            },

            {
              key: "amount",
              title: "Budget",
              type: FieldType.currency,
            },
            {
              key: "spent",
              title: "Spent",
              type: FieldType.currency,
            },
          ]}
          rows={data.map((data, i) => ({
            data,
            values: [
              data.id,
              data.created_at,
              data.name,
              data.approval_status,
              currencyFormat(data.amount),
              currencyFormat(data.spent),
            ],
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default ProjectListScreen;
