export const ItemTypeList: string[] = ["Product", "Service"];

export const ServiceItemCreationSubCategory: string[] = [
  "JobWork",
  "Maintenance and Repair Services",
  "Installation Services",
  "Consulting Services",
  "Logistics and Transportation",
  "Facility Management",
  "IT Services",
  "Training Services",
  "Professional Services",
  "Capex",
  "Software/License",
  "Others",
];

export const ProductCreationCategory: string[] = [
  "Electrical",
  "Electronics",
  "Others",
];

export const ProductItemCreationSubCategoryElectricRawMaterials: string[] = [
  "Raw Materials",
  "Components",
  "Finished Goods",
  "Semi finished goods",
  "Tools and Equipment",
  "Consumables",
  "Spare Parts",
  "IT and Electronic Hardware",
  "Safety and PPE (Personal Protective Equipment)",
  "Others",
];

export const ProductItemCreationSubCategoryOthers: string[] = [
  "DB",
  "MCCB",
  "Isolator",
  "MCB",
  "RCCB",
  "SPD",
  "Accessory",
  "Fire Extinguisher",
  "Wire",
  "Busbar",
  "Device",
  "Others",
];

export const enum MasterItemActions {
  Created = "Created",
  Updated = "Updated",
  Approved = "Approved",
  Rejected = "Rejected",
}
