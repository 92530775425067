import React from "react";
import { ItemModelV2 } from "../../models/item_model";
import ItemUnitSearchField from "../../item_unit/item_unit_search_field";
import { ServiceItemCreationSubCategory } from "../enums/enums";
import ItemNameSearchWidget from "./item_name_search_widget";
import ItemDescriptionBox from "./item_description_box";
interface ServiceItemCreationFormProps {
  data: ItemModelV2;
  setData: (data: ItemModelV2) => void;
  canEdit?: boolean;
}

const ServiceItemUpdateForm: React.FC<ServiceItemCreationFormProps> = ({
  data,
  setData,
  canEdit,
}) => {
  return (
    <>
      <div className="  grid grid-cols-1 lg:grid-cols-1 m-3 gap-2 select-none">
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="name">
              Name*
            </label>
            <ItemNameSearchWidget
              type="Service"
              value={data.name ?? ""}
              disabled={canEdit}
              onSelect={(e) => {
                console.log("onSelect e", e);
                // Assuming e contains the selected value directly
                setData({ ...data, name: e });
              }}
            />
            {/* <label className="font-semibold w-full" htmlFor="name">
              Name*
            </label>
            <input
              placeholder="Enter name"
              className="p-1 rounded  focus:outline-none w-full border"
              id="name"
              name="name"
              value={data.name || ""}
              disabled={!canEdit}
              // required={true}
              type="text"
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            ></input> */}
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="name">
              Description
            </label>
            {/* <textarea
              placeholder="Enter Description"
              className="p-1 rounded  focus:outline-none w-full border"
              id="desc"
              name="desc"
              disabled={!canEdit}
              value={data.description || ""}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
            ></textarea> */}
            <ItemDescriptionBox
              type="Service"
              value={data.description}
              disabled={canEdit}
              onSelect={(e) => {
                console.log("Description selected", e);
                setData({ ...data, description: e });
              }}
            />
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="category">
              Sub-Category
            </label>
            <select
              required
              className="p-1 rounded focus:outline-none w-full border"
              name="subcategory"
              id="subcategory"
              disabled={!canEdit}
              value={data.subcategory || ""}
              onChange={(e) => {
                setData({ ...data, subcategory: e.target.value });
              }}
            >
              <option value="" hidden>
                {" "}
                select
              </option>
              {ServiceItemCreationSubCategory.map((subcategory) => (
                <option value={subcategory}>{subcategory}</option>
              ))}
            </select>
          </div>
          {/* <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="unit">
              Unit measure *
            </label>
            <ItemUnitSearchField
              value={data.unit}
              onSelect={(e) => {
                setData({ ...data, unit: e });
              }}
              disabled={!canEdit}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ServiceItemUpdateForm;
