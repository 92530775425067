import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../../service/api_client";
import { urls } from "../../../../utils/urls";
import { gst_rates, tax_type } from "../../../accounts/models/ledger_model";
import ItemGroupSearchField from "../../item_group/item_group_search_field";
import ItemUnitSearchField from "../../item_unit/item_unit_search_field";
import {
  itemDefaultValue,
  ItemModelV2Default,
  ItemModel,
  ItemModelV2,
  ItemType,
} from "../../models/item_model";

import BomItemWidget from "./bom_item";
import OpeningStock from "./opening_stock";
import { isArray, set, update } from "lodash";
import DoneWidget from "../../../../ui/new_components/common/done";
import ProductItemCreationForm from "../components/product_item_creation_form";
import ServiceItemCreationForm from "../components/service_item_creation_form";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  createItemRepo,
  getSingleItemRepo,
  saveAsDraftRepo,
  updateItemRepo,
  deleteItem,
} from "../repository/items_repository";
import { ItemStatus } from "../../models/inventory_voucher";
import { ACTION, MasterItemStatus, MODULE } from "../../../../utils/enums";
import ModalWidget from "../../../../context_providers/modal/modal_widget";
import { ModalType } from "../../../../context_providers/modal/modal_context";
import { QRCodeSVG } from "qrcode.react";
import ItemNameSearchWidget from "../components/item_name_search_widget";
interface ItemCreateScreenProps {
  data?: ItemModelV2;
  onClose: (id: ItemModel) => void;
}

const ItemViewScreen: FunctionComponent<ItemCreateScreenProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const is_delete_page = useRouteMatch(
    urls.procurement_masters_item + "/delete/:id"
  );
  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState<"Product" | "Service">(
    "Product"
  );

  const { user, isAuthorised } = useAuth();
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [checker, setChecker] = useState<boolean>(false);
  const [maker, setMaker] = useState<boolean>(false);
  // const [deleteRequired, setDeleteRequired] = useState<boolean>(false);
  const { showToast } = useToast();
  const [data, setData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const [showQrCodeModal, setQrCodeModal] = useState<boolean>(false);
  const [qrCodeData, setQRCodeData] = useState<string>("");
  const [productData, setProductData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const [serviceData, setServiceData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const history = useHistory();
  const getData = async (id: string) => {
    setLoading(true);
    const res = await getSingleItemRepo(id);
    if (res.success) {
      console.log("res", res.data);
      if (res.data.type === "Product") {
        setProductData(res.data);
        let stringifiedData = JSON.stringify({
          item_code: res.data.item_code,
          item_name: res.data.name,
        });
        setQRCodeData(stringifiedData);
      } else if (res.data.type === "Service") {
        setServiceData(res.data);
        let stringifiedData = JSON.stringify({
          item_code: res.data.item_code,
          item_name: res.data.name,
        });
        setQRCodeData(stringifiedData);
      } else setData(res.data);
      setData(res.data);
      setSelectedType(res.data.type);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const approveRejectDeleteItem = async (status: MasterItemStatus) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item? Click 'OK' to confirm or 'Cancel' to abort."
    );
    if (!confirmed) {
      return; // Abort the operation if the user clicks 'Cancel'
    }
    setLoading(true);
    data.status = status;
    data.deleted = true;
    const result = await deleteItem(data);
    if (result.success) {
      showToast({
        type: ToastType.success,
        text: "Item deleted request successfull",
      });
      navaigation.goBack();
    } else {
      showToast({ type: ToastType.error, text: result.error });
    }
    setLoading(false);
  };

  // const requestDelete = async () => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to delete this item? Click 'OK' to confirm or 'Cancel' to abort."
  //   );
  //   if (!confirmed) {
  //     return; // Abort the operation if the user clicks 'Cancel'
  //   }
  //   setLoading(true);
  //   data.status = MasterItemStatus.DeletePending;
  //   const result = await deleteItemRequestRepo(data);
  //   if (result.success) {
  //     showToast({
  //       type: ToastType.success,
  //       text: "Item deleted request successfull",
  //     });
  //     navaigation.goBack();
  //   } else {
  //     showToast({ type: ToastType.error, text: result.error });
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    if (id) {
      getData(id); // Fetch data only once when the component mounts.
    }
    setCanEdit(false);

    const checker_result = isAuthorised({
      module: MODULE.master_item_checker,
      action: ACTION.UPDATE,
    });

    const maker_result = isAuthorised({
      module: MODULE.master_item_maker,
      action: ACTION.UPDATE,
    });

    setChecker(checker_result);
    setMaker(maker_result);

    console.log("checker", checker_result);
    console.log("maker", maker_result);
  }, [id]); // Only run this effect once when `id` is set.

  return (
    <>
      {showQrCodeModal && (
        <ModalWidget
          data={{
            id: 1,
            title: "QR Code",
            type: ModalType.modal,

            onClose: () => {
              setQrCodeModal(false);
            },
          }}
          onClose={function (): void {
            setQrCodeModal(false);
          }}
        >
          <div className="gap-4">
            <div className="flex flex-row text-sm w-full mb-4">
              <label className="font-semibold w-full" htmlFor="item_code">
                Item Code
              </label>
              <input
                placeholder="Item Code"
                className="p-1 rounded focus:outline-none w-full border"
                id="item_code"
                name="item_code"
                value={data.item_code || ""}
                disabled={true}
                type="text"
                onChange={(e) => {
                  setData({ ...data, item_code: e.target.value });
                }}
              ></input>
            </div>
            <div className="flex flex-row text-sm w-full">
              <label className="font-semibold w-full" htmlFor="name">
                Item Name
              </label>
              <input
                placeholder="Item Name"
                className="p-1 rounded focus:outline-none w-full border"
                id="name"
                name="name"
                value={data.name || ""}
                disabled={true}
                type="text"
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              ></input>
            </div>
            <div className="flex justify-center my-4">
              <QRCodeSVG value={qrCodeData} />
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => window.print()}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Print
              </button>
            </div>
          </div>
        </ModalWidget>
      )}
      <div className="flex flex-col m-4 gap-4">
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-3xl text-gray-500 flex items-center gap-3">
              <h1 className="font-bold text-3xl text-gray-500 flex items-center m-2 gap-3">
                {is_delete_page ? "Item Delete" : "Item Details"}
              </h1>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-1 ">
              {" "}
              <div className="bg-white rounded w-52 flex gap-1"></div>
            </div>
          </div>
        </section>
        <section className="m-4">
          <div className="font-bold">Item Type</div>
          <div className="flex flex-row gap-4">
            <div className="items-center">
              <input
                type="radio"
                name="itemType"
                value="Product"
                disabled={!canEdit}
                checked={selectedType === "Product"}
                onChange={(e) =>
                  setSelectedType(e.target.value as "Product" | "Service")
                }
              />
              <label className="ml-2">Product</label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="itemType"
                value="Service"
                checked={selectedType === "Service"}
                disabled={!canEdit}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    type: e.target.value as "Product" | "Service",
                  }));
                  setSelectedType(e.target.value as "Product" | "Service");
                }}
              />
              <label className="ml-2">Service</label>
            </div>
          </div>
        </section>
      </div>
      {selectedType === "Product" ? (
        <ProductItemCreationForm
          data={productData}
          setData={setProductData}
          canEdit={canEdit}
        />
      ) : (
        <ServiceItemCreationForm
          data={serviceData}
          setData={setServiceData}
          canEdit={canEdit}
        />
      )}
      <div className="  grid grid-cols-2 m-3 gap-2 select-none">
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <h4 className="font-semibold"> Tax details </h4>
          <div className="flex flex-row gap-6">
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                id="tax_yes"
                name="tax_applicable"
                value="yes"
                checked={
                  selectedType === "Product"
                    ? productData.tax_applicable
                    : selectedType === "Service"
                    ? serviceData.tax_applicable
                    : data.tax_applicable
                }
                onChange={() => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({ ...o, tax_applicable: true }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({ ...o, tax_applicable: true }));
                  }
                  setData((o) => ({ ...o, tax_applicable: true }));
                }}
                disabled={!canEdit}
              />
              <label htmlFor="tax_yes">Yes</label>
            </div>
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                id="tax_no"
                name="tax_applicable"
                value="no"
                checked={
                  selectedType === "Product"
                    ? !productData.tax_applicable
                    : selectedType === "Service"
                    ? !serviceData.tax_applicable
                    : !data.tax_applicable
                }
                onChange={() => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({
                      ...o,
                      tax_applicable: false,
                    }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({
                      ...o,
                      tax_applicable: false,
                    }));
                  }
                  setData((o) => ({ ...o, tax_applicable: false }));
                }}
                disabled={!canEdit}
              />
              <label htmlFor="tax_no">No</label>
            </div>
          </div>
          {selectedType === "Product" || selectedType === "Service" ? (
            <>
              {(selectedType === "Product"
                ? productData.tax_applicable
                : serviceData.tax_applicable) && (
                <>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="tax_type">
                      Tax type{"*"}
                    </label>
                    <select
                      value={
                        selectedType === "Product"
                          ? productData.tax_detail?.type || "default"
                          : serviceData.tax_detail?.type || "default"
                      }
                      disabled={!canEdit}
                      onChange={(e) => {
                        const updatedType = e.target.value;
                        if (selectedType === "Product") {
                          setProductData((prev) => ({
                            ...prev,
                            tax_detail: {
                              ...prev.tax_detail,
                              type: updatedType,
                              hsn_sac: prev.tax_detail?.hsn_sac || "",
                            },
                          }));
                        } else {
                          setServiceData((prev) => ({
                            ...prev,
                            tax_detail: {
                              ...prev.tax_detail,
                              type: updatedType,
                              hsn_sac: prev.tax_detail?.hsn_sac || "",
                            },
                          }));
                        }
                      }}
                      name="tax_type"
                      id="tax_type"
                      className="p-1 rounded focus:outline-none w-full border"
                    >
                      <option value="default" disabled>
                        Select a tax type
                      </option>
                      {Object.values(tax_type).map((type, i) => (
                        <option value={type} key={i}>
                          {type.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex gap-5 items-center text-sm w-full">
                    {(selectedType === "Product"
                      ? productData.tax_detail.type === tax_type.gst
                      : serviceData.tax_detail.type === tax_type.gst) && (
                      <>
                        <div className="flex flex-col items-start text-sm w-full">
                          <label className="font-semibold w-full" htmlFor="hsn">
                            GST Rate*
                          </label>
                          <select
                            id="gstRate"
                            className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={
                              selectedType === "Product"
                                ? productData.tax_detail?.gst_rate || "default"
                                : serviceData.tax_detail?.gst_rate || "default"
                            }
                            disabled={!canEdit}
                            onChange={(e) => {
                              const gstRate = Number(e.target.value);
                              if (selectedType === "Product") {
                                setProductData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    gst_rate: gstRate,
                                  },
                                }));
                              } else {
                                setServiceData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    gst_rate: gstRate,
                                  },
                                }));
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select GST Rate
                            </option>
                            {gst_rates.map((rate) => (
                              <option key={rate} value={rate}>
                                {rate}%
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col items-start text-sm w-full">
                          <label className="font-semibold w-full" htmlFor="hsn">
                            HSN/SAC
                          </label>
                          <input
                            type="text"
                            placeholder="Enter HSN or SAC number"
                            id="hsn"
                            name="hsn"
                            disabled={!canEdit}
                            value={
                              selectedType === "Product"
                                ? productData.tax_detail?.hsn_sac || ""
                                : serviceData.tax_detail?.hsn_sac || ""
                            }
                            onChange={(e) => {
                              const hsnSac = e.target.value;
                              if (selectedType === "Product") {
                                setProductData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    hsn_sac: hsnSac,
                                  },
                                }));
                              } else {
                                setServiceData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    hsn_sac: hsnSac,
                                  },
                                }));
                              }
                            }}
                            className="p-1 rounded focus:outline-none w-full border"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        {!is_delete_page && (
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="remarks">
                Remarks/Notes
              </label>
              <textarea
                placeholder="Enter Remarks/Notes"
                className="p-1 rounded  focus:outline-none w-full border"
                id="remarks"
                name="remarks"
                disabled={is_delete_page ? false : true}
                value={
                  selectedType === "Product"
                    ? productData.remarks
                    : selectedType === "Service"
                    ? serviceData.remarks
                    : data.remarks
                }
                onChange={(e) => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({
                      ...o,
                      remarks: e.target.value,
                    }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({
                      ...o,
                      remarks: e.target.value,
                    }));
                  }
                  setData((o) => ({
                    ...o,
                    remarks: e.target.value,
                  }));
                }}
              ></textarea>
            </div>
          </div>
        )}
      </div>
      <div className="m-4">
        <div className="flex justify-end mt-5 mb-2">
          {is_delete_page && checker ? (
            <>
              <div className="flex m-4">
                <button
                  onClick={() => {
                    approveRejectDeleteItem(MasterItemStatus.Approved);
                  }}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-red-400 text-white px-3 py-1"
                >
                  Delete
                </button>
              </div>
            </>
          ) : null}
          {/* <div className="m-4">
            <button
              onClick={() => {
                setQrCodeModal(true);
              }}
              type="button"
              className="rounded-md text-sm font-semibold bg-myPrimaryColor text-black px-3 py-1"
            >
              Generate QR Code
            </button>
          </div> */}
          <div className="m-4">
            {(selectedType === "Product" &&
              productData.status === "Approved") ||
            (selectedType === "Service" &&
              serviceData.status === "Approved") ? (
              <button
                onClick={() => {
                  setQrCodeModal(true);
                }}
                type="button"
                className="rounded-md text-sm font-semibold bg-myPrimaryColor text-black px-3 py-1"
              >
                Generate QR Code
              </button>
            ) : null}
          </div>
          <div className="m-4">
            <button
              onClick={() => {
                navaigation.goBack();
              }}
              type="button"
              className="rounded-md text-sm font-semibold bg-white text-black px-3 py-1"
            >
              Quit
            </button>
          </div>
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};
export default ItemViewScreen;
