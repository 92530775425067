import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
// import { useLocation } from 'react-router-dom';
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  fetchGet,
  fetchPut,
  fetchPost,
  getHeaders,
} from "../../../../../service/api_client";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import { LedgerEntryModel } from "../../../../accounts/models/common_model";
import {
  ItemMovementModel,
  stockJournalDefaultValue,
  StockJournalModel,
  voucherStatus,
} from "../../../models/inventory_voucher";
import ItemRow from "./components/item_row";
import TaxEntryRow, { getItemTaxAmount } from "./components/tax_entry_row";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import axios from "axios";
import { useGoogleLogout } from "react-google-login";
import EmployeeSearchWidget from "../../../../../ui/new_components/emp_search_widget";
import { urls } from "../../../../../utils/urls";
import { tax_type } from "../../../../accounts/models/ledger_model";

interface StockJournalProps {}

interface LocationState {
  id: string; // or whatever type the ID is
}

const StockJournal: FunctionComponent<StockJournalProps> = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation<LocationState>();
  const id1 = location.state?.id; //
  // console.log("Fetched ID from params:", id); // Log the ID
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/source_transfer`;
  const [, setLoading] = useState(false);
  const [editable] = useState(false);
  const [addNewId1, setAddNewId1] = useState(Math.random() * 1000);
  const [addNewTaxId1, setAddNewTaxId1] = useState(Math.random() * 1000);
  const [addNewTaxId, setAddNewTaxId] = useState<number>();
  const [isButtonClicked, setIsButtonClicked] = useState(true);
  const { showToast } = useToast();
  const google_props = {
    clientId:
      "225306128297-9b0pj2cq68voiqk87qpod3fpuevghoje.apps.googleusercontent.com",
  };
  const { signOut } = useGoogleLogout(google_props);
  const [data, setData] = useState<StockJournalModel>({
    ...stockJournalDefaultValue,
  });
  const existingItems = data.source || [];
  const [isWithdrawn, setIsWithdrawn] = useState<boolean>(false);
  const navigate = useHistory();
  // const [ShowHistory, setShowHistory] = useState<{ [key: string]: any }>();
  // const handleOptionChange = (value: WarehouseModel["type"]) => {
  //   setData((pv) => ({ ...pv, type: value }));
  // };
  const locate = useLocation();
  const isCreatePath = locate.pathname.endsWith("/create");

  useEffect(() => {
    if (id) getVoucher(id);
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id1) getDuplicateVoucher(id1);
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id1]);

  const getVoucher = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + "/" + id);
      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
          user: prevData.user,
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to fetch voucher" });
    }
    setLoading(false);
  };

  const getDuplicateVoucher = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + "/" + id);
      if (res.success) {
        // Update the source items' unit_no to 0 and warehouse to an empty object
        const updatedSource = res.data.source.map((item: any) => ({
          ...item,
          unit_no: 0,
          warehouse: {
            _id: "", // or null, depending on your preference
            id: null,
            name: "",
          }, // Set warehouse to an empty structure
        }));

        // Set the data with updated source items
        setData((prevData) => ({
          ...prevData,
          ...res.data,
          date: new Date(),
          source: updatedSource, // Use the updated source here
          destination: [],
          destination_tax: 0,
          destination_taxes: [],
          disposal_ids: [],
          destination_ids: [],
          destination_tax_ids: [],
          destination_sub_total: 0,
          destination_total: 0,
          disposalItems: [],
          user: prevData.user,
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to fetch voucher" });
    }
    setLoading(false);
  };

  async function getUserData() {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/auth/data`;
      const res = (
        await axios.get(url, {
          withCredentials: true,
          headers: { ...getHeaders() },
        })
      ).data;

      if (res.success) {
        setData((prevData) => ({
          ...prevData,
          user: res.data || prevData.user, // Ensure existing data is preserved
        }));
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      const { response } = error;
      if (response && response.status === 401) {
        signOut();
        window.localStorage.removeItem("token");
      }
    }
    setLoading(false);
  }

  const submit = async () => {
    if (!data.source?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add source component consumption",
      });

    if (!data.receiver || !data.receiver_spoc)
      return showToast({
        type: ToastType.error,
        text: "Please add receiver warehouse and SPOC person.",
      });
    setIsButtonClicked(false);
    setLoading(true);
    // console.log("ID in submit", id);
    const res = id
      ? await fetchPut(url + "/" + id, data)
      : await fetchPost(url, data);
    if (res.success) {
      data.status = voucherStatus.pending;
      setIsButtonClicked(false);
      showToast({ type: ToastType.success, text: res.message });
      navigate.push(urls.stock_transfer_voucher);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const withdraw = async () => {
    if (!id) return;

    setLoading(true);

    try {
      data.status = voucherStatus.return;
      const res = await fetchPut(url + "/" + id, data);
      if (res.success) {
        setIsWithdrawn(true); // Update state to reflect withdrawal
        showToast({
          type: ToastType.success,
          text: "Voucher withdrawn successfully",
        });
        navigate.push(urls.stock_transfer_voucher);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "Failed to withdraw voucher" });
    }
    setLoading(false);
  };

  return (
    <>
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Material Transfer
          </div>
          {/* <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div> */}
        </div>
        <div className="bg-white rounded mb-5 flex gap-1 h-auto overflow-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 p-5 text-sm w-3/4 font-semibold rounded-t top-0">
            <div className="">
              <div className="font-bold text-md mb-3">From</div>
              <div className="">
                <div className="font-bold">Sender</div>
                <input
                  value={`${data.user?.first_name || ""} ${
                    data.user?.last_name || ""
                  }`}
                  placeholder="Sender"
                  type="text"
                  disabled={!editable}
                  className="text-left p-1 focus:outline-none w-full"
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      user: {
                        ...prevData.user!,
                        first_name: e.target.value,
                        last_name: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="">
                <div className="font-bold">Sender Department</div>
                <input
                  value={data.user?.emp_profile?.department}
                  placeholder="Department"
                  disabled={!editable}
                  type="text"
                  className="text-left p-1 focus:outline-none w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      department: d.user?.emp_profile?.department,
                    }))
                  }
                />
              </div>
              <div className="">
                <label htmlFor="" className="text-sm font-bold text-gray-500">
                  Shipment Due Date
                </label>{" "}
                <input
                  type="date"
                  className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
                  value={
                    data.date ? moment(data.date).format("YYYY-MM-DD") : ""
                  }
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    setData((o) => ({
                      ...o,
                      date: date,
                    }));
                  }}
                />
              </div>
              <div className="">
                <div className="font-bold">Delivery Challan Number/Invoice</div>
                <input
                  value={data.delivery_challan_number}
                  name="challan"
                  id="challan"
                  placeholder="Delivery Challan"
                  type="text"
                  className="text-left p-1 focus:outline-none border rounded w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      delivery_challan_number: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="mr-0">
              <div className="font-bold text-md mb-3">To</div>

              <div className="font-bold">Warehouse/office</div>
              <WarehouseSearchField
                type={""}
                disabled={editable}
                value={data.receiver}
                placeholder="Inventory Basket"
                onSelect={(d) => {
                  setData((old) => {
                    return {
                      ...old,
                      receiver: d,
                    };
                  });
                }}
              />
              <div className="font-bold">Receiver</div>
              <input
                value={data.receiver?.spoc?.contact_name}
                placeholder=""
                type="text"
                className="text-left bg-gray-200 p-1 focus:outline-none border rounded w-full"
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    spoc: {
                      ...prevData.receiver?.spoc,
                      contact_name: e.target.value,
                    },
                  }));
                }}
                readOnly
              />
              {/* <div className="">
                <div className="font-bold">Department</div>
                <input
                  value={data.receiver?.spoc?.department || ""}
                  placeholder="Department"
                  disabled={editable}
                  type="text"
                  className="text-left p-1 focus:outline-none border rounded w-full"
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      department: e.target.value,
                    }))
                  }
                />
              </div> */}

              <div className="font-bold">Responsible Person(SPOC)</div>
              <EmployeeSearchWidget
                withBtn={false}
                onSelect={(emp) => {
                  setData((pv) => {
                    return {
                      ...pv!,
                      receiver_spoc: {
                        ...pv.receiver_spoc,
                        uid: emp.uid,
                        contact_name:
                          emp.first_name + " " + (emp.last_name ?? ""),
                        contact_mobile: emp.mobile,
                        contact_email: emp.email,
                        department: emp.emp_profile?.department,
                      },
                    };
                  });
                }}
                emp={
                  data.receiver_spoc?.uid
                    ? {
                        uid: data.receiver_spoc.uid!,
                        name: data.receiver_spoc.contact_name,
                      }
                    : undefined
                }
              />

              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  Spoc Department
                </label>
                <input
                  placeholder="Phone"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="phone"
                  name="phone"
                  value={data.receiver_spoc?.department}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      receiver_spoc: {
                        ...prevData.receiver_spoc!,
                        department: e.target.value,
                      },
                    }))
                  }
                />
              </div>

              {/* <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="line1">
                  SPOC Department
                </label>
                <input
                  placeholder="dept"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="dept"
                  name="dept"
                  value={data.receiver_spoc?.emp_profile?.department}
                  onChange={(e) =>
                    setData((d) => ({
                      ...d,
                      department: d.receiver_spoc?.emp_profile?.department,
                    }))
                  }
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-5 ">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-sm font-bold">Source(consumption)</h3>
              <div className="bg-white rounded flex flex-col gap-1 h-auto overflow-auto pb-20">
                <div className="grid grid-cols-8 gap-1 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t sticky top-0">
                  <div className="text-center col-span-2">Item</div>
                  <div className="text-center">Warehouse</div>
                  <div className="text-center">Qty</div>
                  <div className="text-center">Project From</div>
                  <div className="text-center">Rate</div>
                  <div className="text-center">Amount</div>
                </div>
                <div>
                  {/* {console.log("source data:", data.id)} */}
                  {data.source?.map((comp, i) => {
                    return (
                      <ItemRow
                        component={comp}
                        edit={false}
                        disabled={false}
                        key={comp.id}
                        onDelete={(d) => {
                          setData((o) => {
                            const source = [
                              ...(o.source || []).filter(
                                (it) => it.id !== d.id
                              ),
                            ];
                            const source_taxes = o.source_taxes.map((t, i) => {
                              t.amount = getItemTaxAmount({
                                items: source,
                                ledgerTaxDetail: t.ledger!.tax_detail!,
                              });

                              return { ...t };
                            });
                            let source_tax = source_taxes.reduce(
                              (pv, val) => pv + val.amount,
                              0
                            );

                            const source_sub_total = source.reduce(
                              (pv, val) => pv + val.unit_no * val.price,
                              0
                            );
                            const source_total =
                              source_sub_total + (source_tax ?? 0);

                            return {
                              ...o,
                              source,
                              source_sub_total,
                              source_taxes,
                              source_tax,
                              source_total,
                            };
                          });
                        }}
                        onSubmit={(d) => {
                          setData((o) => {
                            const source = [
                              ...(o.source || []).map((it) =>
                                it.id === d.id ? d : it
                              ),
                            ];
                            const source_taxes = o.source_taxes.map((t) => {
                              t.amount = getItemTaxAmount({
                                items: source,
                                ledgerTaxDetail: t.ledger!.tax_detail!,
                              });

                              return { ...t };
                            });
                            let source_tax = source_taxes.reduce(
                              (pv, val) => pv + val.amount,
                              0
                            );
                            const source_sub_total = source.reduce(
                              (pv, val) => pv + val.unit_no * val.price,
                              0
                            );
                            const source_total = source_sub_total + source_tax;

                            return {
                              ...o,
                              source,
                              source_sub_total,
                              source_tax,
                              source_total,
                            };
                          });
                        }}
                        existingItems={existingItems}
                      />
                    );
                  })}
                  {console.log("source taxes:", data.source_taxes)}
                  {!data.id && (
                    <ItemRow
                      key={addNewId1}
                      onSubmit={function (data: ItemMovementModel): void {
                        data.id = (Math.random() * 1000).toString();
                        setData((o) => {
                          const source = [...(o.source || []), data];
                          const source_taxes = o.source_taxes.map((t, i) => {
                            t.amount = getItemTaxAmount({
                              items: source,
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                            return { ...t };
                          });
                          let source_tax = source_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const source_sub_total = source.reduce(
                            (pv, val) => pv + val.unit_no * val.price,
                            0
                          );
                          const source_total =
                            source_sub_total + (source_tax ?? 0);

                          return {
                            ...o,
                            source,
                            source_sub_total,
                            source_taxes,
                            source_tax,
                            source_total,
                          };
                        });
                        setAddNewId1(Math.random() * 1000);
                      }}
                      // index={i}
                      edit={true}
                      disabled={false}
                      existingItems={existingItems}
                    />
                  )}
                </div>
              </div>

              <div className="">
                <h3 className="text-sm font-bold">Taxes</h3>
                <div className="bg-white rounded flex flex-col gap-1">
                  <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                    <div className="col-span-2">Particular</div>
                    <div className="text-center">Amount</div>
                  </div>
                </div>

                {/* {(data.source_taxes || []).map((tax, i) =>
                  isCreatePath ? (
                    <TaxEntryRow
                      key={tax.amount}
                      entry={tax}
                      items={[...(data.source || [])]}
                      onDelete={(d) => {
                        setData((o) => {
                          const source_taxes = [
                            ...(o.source_taxes || []),
                          ].filter((i) => i.id !== d.id);
                          let source_tax = source_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const source_total =
                            o.source_sub_total + (source_tax ?? 0);
                          return {
                            ...o,
                            source_taxes,
                            source_tax,
                            source_total,
                          };
                        });
                      }}
                      onSubmit={(d) => {
                        setData((o) => {
                          const source_taxes = [
                            ...(o.source_taxes || []).map((t) => {
                              if (t.id === d.id) {
                                return { ...d };
                              }
                              return { ...t };
                            }),
                          ].map((t) => {
                            t.amount = getItemTaxAmount({
                              items: data.source || [],
                              ledgerTaxDetail: t.ledger?.tax_detail!,
                            });

                            return { ...t };
                          });
                          let source_tax = source_taxes.reduce(
                            (pv, val) => pv + val.amount,
                            0
                          );
                          const source_total =
                            o.source_sub_total + (source_tax ?? 0);
                          return {
                            ...o,
                            source_taxes,
                            source_tax,
                            source_total,
                          };
                        });
                      }}
                      edit={false}
                      disabled={false}
                      existingEntries={data.source_taxes}
                    />
                  ) : null
                )} */}
                {(data.source_taxes || []).map((tax, i) => (
                  <TaxEntryRow
                    key={tax.id + `${tax.amount}`}
                    entry={tax}
                    items={[...(data.source || [])]}
                    onDelete={(d) => {
                      setData((o) => {
                        const source_taxes = [...(o.source_taxes || [])].filter(
                          (i) => i.id !== d.id
                        );
                        let source_tax = source_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const source_total =
                          o.source_sub_total + (source_tax ?? 0);
                        return {
                          ...o,
                          source_taxes,
                          source_tax,
                          source_total,
                        };
                      });
                    }}
                    onSubmit={function (d: LedgerEntryModel): void {
                      setData((o) => {
                        const source_taxes = [
                          ...(o.source_taxes || []).map((t) => {
                            if (t.id === d.id) {
                              return { ...d };
                            }
                            return { ...t };
                          }),
                        ].map((t) => {
                          if (t.ledger?.tax_detail?.type === tax_type.gst) {
                            t.amount = getItemTaxAmount({
                              items: data.source || [],
                              ledgerTaxDetail: t.ledger?.tax_detail!,
                            });
                          }
                          return { ...t };
                        });
                        let source_tax = source_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const source_total =
                          o.source_sub_total + (source_tax ?? 0);
                        return {
                          ...o,
                          source_taxes,
                          source_tax,
                          source_total,
                        };
                      });
                      setAddNewTaxId(Math.random() * 1000);
                    }}
                    edit={false}
                    disabled={false}
                    existingEntries={data.source_taxes}
                  />
                ))}
                {!data.id && (
                  <TaxEntryRow
                    key={addNewTaxId1}
                    items={[...(data.source || [])]}
                    onSubmit={function (d: LedgerEntryModel): void {
                      d.id = (Math.random() * 1000).toString();
                      setData((o) => {
                        const source_taxes = [
                          ...(o.source_taxes || [[]]),
                          d,
                        ].map((t, i) => {
                          if (t.ledger!.tax_detail)
                            t.amount = getItemTaxAmount({
                              items: data.source || [],
                              ledgerTaxDetail: t.ledger!.tax_detail!,
                            });

                          return { ...t };
                        });
                        let source_tax = source_taxes.reduce(
                          (pv, val) => pv + val.amount,
                          0
                        );
                        const source_total =
                          o.source_sub_total + (source_tax ?? 0);
                        return {
                          ...o,
                          source_taxes,
                          source_tax,
                          source_total,
                        };
                      });
                      setAddNewTaxId1(Math.random() * 1000);
                    }}
                    edit={true}
                    disabled={false}
                    existingEntries={data.source_taxes}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="m-7">
          <div className="text-sm flex justify-end">
            <div className="flex flex-col gap-2">
              <div className=" flex gap-5 justify-between">
                <div className="">Source subtotal:</div>
                <div className="">{data.source_sub_total}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Source tax:</div>
                <div className="">{Math.round(data.source_tax)}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Source total:</div>
                <div className="">{data.source_total}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-3 justify-end my-5 mb-10 px-5">
          {id && !data.destination?.length ? (
            <button
              type="button"
              className="bg-red-500 px-10 py-1 rounded-md text-white text-sm"
              onClick={withdraw}
              disabled={isWithdrawn} // Disable if already withdrawn
            >
              Withdraw
            </button>
          ) : null}
          {id &&
            !(data.status === voucherStatus.return) &&
            !data.destination?.length && (
              <button
                type="button"
                className="bg-myPrimaryColor px-10 py-1 rounded-md text-white text-sm"
                onClick={submit}
                disabled={isWithdrawn} // Disable if already withdrawn
              >
                Resend Mail
              </button>
            )}

          {!id && isButtonClicked && (
            <button
              type="button"
              className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm"
              onClick={submit}
              disabled={isWithdrawn} // Disable submit if withdrawn or no items
            >
              Outward
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default StockJournal;
