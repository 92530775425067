import { FunctionComponent, useEffect, useState } from "react";
import Pagination from "../../../../ui/pages/content_panel/ui/new_components/common/pagination";
import LoadingWidget from "../../../../ui/pages/content_panel/ui/new_components/common/loading_spinner";
import moment from "moment";
import { GetItemLogsAPI } from "../repository/items_log_repository";
import { ItemLogsModel } from "../../models/item_model";
import { GetItemLogTemplate } from "./item_log_template";

interface ItemHistoryProps {
  item_code: string;
}

const ItemActionHistory: FunctionComponent<ItemHistoryProps> = (props) => {
  const [logs, setLogs] = useState<ItemLogsModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count] = useState(10);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    GetItemLogsAPI({ item_code: props.item_code, page: page, count: count })
      .then((res) => {
        if (res.success) {
          setLogs(res.data.logs);

          if (res.data.metadata[0]) {
            setTotal(res.data.metadata[0].total);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    scrollBottom();
  }, [logs]);
  const scrollBottom = () => {
    const container = document.getElementById("history_wrap");
    if (container) container.scrollTop = container.scrollHeight;
  };

  return (
    <div className=" ">
      <div className="flex flex-col justify-between h-full">
        <div
          id="history_wrap"
          className="overflow-auto p-1 bg-gray-100"
          style={{ height: "88vh" }}
        >
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div key={i} className="  mb-2 shadow rounded bg-white p-1">
              {log.operation ? (
                GetItemLogTemplate(log)
              ) : (
                <div>
                  <b>{log.user}</b>
                  <p className="text-sm"> {log.operation}</p>
                  <div className="flex justify-end items-end py-2">
                    <span className="text-xs italic">
                      {moment(log.created_at).format("lll")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <Pagination
          page={page}
          total={total}
          count={count}
          itemCount={logs.length}
          onChange={(page: number) => setPage(page)}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ItemActionHistory;
