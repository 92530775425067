import { FunctionComponent } from "react";
import HrmPopup from "../../components/HrmPopup";
import { X } from "react-feather";

interface HomeProps {
  close: () => void;
}

const ImageUpdate: FunctionComponent<HomeProps> = ({ close }) => {
  return (
    <HrmPopup>
      <div className="flex flex-col items-center justify-center w-screen">
        <div className="bg-white rounded-lg text-black py-8 px-6 w-11/12 lg:w-1/3 relative">
          <X
            className="text-black cursor-pointer absolute top-3 right-3"
            size={24}
            onClick={close}
          />

          <div className="text-xl lg:text-2xl font-thin tracking-wide">
            New Year, New You.
            <br />
            Time for a Profile Pic Makeover.
          </div>
          <div className="my-3 lg:my-6 font-thin text-lg">
            ✨ Let's see that fresh look—your profile deserves it!
          </div>
          <div className="relative w-full h-72">
            <div className="absolute top-0 left-0 w-full h-full"></div>

            <iframe
              src="https://giphy.com/embed/A1oBMukTqFfkoY1HiH"
              frameBorder="0"
              className="w-full h-full"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </HrmPopup>
  );
};

export default ImageUpdate;
