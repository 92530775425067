import "../styles/index.css";
import { FunctionComponent, useEffect, useState } from "react";
import { GetEmpDetails } from "../../../../service/repos/users_repo";

import { UserModel } from "../../../../context_providers/auth/user_model";
import { Camera, Download } from "react-feather";
import { PostProfileImage } from "../../../../service/repos/hrm/hrm_repo";
import { compressImage } from "../../../../utils/image_compressor";
import Layout from "../layout";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import HrmLoader from "../components/loading";
import Details from "./components/Details";
import Documents from "./components/Documents";
import Referrals from "./components/Referrals";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import EmployeeCards from "./components/Cards";
import Assets from "./components/Assets";

const Profile = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [profileData, setProfileData] = useState<UserModel>();
  const [empUid, setEmpUid] = useState<any>();
  const { user } = useAuth();
  const selectTypes = [
    {
      name: "Profile Details",
      component: profileData && <Details userData={profileData} />,
    },
    {
      name: "Employee cards",
      component: profileData && <EmployeeCards userData={profileData} />,
    },
    {
      name: "Your referrals",
      component: empUid && <Referrals uid={empUid} />,
    },
    {
      name: "Documents",
      component: empUid && <Documents uid={empUid} />,
    },
    {
      name: "Assets",
      component: empUid && <Assets profileUID={empUid} />,
    },
  ];

  const [selected, setSelected] = useState<string>(selectTypes[0].name);

  const getData = async (uid: any) => {
    const res = await GetEmpDetails(uid);
    console.log(res.data);

    if (res.success) {
      setProfileData(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.location.state && props.location.state.uid) {
      setEmpUid(props.location.state.uid);
      getData(props.location.state.uid);
    } else if (user?.uid) {
      setEmpUid(user?.uid);
      getData(user?.uid!);
    } else {
      alert("Cannot fetch UID");
    }
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-center py-12 lg:py-32 ">
        {loading ? (
          <div className="">
            <HrmLoader className="w-12 h-12" />
          </div>
        ) : (
          profileData && <ProfileHeader userData={profileData} />
        )}

        <div className="w-11/12 lg:w-4/5 grid grid-cols-2 lg:flex items-center gap-3 lg:gap-20 mt-20 border-b border-gray-300 pb-2">
          {selectTypes.map((el, i) => {
            return (
              <div
                className={` ${
                  selected === el.name
                    ? "bg-black text-white rounded-full font-semibold"
                    : "text-opacity-70"
                } cursor-pointer transform transition-all duration-300  py-1 px-2 lg:py-2 lg:px-4 text-center `}
                key={i}
                onClick={() => setSelected(el.name)}
              >
                {el.name}
              </div>
            );
          })}
        </div>
        <div className="w-4/5">
          {selectTypes
            .filter((el) => el.name === selected)
            .map((el, i) => {
              return (
                <div className="" key={i}>
                  {el.component}
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

interface ProfileHeaderProps {
  userData: UserModel;
}

const ProfileHeader: FunctionComponent<ProfileHeaderProps> = ({ userData }) => {
  const { showToast } = useToast();
  const [data, setData] = useState<UserModel>(userData);

  const uploadProfileImage = async (uid: any, file: any) => {
    const formData = new FormData();
    const compressedImage = await compressImage(file);
    formData.append("uid", uid);
    formData.append("attachment", compressedImage);
    const res = await PostProfileImage(uid, formData);
    if (res.success) {
      setData(res.data);
      localStorage.setItem("imageUpdated", "true");
    }
  };
  return (
    <div className="w-4/5 lg:w-auto flex flex-col lg:flex-row lg:gap-12">
      <div className="relative w-20 lg:w-32 h-20 lg:h-32">
        {data?.profile_image ? (
          <img
            src={data.profile_image}
            alt=""
            className="w-full h-full rounded-full"
          />
        ) : (
          <img
            src={"/images/cat.jpeg"}
            alt=""
            className="w-full h-full rounded-full object-cover"
          />
        )}
        <div className="absolute bottom-0 right-0 cursor-pointer">
          <label htmlFor="image-input" className="cursor-pointer">
            <Camera fill="black" color="white" />
          </label>

          <input
            type="file"
            name="file"
            id="image-input"
            accept="image/*"
            onChange={(e: any) => {
              if (e.target.files[0]) {
                if (
                  e.target.files[0].type != "image/png" &&
                  e.target.files[0].type != "image/jpeg"
                ) {
                  showToast({
                    type: ToastType.error,
                    text: "Please upload either .png or .jpeg images",
                  });
                  e.target.value = "";
                } else {
                  const confirmCheck = window.confirm(
                    "Are you sure you want to update the image?"
                  );

                  if (confirmCheck) {
                    uploadProfileImage(data?.uid!, e.target.files[0]);
                  }
                }
              }
            }}
            className="hidden invisible"
          />
        </div>
      </div>
      <div className="pt-4">
        <div className="text-xl lg:text-3xl font-bold">
          {data?.first_name + " " + data?.last_name}
        </div>
        <div className="mt-4 text-black text-opacity-50 lg:text-xl font-semibold">
          {data?.emp_profile?.designation}
        </div>
        <div className="mt-1 text-black text-opacity-40">
          {data?.emp_profile?.department}
        </div>
      </div>
    </div>
  );
};
