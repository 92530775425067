import { FunctionComponent } from "react";

import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
interface LoadingWidgetProps {
  loading: boolean;
}

const LoadingWidget: FunctionComponent<LoadingWidgetProps> = (props) => {
  // <div className="flex h-full w-full justify-center items-center ">
  //     {loading}
  //   </div>
  return (
    <>
      <div
        className={`h-screen w-screen fixed z-50 top-0 left-0 bg-black bg-opacity-75 ${
          props.loading ? "block" : "hidden"
        }`}
      >
        <div className="flex h-full w-full justify-center items-center text-white ">
          <div className="flex items-center gap-2">
            <Icon path={mdiLoading} size={1} spin className="mr-2 ml-1 " />
            <p>Loading . . . .</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingWidget;
