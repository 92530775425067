import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../../service/api_client";
import { urls } from "../../../../utils/urls";
import { gst_rates, tax_type } from "../../../accounts/models/ledger_model";
import {
  ItemModelV2Default,
  ItemModel,
  ItemModelV2,
  ItemType,
} from "../../models/item_model";

import ProductItemCreationForm from "../components/product_item_creation_form";
import ServiceItemCreationForm from "../components/service_item_creation_form";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  approveItem,
  createItemRepo,
  getSingleItemRepo,
  rejectItem,
  saveAsDraftRepo,
  updateItemRepo,
} from "../repository/items_repository";
import { ItemStatus } from "../../models/inventory_voucher";
import { ACTION, MasterItemStatus, MODULE } from "../../../../utils/enums";

interface ItemCreateScreenProps {
  data?: ItemModelV2;
  onClose: (id: ItemModel) => void;
}

const ItemCreateScreen: FunctionComponent<ItemCreateScreenProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const is_update_page = useRouteMatch(
    urls.procurement_masters_item + "/update/:id"
  );

  const [loading, setLoading] = useState(false);
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);
  const [selectedType, setSelectedType] = useState<"Product" | "Service">(
    "Product"
  );
  const { user, isAuthorised } = useAuth();
  const [canEdit, setCanEdit] = useState(true);
  const [approveRejectCreationRequired, setApproveRejectCreationRequired] =
    useState(false);
  const [approveRejectUpdateRequired, setApproveRejectUpdateRequired] =
    useState(false);

  const [isStatusDraft, setIsStatusDraft] = useState(false);

  const [selectedTaxType, setTaxType] = useState<string>(
    ItemModelV2Default.tax_detail?.type || ""
  );
  const { showToast } = useToast();
  const [data, setData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const [productData, setProductData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const [serviceData, setServiceData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  useEffect(() => {
    console.log("Product Data", productData);
    console.log("Service Data", serviceData);
  });

  const history = useHistory();

  const updateItem = async () => {
    try {
      if (selectedType === "Product") {
        if (is_update_page && !productData._id && serviceData._id) {
          productData._id = serviceData._id;
          productData.item_code = serviceData.item_code;
          productData.id = serviceData.id;
        }
        console.log("onSubmit", productData);
        productData.type = selectedType;
        productData.status = MasterItemStatus.UpdatePending;
        if (!productData.name) {
          showToast({
            type: ToastType.error,
            text: "Please fill required fields !",
          });
          return;
        }
        setLoading(true);
        const result = await updateItemRepo(productData);
        if (result.success) {
          showToast({ type: ToastType.success, text: result.message });
          history.goBack();
        } else {
          showToast({ type: ToastType.error, text: result.error });
        }
        setLoading(false);
      } else {
        console.log("onSubmit SERVICE DATA", serviceData);
        if (is_update_page && !serviceData._id && productData._id) {
          serviceData._id = productData._id;
          serviceData.item_code = productData.item_code;
          serviceData.id = productData.id;
        }
        serviceData.type = selectedType;
        serviceData.status = MasterItemStatus.UpdatePending;
        if (!serviceData.name) {
          showToast({
            type: ToastType.error,
            text: "Please fill required fields !",
          });
          return;
        }
        setLoading(true);
        const result = await updateItemRepo(serviceData);
        if (result.success) {
          showToast({ type: ToastType.success, text: result.message });
          history.goBack();
        } else {
          showToast({ type: ToastType.error, text: result.error });
        }
        setLoading(false);
      }
    } catch (error: any) {
      console.log("error", error);
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  const approveMasterItem = async () => {
    try {
      if (selectedType === "Product") {
        // Set the Status first
        productData.status = MasterItemStatus.Approved;
        let res = await approveItem(productData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Approved successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      } else if (selectedType === "Service") {
        // Set the Status first
        serviceData.status = MasterItemStatus.Approved;
        let res = await approveItem(serviceData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Approved successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  const rejectMasterItem = async () => {
    try {
      if (selectedType === "Product") {
        // Set the Status first
        productData.status = MasterItemStatus.Rejected;
        let res = await rejectItem(productData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Rejected successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      } else if (selectedType === "Service") {
        // Set the Status first
        serviceData.status = MasterItemStatus.Rejected;
        let res = await rejectItem(serviceData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Rejected successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  const onSaveAsDraft = async () => {
    if (selectedType === "Product") {
      if (is_update_page && !productData._id && serviceData._id) {
        productData._id = serviceData._id;
        productData.item_code = serviceData.item_code;
        productData.id = serviceData.id;
      }

      console.log("onSubmit", productData);
      productData.type = selectedType;
      productData.status = MasterItemStatus.SavedDraft;
      if (!productData.name) {
        showToast({
          type: ToastType.error,
          text: "Please fill required fields !",
        });
        return;
      }
      // TODO: Check if tax applicable is there or not
      setLoading(true);
      const res = await saveAsDraftRepo(productData);
      if (res.success) {
        if (!productData.id) {
          setProductData(ItemModelV2Default);
        }
        console.log("res", res);
        setProductData(res.data);
        // setData((prevData) => ({
        //   ...prevData,
        //   ...res.data,
        // }));
        history.goBack();
        showToast({ type: ToastType.success, text: res.message });
        if (props.onClose) props.onClose(res.data);
      } else showToast({ type: ToastType.error, text: res.error });
    } else if (selectedType === "Service") {
      if (is_update_page && !serviceData._id && productData._id) {
        serviceData._id = productData._id;
        serviceData.item_code = productData.item_code;
        serviceData.id = productData.id;
      }
      console.log("onSubmit SERVICE DATA", serviceData);
      serviceData.type = selectedType;
      serviceData.status = MasterItemStatus.SavedDraft;
      if (!serviceData.name) {
        showToast({
          type: ToastType.error,
          text: "Please fill required fields !",
        });
        return;
      }
      setLoading(true);
      const res = await saveAsDraftRepo(serviceData);
      if (res.success) {
        if (!serviceData.id) {
          setServiceData(ItemModelV2Default);
        }
        console.log("res", res);
        setServiceData(res.data);
        // setData((prevData) => ({
        //   ...prevData,
        //   ...res.data,
        // }));
        history.goBack();
        showToast({ type: ToastType.success, text: res.message });
        if (props.onClose) props.onClose(res.data);
      } else showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    if (selectedType === "Product") {
      console.log("onSubmit", productData);
      productData.type = selectedType;
      productData.status = MasterItemStatus.PendingForApproval;
      if (!productData.name) {
        showToast({
          type: ToastType.error,
          text: "Please fill required fields !",
        });
        return;
      }
      // TODO: Check if tax applicable is there or not
      setLoading(true);

      const res = await createItemRepo(productData);
      if (res.success) {
        if (!productData.id) {
          setProductData(ItemModelV2Default);
        }
        console.log("res", res);
        setProductData(res.data);
        // setData((prevData) => ({
        //   ...prevData,
        //   ...res.data,
        // }));
        history.goBack();
        showToast({ type: ToastType.success, text: res.message });
        if (props.onClose) props.onClose(res.data);
      } else showToast({ type: ToastType.error, text: res.error });
    } else if (selectedType === "Service") {
      console.log("onSubmit SERVICE DATA", serviceData);
      serviceData.type = selectedType;
      if (!serviceData.name) {
        showToast({
          type: ToastType.error,
          text: "Please fill required fields !",
        });
        return;
      }
      // TODO: Check if tax applicable is there or not
      setLoading(true);
      serviceData.status = MasterItemStatus.PendingForApproval;
      const res = await createItemRepo(serviceData);
      if (res.success) {
        if (!serviceData.id) {
          setServiceData(ItemModelV2Default);
        }
        console.log("res", res);
        setServiceData(res.data);
        // setData((prevData) => ({
        //   ...prevData,
        //   ...res.data,
        // }));
        history.goBack();
        showToast({ type: ToastType.success, text: res.message });
        if (props.onClose) props.onClose(res.data);
      } else showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const getData = async (id: string) => {
    setLoading(true);
    const res = await getSingleItemRepo(id);
    if (res.success) {
      console.log("res", res.data);
      if (res.data.type === "Product") setProductData(res.data);
      else if (res.data.type === "Service") setServiceData(res.data);
      else setData(res.data);
      setSelectedType(res.data.type);
      if (res.data.status === MasterItemStatus.SavedDraft) {
        setIsStatusDraft(true);
      } else if (res.data.status === MasterItemStatus.PendingForApproval) {
        setApproveRejectCreationRequired(true);
      } else if (res.data.status === MasterItemStatus.UpdatePending) {
        setApproveRejectUpdateRequired(true);
      }
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  useEffect(() => {
    console.log("useEffect on Create screen running");
    if (id) getData(id);
    // TODO: check if user has permission to update
    // if maker we should be ab
    const maker_update_result = isAuthorised({
      module: MODULE.master_item_maker,
      action: [ACTION.UPDATE],
    });
    const checker_update_result = isAuthorised({
      module: MODULE.master_item_checker,
      action: [ACTION.UPDATE],
    });
    console.log("maker_update_result", maker_update_result);
    console.log("checker_update_result", checker_update_result);
    if (maker_update_result && !checker_update_result) {
      setCanEdit(true);
      setMaker(true);
      setChecker(false);
    } else if (checker_update_result && !maker_update_result) {
      setCanEdit(false);
      setChecker(true);
      setMaker(false);
    } else if (maker_update_result && checker_update_result) {
      setCanEdit(true);
      setMaker(true);
      setChecker(true);
    } else {
      setCanEdit(false);
      setMaker(false);
      setChecker(false);
    }
  }, [id]);

  return (
    <>
      <div className="flex justify-between items-end my-1">
        <h1 className="font-bold text-3xl text-gray-500 flex items-center m-2 gap-3">
          {is_update_page &&
          ((selectedType === "Product" &&
            (productData.status === MasterItemStatus.UpdatePending ||
              productData.status === MasterItemStatus.PendingForApproval)) ||
            (selectedType === "Service" &&
              (serviceData.status === MasterItemStatus.UpdatePending ||
                serviceData.status === MasterItemStatus.PendingForApproval)))
            ? "Item Update"
            : "Item Create"}
        </h1>
        <div className="flex justify-end   text-sm items-center gap-1 ">
          {" "}
          <div className="bg-white rounded w-52 flex gap-1"></div>
        </div>
      </div>
      <div className="flex flex-row m-4 gap-4">
        <div className="font-bold">Item Type</div>
        <div className="flex items-center">
          <input
            type="radio"
            name="itemType"
            value="Product"
            disabled={is_update_page ? true : false}
            checked={selectedType === "Product"}
            onChange={(e) =>
              setSelectedType(e.target.value as "Product" | "Service")
            }
          />
          <label className="ml-2">Product</label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            name="itemType"
            value="Service"
            checked={selectedType === "Service"}
            disabled={is_update_page ? true : false}
            onChange={(e) => {
              setData((o) => ({
                ...o,
                type: e.target.value as "Product" | "Service",
              }));
              setSelectedType(e.target.value as "Product" | "Service");
            }}
          />
          <label className="ml-2">Service</label>
        </div>
      </div>
      {selectedType === "Product" ? (
        <ProductItemCreationForm
          data={productData}
          setData={setProductData}
          canEdit={canEdit}
        />
      ) : (
        <ServiceItemCreationForm
          data={serviceData}
          setData={setServiceData}
          canEdit={canEdit}
        />
      )}
      <div className="  grid grid-cols-2 m-3 gap-2 select-none">
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <h4 className="font-semibold"> Tax details </h4>
          <div className="flex flex-row gap-6">
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                id="tax_yes"
                name="tax_applicable"
                value="yes"
                checked={
                  selectedType === "Product"
                    ? productData.tax_applicable
                    : selectedType === "Service"
                    ? serviceData.tax_applicable
                    : data.tax_applicable
                }
                onChange={() => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({ ...o, tax_applicable: true }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({ ...o, tax_applicable: true }));
                  }
                  setData((o) => ({ ...o, tax_applicable: true }));
                }}
                disabled={!canEdit}
              />
              <label htmlFor="tax_yes">Yes</label>
            </div>
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                id="tax_no"
                name="tax_applicable"
                value="no"
                checked={
                  selectedType === "Product"
                    ? !productData.tax_applicable
                    : selectedType === "Service"
                    ? !serviceData.tax_applicable
                    : !data.tax_applicable
                }
                onChange={() => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({
                      ...o,
                      tax_applicable: false,
                    }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({
                      ...o,
                      tax_applicable: false,
                    }));
                  }
                  setData((o) => ({ ...o, tax_applicable: false }));
                }}
                disabled={!canEdit}
              />
              <label htmlFor="tax_no">No</label>
            </div>
          </div>
          {selectedType === "Product" || selectedType === "Service" ? (
            <>
              {(selectedType === "Product"
                ? productData.tax_applicable
                : serviceData.tax_applicable) && (
                <>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="tax_type">
                      Tax type{"*"}
                    </label>
                    <select
                      value={
                        selectedType === "Product"
                          ? productData.tax_detail?.type || "default"
                          : serviceData.tax_detail?.type || "default"
                      }
                      disabled={!canEdit}
                      onChange={(e) => {
                        const updatedType = e.target.value;
                        if (selectedType === "Product") {
                          setProductData((prev) => ({
                            ...prev,
                            tax_detail: {
                              ...prev.tax_detail,
                              type: updatedType,
                              hsn_sac: prev.tax_detail?.hsn_sac || "",
                            },
                          }));
                        } else {
                          setServiceData((prev) => ({
                            ...prev,
                            tax_detail: {
                              ...prev.tax_detail,
                              type: updatedType,
                              hsn_sac: prev.tax_detail?.hsn_sac || "",
                            },
                          }));
                        }
                      }}
                      name="tax_type"
                      id="tax_type"
                      className="p-1 rounded focus:outline-none w-full border"
                    >
                      <option value="default" disabled>
                        Select a tax type
                      </option>
                      {Object.values(tax_type).map((type, i) => (
                        <option value={type} key={i}>
                          {type.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex gap-5 items-center text-sm w-full">
                    {(selectedType === "Product"
                      ? productData.tax_detail.type === tax_type.gst
                      : serviceData.tax_detail.type === tax_type.gst) && (
                      <>
                        <div className="flex flex-col items-start text-sm w-full">
                          <label className="font-semibold w-full" htmlFor="hsn">
                            GST Rate*
                          </label>
                          <select
                            id="gstRate"
                            className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={
                              selectedType === "Product"
                                ? productData.tax_detail?.gst_rate || "default"
                                : serviceData.tax_detail?.gst_rate || "default"
                            }
                            onChange={(e) => {
                              const gstRate = Number(e.target.value);
                              if (selectedType === "Product") {
                                setProductData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    gst_rate: gstRate,
                                  },
                                }));
                              } else {
                                setServiceData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    gst_rate: gstRate,
                                  },
                                }));
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select GST Rate
                            </option>
                            {gst_rates.map((rate) => (
                              <option key={rate} value={rate}>
                                {rate}%
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col items-start text-sm w-full">
                          <label className="font-semibold w-full" htmlFor="hsn">
                            HSN/SAC
                          </label>
                          <input
                            type="text"
                            placeholder="Enter HSN or SAC number"
                            id="hsn"
                            name="hsn"
                            disabled={!canEdit}
                            value={
                              selectedType === "Product"
                                ? productData.tax_detail?.hsn_sac || ""
                                : serviceData.tax_detail?.hsn_sac || ""
                            }
                            onChange={(e) => {
                              const hsnSac = e.target.value;
                              if (selectedType === "Product") {
                                setProductData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    hsn_sac: hsnSac,
                                  },
                                }));
                              } else {
                                setServiceData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    hsn_sac: hsnSac,
                                  },
                                }));
                              }
                            }}
                            className="p-1 rounded focus:outline-none w-full border"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="flex flex-row justify-end gap-4 m-4">
        {checker && is_update_page && approveRejectCreationRequired && (
          <>
            <div className="flex gap-5 mt-5 mb-2">
              {/* TODO Have to change update method */}
              <button
                onClick={rejectMasterItem}
                type="button"
                className="rounded-md text-sm font-semibold bg-red-400 text-white px-3 py-1"
              >
                Reject Creation
              </button>
            </div>
            <div className="flex  gap-5 mt-5 mb-2">
              <button
                onClick={approveMasterItem}
                type="button"
                className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
              >
                Approve Creation
              </button>
            </div>
          </>
        )}
        {maker && (
          <>
            {!is_update_page || isStatusDraft ? (
              <>
                {/* Save as Draft and Submit buttons for non-update pages or if status is saved as draft */}
                <div className="flex gap-5 mt-5 mb-2">
                  <button
                    onClick={onSaveAsDraft}
                    type="button"
                    className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
                  >
                    Save as Draft
                  </button>
                </div>
                <div className="flex gap-5 mt-5 mb-2">
                  <button
                    onClick={onSubmit}
                    type="button"
                    className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* Update button for update pages */}
                <div className="flex gap-5 mt-5 mb-2">
                  <button
                    onClick={updateItem}
                    type="button"
                    className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
                  >
                    Update
                  </button>
                </div>
              </>
            )}
          </>
        )}

        <div className="flex  gap-5 mt-5 mb-2">
          <button
            onClick={() => {
              navaigation.goBack();
            }}
            type="button"
            className="rounded-md text-sm font-semibold bg-white text-black px-3 py-1"
          >
            Quit
          </button>
        </div>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};
export default ItemCreateScreen;
