/* eslint-disable react-hooks/exhaustive-deps */
import { mdiArrowBottomLeft, mdiArrowTopRight, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { parse } from "papaparse";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { currencyFormat } from "../../../../utils/orders_utils";
import {
  payment_status,
  PaymentModeOptions,
  PaymentRequestAdvanceModel,
  PaymentRequestAgainstInvoiceModel,
  po_payments_req_status,
} from "../../model/payment_request_model";
import { ProcurementProjectModel } from "../../projects/model/project_model";
import { PurchaseOrderModel } from "../../model/purchase_order_model";
import { urls } from "../../../../utils/urls";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../ui/new_components/common/drawer_modal";
import ActionHistoryInvoice from "../components/payment_request_invoice_history_logs";
import ActionHistoryAdvance from "../components/payment_request_advance_history_logs";
import NewTableView from "../../../../procurement/components/new_table_comp";
import { defaultFilter, PaymentFilterModule } from "../payment_requests_list";
import {
  getPaymentAdvanceList,
  getPaymentInvoiceList,
  postImportPaymentAdvanceList,
  postImportPaymentInvoiceList,
} from "../../purchase_order_create/service/purchase_order_repository";
import FilterDropDown from "../../../../ui/new_components/common/filter_drop_down";
import ExportPaymentRequestInvoice from "../components/payment_request_invoice_export";
import ModalDialog from "../../../../ui/new_components/common/modal";
import ExportPaymentRequestAdvance from "../components/payment_request_advance_export";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";

interface PaymentRequestApprovalListProps {}

const PaymentRequestApprovalListForAdvanceAndInvoice: FunctionComponent<
  PaymentRequestApprovalListProps
> = () => {
  // const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/payment_request`;
  // const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [total, setTotal] = useState(0);
  const [showExport, setShowExport] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showImportInvoice, setShowImportInvoice] = useState(false);
  const [showExportInvoice, setShowExportInvoice] = useState(false);
  const [showHistoryAdvance, setShowHistoryAdvance] = useState<any>();
  const [showHistoryAdvance1, setShowHistoryAdvance1] = useState<any>();
  const [showHistoryInvoice, setShowHistoryInvoice] = useState<any>();
  const [showHistoryInvoice1, setShowHistoryInvoice1] = useState<any>();
  const tempFilter = localStorage.getItem("com_filter");
  const [filter, setFilter] = useState<PaymentFilterModule>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter
  );
  const [search, setSearch] = useState("");
  // const [fromDate, setFromDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date());
  const [paymentRequestType, setPaymentRequestType] = useState("advance"); // Track selected payment type
  const [paymentRequestAdvance, setPaymentRequestAdvance] = useState<
    PaymentRequestAdvanceModel[]
  >([]);
  const [paymentRequestAgainstInvoice, setPaymentRequestAgainstInvoice] =
    useState<PaymentRequestAgainstInvoiceModel[]>([]); // Against Invoice model state

  // useEffect(() => {
  //   const now = new Date();
  //   let from = new Date(new Date(now).setHours(0, 0, 0, 0));
  //   from = new Date(from.setDate(1));
  //   let to = new Date(new Date(now).setHours(23, 59, 59, 999));
  //   to = new Date(to.setMonth(now.getMonth() + 1));
  //   to = new Date(to.setDate(0));
  //   setFromDate(from);
  //   setToDate(to);
  // }, []);

  const onImportInvoice = async (data: PaymentRequestAgainstInvoiceModel[]) => {
    try {
      setLoading(true);

      const res = await postImportPaymentInvoiceList(data);

      if (res.success) {
        // Refresh the list if necessary
        await getPaymentInvoiceList(filter);
      } else {
        // Handle error case
        showToast({
          type: ToastType.error,
          text: res.error || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "Failed to import data.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onImport = async (data: PaymentRequestAdvanceModel[]) => {
    try {
      setLoading(true);

      const res = await postImportPaymentAdvanceList(data);

      if (res.success) {
        // const importedData = res.data;
        // console.log("imported data:", importedData);
        // if (importedData.length > 0) {
        // Avoid duplicates by filtering
        // setPaymentRequestAdvance((o) => [...importedData, ...o]);

        // Refresh the list if necessary
        await getPaymentAdvanceList(filter);
        // }

        // Handle invalid records
        // if (res.data.invalid.length > 0) {
        //   showToast({
        //     type: ToastType.warning,
        //     text: `PR invalid: [${res.data.invalid.join(", ")}]`,
        //   });
        // }

        // Show success message
        // if (res.data.length) {
        //   showToast({
        //     type: ToastType.success,
        //     text: `Created ${res.data.length} records successfully.`,
        //   });
        // }
      } else {
        // Handle error case
        showToast({
          type: ToastType.error,
          text: res.error || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "Failed to import data.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch data based on the request type
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate?: Date;
    toDate?: Date;
    filterData?: {
      poNo?: string;
      pr_id?: string;
      pi_no_Or_Inv_no?: string;
      payment_mode?: string[];
      due_date?: Date;
      created_date?: Date;
      pr_status?: string[];
      payment_status?: string[];
    };
  }) => {
    setLoading(true); // Start loading state
    try {
      // Conditional URL based on paymentRequestType
      console.log("paymentRequestType:", paymentRequestType);
      if (paymentRequestType === "advance") {
        console.log("Advance payment request", filter);
        // url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request`;
        // const res = await fetchGet(url + toQueryString(prop));
        console.log("filter in fetch:", prop.filterData);
        const res = await getPaymentAdvanceList(prop);

        // console.log("Response for advance:", res.data);
        if (res.success) {
          setPaymentRequestAdvance(res.data.data);
          if (res.data.metadata) {
            setTotal(res.data.metadata.total);
          }
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      } else if (paymentRequestType === "invoice") {
        // url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/invoice_payment_request`;
        // const res = await fetchGet(url + toQueryString(prop));
        console.log("filter in fetch:", prop.filterData);
        const res = await getPaymentInvoiceList(prop);

        console.log("Response for invoice:", res.data);
        if (res.success) {
          setPaymentRequestAgainstInvoice(res.data.data);
          if (res.data.metadata) {
            setTotal(res.data.metadata.total);
          }
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      }
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error?.message || error.toString(),
      });
    }

    setLoading(false); // End loading state
  };

  // Debounced handler
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        filterData?: {
          poNo?: string;
          pr_id?: string;
          pi_no_Or_Inv_no?: string;
          payment_mode?: string[];
          due_date?: Date;
          created_date?: Date;
          pr_status?: string[];
          payment_status?: string[];
        };
      }) => {
        // Simply fetch data, the paymentRequestType logic is handled inside fetchData
        if (prop) {
          console.log("filter:", prop.filterData);
          fetchData(prop);
        } else {
          // Clear previous data if no properties are passed
          if (paymentRequestType === "advance") {
            setPaymentRequestAdvance([]);
          } else if (paymentRequestType === "invoice") {
            setPaymentRequestAgainstInvoice([]);
          }
        }
      },
      100 // Debounce delay (100ms)
    ),
    [paymentRequestType] // Empty dependency array, meaning it will only be created once
  );

  // Trigger data fetch based on paymentRequestType
  useEffect(() => {
    // Whenever paymentRequestType changes, trigger the appropriate fetching
    debouncedHandler({
      search: filter.search,
      page: filter.page,
      count: filter.count,
      all: filter.all,
      filterData: {
        poNo: filter.filterData?.poNo,
        pr_id: filter.filterData?.pr_id,
        pi_no_Or_Inv_no: filter.filterData?.pi_no_Or_Inv_no,
        payment_mode: filter.filterData?.payment_mode,
        due_date: filter.filterData?.due_date,
        created_date: filter.filterData?.created_date,
        pr_status: filter.filterData?.pr_status,
        payment_status: filter.filterData?.payment_status,
      },
    });
    localStorage.setItem("com_filter", JSON.stringify(filter));
  }, [paymentRequestType, page, count, search, filter, filter.filterData]); // Trigger on change of paymentRequestType and other relevant properties

  // const fetchPurchaseOrderByPoNo = async (
  //   poNo: string
  // ): Promise<PurchaseOrderModel | null> => {
  //   try {
  //     const res = await fetchGet(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${poNo}`
  //     );
  //     if (res.success) {
  //       setPurchaseOrderData(res.data);
  //       // Assuming res.data is a single PurchaseOrderModel
  //       return res.data as PurchaseOrderModel;
  //     } else {
  //       showToast({ type: ToastType.error, text: res.error });
  //       return null;
  //     }
  //   } catch (error) {
  //     showToast({ type: ToastType.error, text: (error as Error).message });
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (
  //       paymentRequestType === "advance" &&
  //       paymentRequestAdvance.length > 0
  //     ) {
  //       // if (paymentRequestAdvance.length > 0) {
  //       const poNo = paymentRequestAdvance[0].po_no;
  //       let po;
  //       if (poNo) {
  //         po = await fetchPurchaseOrderByPoNo(poNo);
  //       }

  //       if (po && po.project_id) {
  //         getProjectData(po.project_id);
  //       }
  //     } else {
  //       const poNo = paymentRequestAgainstInvoice[0]?.po_no;
  //       let po;
  //       if (poNo) {
  //        po = await fetchPurchaseOrderByPoNo(poNo);
  //       }
  //       if (po && po.project_id) {
  //         getProjectData(po.project_id);
  //       }
  //     }
  //   };

  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentRequestAdvance, paymentRequestAgainstInvoice, debouncedHandler]);
  // console.log("Purchase order:", purchaseOrderData);
  // const getProjectData = async (id: string) => {
  //   setLoading(true);

  //   const res = await fetchGet(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
  //   );
  //   if (res.success) {
  //     setProject(res.data); // Directly set the project data
  //   } else {
  //     showToast({ type: ToastType.error, text: res.error });
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   debouncedHandler({ page, count, search, fromDate, toDate });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, fromDate, toDate, search]);
  // const navaigation = useHistory();
  // const onSelect = (d: any) => {
  //   console.log("Selected data:", paymentRequestType, d);
  //   if (paymentRequestType === "advance") {
  //     setPaymentRequestAdvance(d);
  //     navaigation.push(urls.po_advance_payment_request + "/approve/" + d.id);
  //   } else {
  //     setPaymentRequestAgainstInvoice(d);
  //     navaigation.push(urls.po_invoice_payment_request + "/approve/" + d.id);
  //   }
  // };
  return (
    <>
      {paymentRequestType === "advance" && showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Advance Payment"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              [PR ID # ,PO #, PO Date, Request Date, Vendor, Due Date, Payment
              Mode, PI NO, PI Date, PI Amount, Payment Amount, Project, Note, PO
              Approval, SPOC, UTR, Swift, Remarks, Payment Status, Payment
              Date,Approved At,Approved by,OnHold Reason,Reject Reason, PR
              Status ]
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<any>(files[0], {
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    console.log("res:", res[0]);
                    if (res.length === 0) {
                      showToast({
                        type: ToastType.info,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("PR_ID") === -1 ||
                      keys.indexOf("PO") === -1
                    ) {
                      showToast({
                        type: ToastType.error,
                        text: "Required fields are [id,po_no] !",
                      });
                    }
                    const filteredData: any[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const {
                        PR_ID,
                        PO,
                        UTR,
                        Swift,
                        payment_amount,
                        payment_status,
                        deleted,
                      } = res[index];
                      if (!PR_ID || !PO || !UTR || !Swift) {
                        showToast({
                          type: ToastType.error,
                          text: "Missing required data !",
                        });

                        return;
                      }

                      filteredData.push({
                        id: PR_ID,
                        po_no: PO,
                        utr: UTR,
                        swift: Swift,
                        payment_amount: payment_amount,
                        payment_status: payment_status,
                        deleted: deleted,
                      });
                    }

                    // Bulk upload
                    setShowImport(false);
                    console.log("filtered dta:", filteredData);
                    onImport(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      {paymentRequestType === "invoice" && showImportInvoice && (
        <ModalDialog
          show={showImportInvoice}
          onClose={() => setShowImportInvoice(false)}
          title="Import PR Against Invoice"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              [PR ID # ,PO #, PO Date, Request Date, Vendor, Due Date, Payment
              Mode, Invoice Number, Invoice Date, Invoice Amount, Payment
              Amount, Project, Note, PO Approval, SPOC, UTR, Swift, Remarks,
              Payment Status, Payment Date,Approved At,Approved by,OnHold
              Reason,Reject Reason, PR Status ]
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<any>(files[0], {
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    console.log("res:", res[0]);
                    if (res.length === 0) {
                      showToast({
                        type: ToastType.info,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("PR_ID") === -1 ||
                      keys.indexOf("PO") === -1
                    ) {
                      showToast({
                        type: ToastType.error,
                        text: "Required fields are [id,po_no] !",
                      });
                    }
                    const filteredData: any[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const {
                        PR_ID,
                        PO,
                        UTR,
                        Swift,
                        payment_amount,
                        payment_status,
                        deleted,
                      } = res[index];
                      if (!PR_ID || !PO || !UTR || !Swift) {
                        showToast({
                          type: ToastType.error,
                          text: "Missing required data !",
                        });

                        return;
                      }

                      filteredData.push({
                        id: PR_ID,
                        po_no: PO,
                        utr: UTR,
                        swift: Swift,
                        payment_amount: payment_amount,
                        payment_status: payment_status,
                        deleted: deleted,
                      });
                    }

                    // Bulk upload
                    setShowImportInvoice(false);
                    console.log("filtered dta:", filteredData);
                    onImportInvoice(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      {paymentRequestType === "advance" && showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportPaymentRequestAdvance filter={filter} />
        </ModalDialog>
      )}
      {paymentRequestType === "invoice" && showExportInvoice && (
        <ModalDialog
          show={showExportInvoice}
          title="Export"
          onClose={() => setShowExportInvoice(false)}
        >
          <ExportPaymentRequestInvoice filter={filter} />
        </ModalDialog>
      )}
      {paymentRequestType === "advance" && showHistoryAdvance && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistoryAdvance !== undefined}
          onClose={() => setShowHistoryAdvance(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          <ActionHistoryAdvance
            pr_id={showHistoryAdvance}
            data={showHistoryAdvance1}
          />
        </DrawerModal>
      )}

      {paymentRequestType === "invoice" && showHistoryInvoice && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistoryInvoice !== undefined}
          onClose={() => setShowHistoryInvoice(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          <ActionHistoryInvoice
            pr_id={showHistoryInvoice}
            data={showHistoryInvoice1}
          />
        </DrawerModal>
      )}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                PO Payment Requests Approval
              </p>
              <button
                title="Refersh"
                onClick={() => debouncedHandler({ page, count, search })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-1 ">
              <div className="text-sm flex items-center gap-2">
                <div className="flex items-center  gap-1">
                  <label htmlFor="fromDate" className="">
                    Created Date
                  </label>
                  <input
                    type="date"
                    id="fromDate"
                    className="p-1 rounded"
                    value={
                      filter.filterData?.created_date
                        ? moment(filter.filterData?.created_date).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                    onChange={(e) => {
                      const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                      const fromDate = selectedDate
                        ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                        : undefined; // Reset if no date is selected

                      // Update the filter
                      setFilter({
                        ...filter,
                        filterData: {
                          ...filter.filterData,
                          created_date: fromDate,
                        },
                      });
                    }}
                  />
                </div>
                <div className="flex items-center  gap-1">
                  <label htmlFor="toDate" className="">
                    Due Date
                  </label>
                  <input
                    type="date"
                    name=""
                    id="toDate"
                    className="p-1 rounded "
                    value={
                      filter.filterData?.due_date
                        ? moment(filter.filterData?.due_date).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                    onChange={(e) => {
                      const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                      const dueDate = selectedDate
                        ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                        : undefined; // Reset if no date is selected

                      // Update the filter
                      setFilter({
                        ...filter,
                        filterData: {
                          ...filter.filterData,
                          due_date: dueDate,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  if (paymentRequestType === "advance") {
                    setShowImport(true);
                  } else if (paymentRequestType === "invoice") {
                    setShowImportInvoice(true);
                  }
                }}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowBottomLeft} size="15"></Icon>
                Import
              </button>
              <button
                onClick={() => {
                  if (paymentRequestType === "advance") {
                    setShowExport(true);
                  } else {
                    setShowExportInvoice(true);
                  }
                }}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} className="h-4 w-4"></Icon>
                Export PR
              </button>
              <SearchFieldWidget
                defaulValue={search}
                onClear={() => {
                  setSearch("");
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setPage(1);
                  setSearch(val);
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 m-3">
            <div className="">
              <FilterDropDown
                placeholder="Payment Status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      payment_status: undefined,
                    },
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      payment_status: filter.filterData?.payment_status?.filter(
                        (f: any) => f !== type
                      ),
                    },
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    filterData: {
                      ...old.filterData,
                      payment_status: [
                        ...(old.filterData?.payment_status ?? []),
                        type,
                      ],
                    },
                  }));
                }}
                // selectedOptions={filter.filterData?.ticket_type ?? [ComplaintTicketType.incident]}
                selectedOptions={filter.filterData?.payment_status ?? []}
                options={Object.values(payment_status).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="">
              <FilterDropDown
                placeholder="PR Status"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      pr_status: undefined,
                    },
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      pr_status: filter.filterData?.pr_status?.filter(
                        (f: any) => f !== type
                      ),
                    },
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    filterData: {
                      ...old.filterData,
                      pr_status: [...(old.filterData?.pr_status ?? []), type],
                    },
                  }));
                }}
                // selectedOptions={filter.filterData?.ticket_type ?? [ComplaintTicketType.incident]}
                selectedOptions={filter.filterData?.pr_status ?? []}
                options={Object.values(po_payments_req_status).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="">
              <FilterDropDown
                placeholder="select payment"
                onClearAll={() =>
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      payment_mode: undefined,
                    },
                  })
                }
                onDelete={(type) => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      payment_mode: filter.filterData?.payment_mode?.filter(
                        (f: any) => f !== type
                      ),
                    },
                  });
                }}
                onSelect={(type) => {
                  setFilter((old) => ({
                    ...old,
                    filterData: {
                      ...old.filterData,
                      payment_mode: [
                        ...(old.filterData?.payment_mode ?? []),
                        type,
                      ],
                    },
                  }));
                }}
                // selectedOptions={filter.filterData?.ticket_type ?? [ComplaintTicketType.incident]}
                selectedOptions={filter.filterData?.payment_mode ?? []}
                options={Object.values(PaymentModeOptions).map((t) => ({
                  label: t,
                  value: t,
                }))}
              />
            </div>
            <div className="flex flex-row gap-1">
              <label className="text-md mb-2 pt-1 font-semibold">PO-No</label>
              <SearchFieldWidget
                defaulValue={filter.filterData?.poNo}
                onClear={() => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      poNo: undefined,
                    },
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      poNo: val, // Use the entered value
                    },
                  });
                  setPage(1); // Reset pagination
                }}
              />
            </div>
            <div className="flex flex-row gap-1">
              <label className="text-md mb-2 pt-1 font-semibold">PR-ID</label>
              <SearchFieldWidget
                defaulValue={filter.filterData?.pr_id}
                onClear={() => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      pr_id: undefined,
                    },
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      pr_id: val, // Use the entered value
                    },
                  });
                  setPage(1); // Reset pagination
                }}
              />
            </div>
            <div className="flex flex-row gap-1">
              <label className="text-md mb-2 pt-1 font-semibold">PI No.</label>
              <SearchFieldWidget
                defaulValue={filter.filterData?.pi_no_Or_Inv_no}
                onClear={() => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      pi_no_Or_Inv_no: undefined,
                    },
                  });
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    filterData: {
                      ...filter.filterData,
                      pi_no_Or_Inv_no: val, // Use the entered value
                    },
                  });
                  setPage(1); // Reset pagination
                }}
              />
            </div>
          </div>
          <div className="flex gap-1 mb-4">
            <button
              onClick={() => setPaymentRequestType("advance")}
              className={`${
                paymentRequestType === "advance"
                  ? "bg-myPrimaryColor text-white border-2"
                  : "bg-gray-100 border-2"
              } px-4 py-2 rounded-md`}
            >
              Advance Payment
            </button>
            <button
              onClick={() => setPaymentRequestType("invoice")}
              className={`${
                paymentRequestType === "invoice"
                  ? "bg-myPrimaryColor text-white border-2"
                  : "bg-gray-100 border-2"
              } px-4 py-2 rounded-md`}
            >
              Against Invoice
            </button>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>
        {paymentRequestType === "advance" && (
          <NewTableView
            stickyHeader={true}
            show_index={true}
            show_pagination={true}
            headers={[
              {
                key: "pay_req_id",
                value: "PR ID #",
                type: FieldType.string,
              },
              {
                key: "po_no",
                value: "PO #",
                type: FieldType.string,
              },
              {
                key: "po_date",
                value: "PO Date",
                type: FieldType.date,
              },
              {
                key: "request_date",
                value: "Request Date",
                type: FieldType.date,
              },
              {
                key: "vendor_name",
                value: "Vendor",
                type: FieldType.string,
              },
              {
                key: "due_date",
                value: "Due Date",
                type: FieldType.date,
              },
              {
                key: "payment_mode",
                value: "Payment Mode",
                type: FieldType.string,
              },
              {
                key: "PI_no",
                value: "PI NO",
                type: FieldType.string,
              },
              {
                key: "pi_date",
                value: "PI Date",
                type: FieldType.date,
              },
              {
                key: "PI_amount",
                value: "PI Amount",
                type: FieldType.currency,
              },
              {
                key: "amount",
                value: "Payment Amount",
                type: FieldType.currency,
              },
              {
                key: "project_name",
                value: "Project",
                type: FieldType.string,
              },
              {
                // colspan: 4,
                key: "description",
                value: "Note",
                type: FieldType.long_string,
              },
              {
                key: "po_approval_status",
                value: "PO Approval",
                type: FieldType.string,
              },
              {
                key: "created_by",
                value: "SPOC",
                type: FieldType.string,
              },
              {
                key: "utr",
                value: "UTR",
                type: FieldType.string,
              },
              {
                key: "swift",
                value: "Swift",
                type: FieldType.string,
              },
              {
                key: "Remarks",
                value: "Remarks",
                type: FieldType.string,
              },
              // {
              //   key: "reject_remarks",
              //   value: "Pay Reject Remarks",
              //   type: FieldType.string,
              // },
              {
                key: "status",
                value: "Payment Status",
                type: FieldType.string,
              },
              {
                key: "pay_date",
                value: "Payment Date",
                type: FieldType.date,
              },
              {
                key: "approved_at",
                value: "Approved At",
                type: FieldType.date,
              },
              {
                key: "approved_by",
                value: "Approved by",
                type: FieldType.string,
              },
              {
                key: "onhold_reason",
                value: "OnHold Reason",
                type: FieldType.string,
              },
              {
                key: "reason",
                value: "Reject Reason",
                type: FieldType.string,
              },
              {
                key: "pr_status",
                value: "PR Status",
                type: FieldType.string,
              },
            ]}
            rows={paymentRequestAdvance.map((data, i) => ({
              data: {
                ...data,
                amount: data.payment_amount,
                description: data.notes,
                status: data.payment_status,
              },
              cells: [
                {
                  builder: () => (
                    <a
                      href={`${urls.po_advance_payment_request}/approve/${data?.id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {data.id}
                    </a>
                  ),
                },
                {
                  builder: () => (
                    <a
                      href={`${urls.purchase_order}/update/${data.po_id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {data.po_no}
                    </a>
                  ),
                },
                { value: data.po_date },
                { value: data.created_at },
                { value: data.vendor },
                { value: data?.due_date },
                { value: data?.payment_mode },
                { value: data?.pi_no },
                { value: data?.pi_date },
                { value: data?.pi_amount },
                { value: currencyFormat(data.payment_amount) },
                { value: data.project },
                { value: data.notes || "--" },
                { value: data.po_approval },
                { value: data.created_by_name },
                { value: data?.utr },
                { value: data?.swift },
                { value: data?.paid_remarks ?? data?.failed_remarks ?? "" },
                // { value: data?.failed_remarks },
                { value: data.payment_status },
                { value: data.payment_date },
                { value: data.approved_at },
                { value: data.approved_by_name },
                { value: data.onhold_reason },
                { value: data.reject_reason },
                { value: data.pr_status },
              ],
            }))}
            page={page}
            total={total}
            count={count}
            itemCount={paymentRequestAdvance.length}
            onPageChange={setPage}
            onShowHistory={(data) => {
              setShowHistoryAdvance(data.id);
              setShowHistoryAdvance1(data);
            }}
            // onClick={onSelect}
          />
        )}

        {paymentRequestType === "invoice" && (
          <NewTableView
            stickyHeader={true}
            show_index={true}
            show_pagination={true}
            headers={[
              {
                key: "pay_req_id",
                value: "PR ID #",
                type: FieldType.string,
              },
              {
                key: "po_no",
                value: "PO NO #",
                type: FieldType.string,
              },
              {
                key: "po_date",
                value: "PO Date",
                type: FieldType.date,
              },
              {
                key: "request_date",
                value: "Request Date",
                type: FieldType.date,
              },
              {
                key: "vendor_name",
                value: "Vendor",
                type: FieldType.string,
              },
              {
                key: "due_date",
                value: "Due Date",
                type: FieldType.date,
              },
              {
                key: "payment_mode",
                value: "Payment Mode",
                type: FieldType.string,
              },
              {
                key: "invoice_no",
                value: "Invoice Number",
                type: FieldType.string,
              },
              {
                key: "invoice_date",
                value: "Invoice Date",
                type: FieldType.date,
              },
              {
                key: "invoice_amount",
                value: "Invoice Amount",
                type: FieldType.currency,
              },
              {
                key: "amount",
                value: "Payment Amount",
                type: FieldType.currency,
              },
              {
                key: "project_name",
                value: "Project",
                type: FieldType.string,
              },
              {
                // colspan: 4,
                key: "description",
                value: "Note",
                type: FieldType.long_string,
              },
              {
                key: "po_approval_status",
                value: "PO Approval",
                type: FieldType.string,
              },
              {
                key: "location",
                value: "Customer Location",
                type: FieldType.string,
              },
              {
                key: "category_of_expense",
                value: "Category of Expense",
                type: FieldType.string,
              },
              {
                key: "no_of_installations",
                value: "No. of Installations",
                type: FieldType.string,
              },
              {
                key: "created_by",
                value: "SPOC",
                type: FieldType.string,
              },
              {
                key: "utr",
                value: "UTR",
                type: FieldType.string,
              },
              {
                key: "swift",
                value: "Swift",
                type: FieldType.string,
              },
              {
                key: "remarks",
                value: "Remarks",
                type: FieldType.string,
              },
              // {
              //   key: "reject_remarks",
              //   value: "Pay Reject Remarks",
              //   type: FieldType.string,
              // },
              {
                key: "status",
                value: "Paid/Rejected",
                type: FieldType.string,
              },
              {
                key: "pay_date",
                value: "Payment Date",
                type: FieldType.date,
              },
              {
                key: "approved_at",
                value: "Approved At",
                type: FieldType.date,
              },
              {
                key: "approved_by",
                value: "Approved by",
                type: FieldType.string,
              },
              {
                key: "onhold_reason",
                value: "OnHold Reason",
                type: FieldType.string,
              },
              {
                key: "reason",
                value: "Reject Reason",
                type: FieldType.string,
              },
              {
                key: "pr_status",
                value: "PR Status",
                type: FieldType.string,
              },
              // {
              //   key: "paid",
              //   value: "Paid Amount",
              //   type: FieldType.string,
              // },
              // {
              //   key: "balance",
              //   value: "Balance Amount",
              //   type: FieldType.string,
              // },
              {
                key: "gst",
                value: "GST No.",
                type: FieldType.string,
              },
            ]}
            rows={paymentRequestAgainstInvoice.map((data, i) => ({
              data: {
                ...data,
                amount: data.payment_amount,
                description: data.notes,
                status: data.payment_status,
              },
              cells: [
                {
                  builder: () => (
                    <a
                      href={`${urls.po_invoice_payment_request}/approve/${data?.id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {data.id}
                    </a>
                  ),
                },

                {
                  builder: () => (
                    <a
                      href={`${urls.purchase_order}/update/${data.po_id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {data.po_no}
                    </a>
                  ),
                },
                { value: data.po_date },
                { value: data.created_at },
                { value: data.vendor },
                { value: data?.due_date },
                { value: data?.payment_mode },
                { value: data?.invoice_no },
                { value: data?.invoice_date },
                { value: data?.invoice_amount },
                { value: currencyFormat(data.payment_amount) },
                { value: data?.project },
                { value: data?.notes || "--" },
                { value: data?.po_approval },
                { value: data?.customer_location },
                { value: data?.category_of_expence },
                { value: data?.no_of_intallations },
                { value: data.created_by_name },
                { value: data?.utr },
                { value: data?.swift },
                { value: data?.paid_remarks ?? data?.failed_remarks ?? "" },
                { value: data.payment_status },
                { value: data.payment_date },
                { value: data.approved_at },
                { value: data.approved_by_name },
                { value: data.onhold_reason },
                { value: data.reject_reason },
                { value: data.pr_status },
                { value: data.gst_no },
              ],
            }))}
            page={page}
            total={total}
            count={count}
            itemCount={paymentRequestAgainstInvoice.length}
            onPageChange={setPage}
            onShowHistory={(data) => {
              setShowHistoryInvoice(data.id);
              setShowHistoryInvoice1(data);
            }}
            // onClick={onSelect}
          />
        )}
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PaymentRequestApprovalListForAdvanceAndInvoice;
