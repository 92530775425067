import React, { FunctionComponent, useEffect, useState } from "react";
import { ItemModelV2, ItemModelV2Default } from "../../models/item_model";
import LoadingWidget from "../../../../context_providers/modal/loader";
import { ACTION, MasterItemStatus, MODULE } from "../../../../utils/enums";
import {
  approveItem,
  getSingleItemRepo,
  rejectItem,
} from "../repository/items_repository";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { urls } from "../../../../utils/urls";
import ProductItemCreationForm from "../components/product_item_creation_form";
import ServiceItemCreationForm from "../components/service_item_creation_form";
import { gst_rates, tax_type } from "../../../accounts/models/ledger_model";
import ProductItemUpdateForm from "../components/product_item_update";
import ServiceItemUpdateForm from "../components/service_item_update";

interface ItemCreateScreenProps {
  data?: ItemModelV2;
  onClose: (id: ItemModelV2) => void;
}

const ItemApproveScreen: FunctionComponent<ItemCreateScreenProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const is_approve_page = useRouteMatch(
    urls.procurement_masters_item + "/approve-reject-item/:id"
  );
  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState<"Product" | "Service">(
    "Product"
  );

  const { user, isAuthorised } = useAuth();
  const [canEdit, setCanEdit] = useState(true);
  const [checker, setChecker] = useState<boolean>(false);
  const [maker, setMaker] = useState<boolean>(false);
  const [approveRequired, setApproveRequired] = useState<boolean>(false);
  const [approveRejectUpdateRequired, setApproveRejectUpdateRequired] =
    useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const [productData, setProductData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const [serviceData, setServiceData] = useState<ItemModelV2>({
    ...(props.data || { ...ItemModelV2Default }),
  });

  const history = useHistory();
  const getData = async (id: string) => {
    setLoading(true);
    const res = await getSingleItemRepo(id);
    if (res.success) {
      console.log("res", res.data);
      if (res.data.type === "Product") setProductData(res.data);
      else if (res.data.type === "Service") setServiceData(res.data);
      else setData(res.data);
      setData(res.data);
      setSelectedType(res.data.type);
      console.log("Res", res.data.remarks);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getData(id); // Fetch data only once when the component mounts.
    }
    setCanEdit(false);

    const checker_result = isAuthorised({
      module: MODULE.master_item_checker,
      action: ACTION.UPDATE,
    });

    const maker_result = isAuthorised({
      module: MODULE.master_item_maker,
      action: ACTION.UPDATE,
    });

    setChecker(checker_result);
    setMaker(maker_result);

    console.log("checker", checker_result);
    console.log("maker", maker_result);
  }, [id]); // Only run this effect once when `id` is set.

  useEffect(() => {
    const isApprovePending =
      (selectedType === "Product" &&
        productData.status === MasterItemStatus.PendingForApproval) ||
      (selectedType === "Service" &&
        serviceData.status === MasterItemStatus.PendingForApproval);
    const isUpdateAprovePending =
      (selectedType === "Product" &&
        productData.status === MasterItemStatus.UpdatePending) ||
      (selectedType === "Service" &&
        serviceData.status === MasterItemStatus.UpdatePending);
    setApproveRequired(isApprovePending);
    setApproveRejectUpdateRequired(isUpdateAprovePending);
    if (is_approve_page && checker) {
      if (
        selectedType === "Product" &&
        (productData.status === MasterItemStatus.Rejected ||
          productData.status === MasterItemStatus.Approved)
      ) {
        setCanEdit(false);
      } else if (
        selectedType === "Service" &&
        (serviceData.status === MasterItemStatus.Rejected ||
          serviceData.status === MasterItemStatus.Approved)
      ) {
        setCanEdit(false);
      } else if (
        selectedType === "Product" &&
        productData.status === MasterItemStatus.SavedDraft
      ) {
        setCanEdit(false);
      } else if (
        selectedType === "Service" &&
        serviceData.status === MasterItemStatus.SavedDraft
      ) {
        setCanEdit(false);
      } else {
        setCanEdit(true);
      }
    }
    console.log("Approve Pending", isApprovePending);
    console.log("Update Pending", isUpdateAprovePending);
  }, [selectedType, productData, serviceData]); // Separate effect for delete-related logic.

  const approveMasterItem = async () => {
    try {
      if (selectedType === "Product") {
        let res = await approveItem(productData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Approved successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      } else if (selectedType === "Service") {
        let res = await approveItem(serviceData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Approved successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  const rejectMasterItem = async () => {
    try {
      if (selectedType === "Product") {
        // Set the Status first
        productData.status = MasterItemStatus.Rejected;
        let res = await rejectItem(productData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Rejected successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      } else if (selectedType === "Service") {
        // Set the Status first
        serviceData.status = MasterItemStatus.Rejected;
        let res = await rejectItem(serviceData);
        if (res.success) {
          console.log("res", res);
          navaigation.goBack();
          showToast({ type: ToastType.success, text: "Rejected successfully" });
        } else {
          showToast({ type: ToastType.error, text: res.error });
        }
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  return (
    <>
      <div className="flex flex-row m-4 gap-4">
        <div className="font-bold">Item Type</div>
        <div className="flex items-center">
          <input
            type="radio"
            name="itemType"
            value="Product"
            disabled={true}
            checked={selectedType === "Product"}
            onChange={(e) =>
              setSelectedType(e.target.value as "Product" | "Service")
            }
          />
          <label className="ml-2">Product</label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            name="itemType"
            value="Service"
            checked={selectedType === "Service"}
            disabled={true}
            onChange={(e) => {
              setData((o) => ({
                ...o,
                type: e.target.value as "Product" | "Service",
              }));
              setSelectedType(e.target.value as "Product" | "Service");
            }}
          />
          <label className="ml-2">Service</label>
        </div>
      </div>
      {selectedType === "Product" ? (
        <ProductItemUpdateForm data={productData} setData={setProductData} />
      ) : (
        <ServiceItemUpdateForm data={serviceData} setData={setServiceData} />
      )}
      <div className="  grid grid-cols-2 m-3 gap-2 select-none">
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <h4 className="font-semibold"> Tax details </h4>
          <div className="flex flex-row gap-6">
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                id="tax_yes"
                name="tax_applicable"
                value="yes"
                checked={
                  selectedType === "Product"
                    ? productData.tax_applicable
                    : selectedType === "Service"
                    ? serviceData.tax_applicable
                    : data.tax_applicable
                }
                onChange={() => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({ ...o, tax_applicable: true }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({ ...o, tax_applicable: true }));
                  }
                  setData((o) => ({ ...o, tax_applicable: true }));
                }}
                disabled={!canEdit}
              />
              <label htmlFor="tax_yes">Yes</label>
            </div>
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                id="tax_no"
                name="tax_applicable"
                value="no"
                checked={
                  selectedType === "Product"
                    ? !productData.tax_applicable
                    : selectedType === "Service"
                    ? !serviceData.tax_applicable
                    : !data.tax_applicable
                }
                onChange={() => {
                  if (selectedType === "Product") {
                    setProductData((o) => ({
                      ...o,
                      tax_applicable: false,
                    }));
                  } else if (selectedType === "Service") {
                    setServiceData((o) => ({
                      ...o,
                      tax_applicable: false,
                    }));
                  }
                  setData((o) => ({ ...o, tax_applicable: false }));
                }}
                disabled={!canEdit}
              />
              <label htmlFor="tax_no">No</label>
            </div>
          </div>
          {selectedType === "Product" || selectedType === "Service" ? (
            <>
              {(selectedType === "Product"
                ? productData.tax_applicable
                : serviceData.tax_applicable) && (
                <>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="tax_type">
                      Tax type{"*"}
                    </label>
                    <select
                      value={
                        selectedType === "Product"
                          ? productData.tax_detail?.type || "default"
                          : serviceData.tax_detail?.type || "default"
                      }
                      disabled={!canEdit}
                      onChange={(e) => {
                        const updatedType = e.target.value;
                        if (selectedType === "Product") {
                          setProductData((prev) => ({
                            ...prev,
                            tax_detail: {
                              ...prev.tax_detail,
                              type: updatedType,
                              hsn_sac: prev.tax_detail?.hsn_sac || "",
                            },
                          }));
                        } else {
                          setServiceData((prev) => ({
                            ...prev,
                            tax_detail: {
                              ...prev.tax_detail,
                              type: updatedType,
                              hsn_sac: prev.tax_detail?.hsn_sac || "",
                            },
                          }));
                        }
                      }}
                      name="tax_type"
                      id="tax_type"
                      className="p-1 rounded focus:outline-none w-full border"
                    >
                      <option value="default" disabled>
                        Select a tax type
                      </option>
                      {Object.values(tax_type).map((type, i) => (
                        <option value={type} key={i}>
                          {type.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex gap-5 items-center text-sm w-full">
                    {(selectedType === "Product"
                      ? productData.tax_detail.type === tax_type.gst
                      : serviceData.tax_detail.type === tax_type.gst) && (
                      <>
                        <div className="flex flex-col items-start text-sm w-full">
                          <label className="font-semibold w-full" htmlFor="hsn">
                            GST Rate*
                          </label>
                          <select
                            id="gstRate"
                            className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={
                              selectedType === "Product"
                                ? productData.tax_detail?.gst_rate || "default"
                                : serviceData.tax_detail?.gst_rate || "default"
                            }
                            onChange={(e) => {
                              const gstRate = Number(e.target.value);
                              if (selectedType === "Product") {
                                setProductData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    gst_rate: gstRate,
                                  },
                                }));
                              } else {
                                setServiceData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    gst_rate: gstRate,
                                  },
                                }));
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select GST Rate
                            </option>
                            {gst_rates.map((rate) => (
                              <option key={rate} value={rate}>
                                {rate}%
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col items-start text-sm w-full">
                          <label className="font-semibold w-full" htmlFor="hsn">
                            HSN/SAC
                          </label>
                          <input
                            type="text"
                            placeholder="Enter HSN or SAC number"
                            id="hsn"
                            name="hsn"
                            disabled={!canEdit}
                            value={
                              selectedType === "Product"
                                ? productData.tax_detail?.hsn_sac || ""
                                : serviceData.tax_detail?.hsn_sac || ""
                            }
                            onChange={(e) => {
                              const hsnSac = e.target.value;
                              if (selectedType === "Product") {
                                setProductData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    hsn_sac: hsnSac,
                                  },
                                }));
                              } else {
                                setServiceData((prev) => ({
                                  ...prev,
                                  tax_detail: {
                                    ...prev.tax_detail,
                                    hsn_sac: hsnSac,
                                  },
                                }));
                              }
                            }}
                            className="p-1 rounded focus:outline-none w-full border"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="remarks">
              Remarks/Notes
            </label>
            <textarea
              placeholder="Enter Remarks/Notes"
              className="p-1 rounded  focus:outline-none w-full border"
              id="remarks"
              name="remarks"
              disabled={false}
              value={
                selectedType === "Product"
                  ? productData.remarks
                  : selectedType === "Service"
                  ? serviceData.remarks
                  : data.remarks
              }
              onChange={(e) => {
                if (selectedType === "Product") {
                  setProductData((o) => ({
                    ...o,
                    remarks: e.target.value,
                  }));
                } else if (selectedType === "Service") {
                  setServiceData((o) => ({
                    ...o,
                    remarks: e.target.value,
                  }));
                }
                setData((o) => ({
                  ...o,
                  remarks: e.target.value,
                }));
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="m-4">
        <div className="flex justify-end  gap-5 mb-2">
          {/* {is_approve_page &&
          checker &&
          approveDeleteRequired &&
          !approveRequired ? (
            <>
              <div className="flex gap-5 mb-2">
               
                <button
                  onClick={() => {
                    approveRejectDeleteItem(MasterItemStatus.Rejected);
                  }}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-red-400 text-white px-3 py-1"
                >
                  Reject Delete
                </button>
              </div>
              <div className="flex  gap-5 mb-2">
                <button
                  onClick={() => {
                    approveRejectDeleteItem(MasterItemStatus.Approved);
                  }}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
                >
                  Approve Delete
                </button>
              </div>
            </>
          ) : null} */}
          {is_approve_page && checker && approveRequired ? (
            <>
              <div className="flex gap-5  mb-2">
                <button
                  onClick={rejectMasterItem}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-red-400 text-white px-3 py-1"
                >
                  Reject Creation
                </button>
              </div>
              <div className="flex  gap-5  mb-2">
                <button
                  onClick={approveMasterItem}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
                >
                  Approve Creation
                </button>
              </div>
            </>
          ) : null}
          {checker && is_approve_page && approveRejectUpdateRequired && (
            <>
              <div className="flex gap-5 mb-2">
                {/* TODO Have to change update method */}
                <button
                  onClick={rejectMasterItem}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-red-400 text-white px-3 py-1"
                >
                  Reject Update
                </button>
              </div>
              <div className="flex  gap-5 mb-2">
                <button
                  onClick={approveMasterItem}
                  type="button"
                  className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
                >
                  Approve Update
                </button>
              </div>
            </>
          )}
          <div>
            <button
              onClick={() => {
                navaigation.goBack();
              }}
              type="button"
              className="rounded-md text-sm font-semibold bg-white text-black px-3 py-1"
            >
              Quit
            </button>
          </div>
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default ItemApproveScreen;
