import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useState, useEffect } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { UTCToLocalDate } from "../../../../utils/date_util";
import LoadingWidget from "../../../../ui/pages/content_panel/ui/new_components/common/loading_spinner";
import StadiumButton from "../../../../ui/new_components/common/stadium_button";
import { PaymentRequestAdvanceModel } from "../../model/payment_request_model";
import { getPaymentAdvanceList } from "../../purchase_order_create/service/purchase_order_repository";

interface ExportPaymentRequestAdvanceProps {
  filter: {
    search?: string;
    page: number;
    count: number;
    all?: any;
    fromDate?: Date;
    toDate?: Date;
    sort_by?: string;
    sort_order?: string;
    // filterData?: {
    //   [key: string]: any;
    //   poNo?: string;
    //   pr_id?: string;
    //   pi_no_Or_Inv_no?: string;
    //   payment_mode?: string[];
    //   due_date?: Date;
    //   created_date?: Date;
    //   pr_status?: string[];
    //   payment_status?: string[];
    // };
  };
}

const ExportPaymentRequestAdvance: FunctionComponent<
  ExportPaymentRequestAdvanceProps
> = (props) => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PaymentRequestAdvanceModel[]>([]);
  const [fromDate, setFromdate] = useState<Date>();
  const [toDate, setTodate] = useState<Date>();
  console.log("fromDate:", fromDate, toDate);
  const getAdvancePRListData = async () => {
    if (!fromDate || !toDate) {
      showToast({
        type: ToastType.warning,
        text: "Please select date",
      });

      return;
    } else if (
      moment(fromDate, "YYYY-MM-DD").isAfter(moment(toDate, "YYYY-MM-DD"))
    ) {
      showToast({
        type: ToastType.warning,
        text: "From Date should not be greater than To Date ",
      });
      return;
    }
    setLoading(true);
    try {
      props.filter.fromDate = fromDate;
      props.filter.toDate = toDate;
      const searchProp = { ...props.filter };
      console.log("searchProp:", searchProp);
      searchProp.all = true;
      //   const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request`;
      //   const res = await fetchGet(url + toQueryString(searchProp));
      const res = await getPaymentAdvanceList(searchProp);
      if (res.success) {
        console.log("result is:", res.data.data);
        if (res.data.data.length === 0) {
          throw new Error("No data found");
        }
        setData(res.data);
        if (res.data) {
          const options = {
            filename: `PR Data ${moment(fromDate, moment.ISO_8601).format(
              "DD-MM-YYYY"
            )} - ${moment(toDate, moment.ISO_8601).format("DD-MM-YYYY")}`,
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: false,
            title: "PR Data",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
          };

          const csvExporter = new ExportToCsv(options);

          csvExporter.generateCsv(
            res.data.data.map((data: PaymentRequestAdvanceModel, i: number) => {
              return {
                "PR_ID": data.id,
                "PO": data.po_no,
                "PO Date": data?.po_date 
                ? moment(data?.po_date, moment.ISO_8601).isValid() 
                  ? moment(data?.po_date, moment.ISO_8601).format("DD-MM-YYYY") 
                  : "--"
                : "--",
                "Request Date": moment(data.created_at, moment.ISO_8601).format(
                  "DD-MM-YYYY"
                ) ?? "",
                "Vendor": data?.vendor ?? "--",
                "Due Date": moment(data.due_date, moment.ISO_8601).format(
                  "DD-MM-YYYY"
                ) ?? " ",
                "Payment Mode":data.payment_mode ?? "--",
                "PI NO":data.pi_no,
                "PI Date":moment(data.pi_date, moment.ISO_8601).format(
                  "DD-MM-YYYY"
                ) ?? " ",
                "PI Amount":data.pi_amount ?? "--",
                "payment_amount":data.payment_amount ?? "--",
                "Project": data.project ?? "--",
                "Note":data.notes ?? "--",
                "PO Approval":data.po_approval ?? "--",
                "SPOC":data.created_by_name ?? "--",
                "UTR":data.utr ?? "--",
                "Swift":data.swift ?? "--",
                "payment_status":data.payment_status ?? "--",
                "Approved At":data.approved_at ?? "--",
                "Approved by":data.approved_by_name ?? "--",
                "OnHold Reason":data?.onhold_reason ?? "--",
                "PR Status":data.pr_status ?? "--",
                // Item: `=HYPERLINK("https://${window.location.host}${
                //   urls.purchase_order + "/update/" + data.id
                // }", "${data.items[0].item.name}${
                //   data.items.length > 1
                //     ? `... and ${data.items.length - 1} more`
                //     : ""
                // }")`,
                Attachment: data.attachments.length ?? "--",
              };
            })
          );
        }
      } else {
        console.log(res.error);
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  useEffect(() => {
    const date = new Date();

    if (props?.filter.fromDate && props?.filter.toDate) {
      setFromdate(props?.filter.fromDate);
      setTodate(props?.filter.toDate);
    } else {
      setFromdate(new Date(date.setHours(0, 0, 0, 0)));
      setTodate(new Date(date.setHours(23, 59, 59, 999)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(0, 0, 0, 0));
              if (toDate && moment(date).isAfter(moment(toDate))) {
                showToast({
                  type: ToastType.warning,
                  text: "From Date should not be greater than To Date ",
                });
                return;
              }
              setFromdate(date);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={moment(toDate).format("YYYY-MM-DD")}
            min={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              let date = UTCToLocalDate(e.target.value)!;
              date = new Date(date.setHours(23, 59, 59, 999));
              if (fromDate && moment(fromDate).isAfter(moment(date))) {
                showToast({
                  type: ToastType.warning,
                  text: "To Date should  be greater than From Date ",
                });
                return;
              }
              setTodate(date);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getAdvancePRListData}>
          Export
        </StadiumButton>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportPaymentRequestAdvance;
