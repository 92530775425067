import React, { useState } from "react";
import { PaymentAttachmentModel } from "../../../erp/purchase_order/model/payment_request_model";
import { getFileType } from "../../../erp/purchase_order/model/purchase_order_model";

const AttachmentViewer = ({
  attachments,
}: {
  attachments: PaymentAttachmentModel[];
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onSelectAttachment = async (
    attachment: PaymentAttachmentModel
  ): Promise<void> => {
    try {
      // Fetch the file data from the URL
      const response = await fetch(attachment.url);
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a download link dynamically
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachment.url.split("/").pop() || "download.csv"; // Extract filename or set default
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Handle moving to the next attachment
  const nextAttachment = (event: React.MouseEvent) => {
    event.preventDefault();
    if (currentIndex < attachments?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handle moving to the previous attachment
  const prevAttachment = (event: React.MouseEvent) => {
    event.preventDefault();
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Render the current attachment
  const renderAttachment = () => {
    const attachment = attachments[currentIndex];

    if (attachment?.type === "Image") {
      return (
        <img
          src={attachment.url}
          alt={`Attachment ${currentIndex + 1}`}
          className="w-full h-auto"
        />
      );
    }

    if (attachment?.type === "application/pdf") {
      return (
        <div className="w-full h-96 overflow-auto">
          <embed
            src={attachment.url}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      );
    }
    if (attachment?.type === "CSV") {
      return (
        <div
          className="w-full h-96 flex items-center justify-center"
          onClick={() => onSelectAttachment(attachment)}
        >
          <button className="text-sm font-semibold text-blue-500 underline">
            Download CSV
          </button>
        </div>
      );
    }

    // Handle other file types, if needed
    return <p>No preview available for this attachment type</p>;
  };

  return (
    <div className="flex flex-col gap-4 bg-white rounded-md">
      {/* Attachment navigation and display */}
      <div className="flex flex-col gap-4">
        {attachments?.length > 0 ? (
          <div className="flex justify-between items-center">
            {/* <button
              onClick={prevAttachment}
              className="bg-gray-300 p-2 rounded-md"
              disabled={currentIndex === 0}
            >
              &lt;
            </button> */}

            <div className="flex justify-center items-center w-full h-96 overflow-auto">
              {renderAttachment()}
            </div>
          </div>
        ) : (
          <p>No attachments available</p>
        )}
      </div>
      <div className="flex flex-row items-center justify-center sticky bottom-0  p-4 rounded-b-md">
        <button
          onClick={prevAttachment}
          className="bg-gray-300 p-2 rounded-md"
          disabled={currentIndex === 0}
        >
          &lt;
        </button>
        <button
          onClick={nextAttachment}
          className="bg-gray-300 p-2 rounded-md"
          disabled={currentIndex === attachments?.length - 1}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default AttachmentViewer;
