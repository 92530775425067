import { fetchGet, toQueryString } from "../../../../service/api_client";

export const GetItemLogsAPI = async ({
  page,
  count,
  item_code,
}: {
  page?: number;
  count?: number;
  item_code: string;
}) => {
  const query_string = toQueryString({ page, count });
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${item_code}/item-logs${query_string}`;
  const res = await fetchGet(url);
  return res;
};
