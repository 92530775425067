import { useState } from "react";
import { PoItem } from "../../model/purchase_order_model";

const PoItemCard = ({ data }: { data: { items: PoItem[] } }) => {
  const [expanded, setExpanded] = useState<number | null>(null);

  return (
    <div className="">
      <div className="lg:hidden">
        <div className="flex flex-col gap-4">
          {data.items.map((item: PoItem, i: number) => (
            <div
              className="bg-white rounded-xl py-6 px-8 w-full flex flex-col items-left"
              key={i}
            >
              <div className="text-sm flex flex-col gap-4">
                <div className="text-sm font-bold">{item.item.name}</div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="">HSN/SAC-</div>
                  <div className="p-1 focus:outline-none border rounded w-full">
                    {item.item.tax_detail.hsn_sac ?? "--"}
                  </div>
                  <div className="">Quantity-</div>
                  <div className="p-1 focus:outline-none border rounded w-full">
                    {item.unit_no}
                  </div>
                  <div className="">Price-</div>
                  <div className="p-1 focus:outline-none border rounded w-full">
                    {item.price}
                  </div>
                  <div className="">Amount-</div>
                  <div className="p-1 focus:outline-none border rounded w-full">
                    {item.price*item.unit_no}
                  </div>
                  <div className="">Received qty-</div>
                  <div className="p-1 focus:outline-none border rounded w-full">
                    {item.received_unit_no}
                  </div>
                  <div className="">Returned qty-</div>
                  <div className="p-1 focus:outline-none border rounded w-full">
                    {item.returned_unit_no}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PoItemCard;
