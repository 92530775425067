import React, { useEffect } from "react";
import { ItemModelV2 } from "../../models/item_model";
import ItemUnitSearchField from "../../item_unit/item_unit_search_field";
import {
  ProductCreationCategory as ProductItemCreationCategory,
  ProductItemCreationSubCategoryElectricRawMaterials,
  ProductItemCreationSubCategoryOthers,
} from "../enums/enums";
import ItemNameSearchWidget from "./item_name_search_widget";
import ItemMPNSearchWidget from "./item_mpn_search_widget";
import ItemManufacturerSearchWidget from "./item_manufacturer_search_widget";
import ItemDescriptionBox from "./item_description_box";

interface ProductItemCreationFormProps {
  data: ItemModelV2;
  setData: (data: ItemModelV2) => void;
  canEdit?: boolean;
}

const ProductItemUpdateForm: React.FC<ProductItemCreationFormProps> = ({
  data,
  setData,
  canEdit,
}) => {
  useEffect(() => {
    console.log("Product Creation Form", canEdit);
  }, [canEdit]);

  return (
    <>
      <div className="  grid grid-cols-1 m-3 gap-2 select-none">
        <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
          <h4 className="font-semibold"> Item details </h4>
          <div className="flex flex-col items-start text-sm w-1/3">
            <label className="font-semibold w-full" htmlFor="category">
              Category*
            </label>
            <select
              required
              className="p-1 rounded  focus:outline-none w-full border"
              name="category"
              id="category"
              value={data.category || ""}
              disabled={!canEdit}
              onChange={(e) => {
                setData({
                  ...data,
                  category: e.target.value as
                    | "Electrical"
                    | "Electronics"
                    | "Others",
                });
              }}
            >
              <option value="" hidden>
                {" "}
                select
              </option>
              {ProductItemCreationCategory.map((category) => (
                <option value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div>
              <label className="font-semibold w-full" htmlFor="name">
                Name*
              </label>
              <ItemNameSearchWidget
                type="Product"
                value={data.name}
                disabled={canEdit}
                onSelect={(e) => {
                  console.log("onSelect", e);
                  // Assuming e contains the selected value directly
                  setData({ ...data, name: e });
                }}
              />
            </div>

            {/* <div className="flex flex-col items-start text-sm w-full">
        <label className="font-semibold w-full" htmlFor="name">
          Name*
        </label>
        <input
          placeholder="Enter name"
          className="p-1 rounded  focus:outline-none w-full border"
          id="name"
          name="name"
          value={data.name || ""}
          disabled={!canEdit}
          // required={true}
          type="text"
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        ></input>
      </div> */}
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="description">
                Description
              </label>
              {/* <textarea
            placeholder="Enter Description"
            className="p-1 rounded  focus:outline-none w-full border"
            id="desc"
            name="desc"
            value={data.description || ""}
            disabled={!canEdit}
            onChange={(e) => {
              setData({ ...data, description: e.target.value });
            }}
          ></textarea> */}
              <ItemDescriptionBox
                type="Product"
                value={data.description}
                disabled={canEdit}
                onSelect={(e) => {
                  console.log("Description selected", e);
                  setData({ ...data, description: e });
                }}
              />
            </div>
            {(data.category === "Electrical" ||
              data.category === "Electronics" ||
              data.category === "Others") && (
              <>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="subcategory">
                    Sub-Category
                  </label>
                  <select
                    required
                    className="p-1 rounded focus:outline-none w-full border"
                    name="subcategory"
                    id="subcategory"
                    value={data.subcategory || ""}
                    disabled={!canEdit}
                    onChange={(e) => {
                      setData({ ...data, subcategory: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      {" "}
                      select
                    </option>
                    {ProductItemCreationSubCategoryElectricRawMaterials.map(
                      (subcategory) => (
                        <option value={subcategory}>{subcategory}</option>
                      )
                    )}
                  </select>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="name">
                    Manufacturer
                  </label>

                  <ItemManufacturerSearchWidget
                    type="Product"
                    value={data.manufacturer}
                    disabled={canEdit}
                    onSelect={(e) => {
                      console.log("onSelect e", e);
                      // Assuming e contains the selected value directly
                      setData({ ...data, manufacturer: e });
                    }}
                  />
                  {/* <input
                placeholder="Enter Manufacturer"
                className="p-1 rounded  focus:outline-none w-full border"
                id="manufacturer"
                name="manufacturer"
                value={data.manufacturer || ""}
                disabled={!canEdit}
                // required={true}
                type="text"
                onChange={(e) => {
                  setData({ ...data, manufacturer: e.target.value });
                }}
              ></input> */}
                </div>

                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="mpn">
                    MPN*
                  </label>
                  <ItemMPNSearchWidget
                    type="Product"
                    value={data.mpn}
                    disabled={canEdit}
                    onSelect={(e) => {
                      console.log("onSelect e", e);
                      // Assuming e contains the selected value directly
                      setData({ ...data, mpn: e });
                    }}
                  />

                  {/* <label className="font-semibold w-full" htmlFor="name">
                MPN
              </label>
              <input
                placeholder="Enter MPN"
                className="p-1 rounded  focus:outline-none w-full border"
                id="name"
                name="name"
                value={data.mpn || ""}
                disabled={!canEdit}
                // required={true}
                type="text"
                onChange={(e) => {
                  setData({ ...data, mpn: e.target.value });
                }}
              ></input> */}
                </div>
              </>
            )}
            {data.category === "Electronics" && (
              <>
                {/* Components */}
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="components">
                    Components
                  </label>
                  <input
                    placeholder="Enter Component"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="components"
                    name="components"
                    value={data.components || ""}
                    // required={true}
                    disabled={canEdit}
                    type="text"
                    onChange={(e) => {
                      setData({ ...data, components: e.target.value });
                    }}
                  ></input>
                </div>
                {/* Components Sub Type */}
                <div className="flex flex-col items-start text-sm w-full">
                  <label
                    className="font-semibold w-full"
                    htmlFor="components-sub-type"
                  >
                    Components Sub Type
                  </label>
                  <input
                    placeholder="Enter Component Sub Type"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="components-sub-type"
                    name="components-sub-type"
                    value={data.component_sub_type || ""}
                    disabled={canEdit}
                    // required={true}
                    type="text"
                    onChange={(e) => {
                      setData({ ...data, component_sub_type: e.target.value });
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label
                    className="font-semibold w-full"
                    htmlFor="mounting-style"
                  >
                    Mounting Style
                  </label>
                  <select
                    required
                    className="p-1 rounded focus:outline-none w-full border"
                    name="mounting-style"
                    id="mounting-style"
                    value={data.mounting_style || ""}
                    disabled={canEdit}
                    onChange={(e) => {
                      setData({
                        ...data,
                        mounting_style: e.target.value as "SMT" | "THT",
                      });
                    }}
                  >
                    <option value="" hidden>
                      {" "}
                      select
                    </option>
                    {["SMD", "THT"].map((subcategory) => (
                      <option value={subcategory}>{subcategory}</option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="value">
                    Value
                  </label>
                  <input
                    placeholder="Enter Value"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="value"
                    name="value"
                    value={data.value || ""}
                    disabled={canEdit}
                    // required={true}
                    type="text"
                    onChange={(e) => {
                      setData({ ...data, value: e.target.value });
                    }}
                  ></input>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="footprint">
                    Footprint
                  </label>
                  <input
                    placeholder="Enter Footprint"
                    className="p-1 rounded  focus:outline-none w-full border"
                    id="footprint"
                    name="footprint"
                    value={data.footprint || ""}
                    disabled={canEdit}
                    // required={true}
                    type="text"
                    onChange={(e) => {
                      setData({ ...data, footprint: e.target.value });
                    }}
                  ></input>
                </div>
              </>
            )}

            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="unit">
                Unit measure *
              </label>
              <ItemUnitSearchField
                value={data.unit}
                onSelect={(e) => {
                  setData({ ...data, unit: e });
                }}
                disabled={canEdit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductItemUpdateForm;
