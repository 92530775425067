import { FieldType } from "../../../../../../../../procurement/components/create_view/form_field";

export const requiredFields = [
  "Vehicle Type",
  "Category (2W/3W/4W/Others)",
  "Brand",
  "Model",
  "Battery Capacity (kWh)",
  "Connector Type",
  "Range (KMs/charge)",
  "Image",
  "Link",
];

export const requiredFieldsKeyname: { [header: string]: string } = {
  "Vehicle Type": "type",
  "Category (2W/3W/4W/Others)": "category",
  Brand: "brand",
  Model: "model",
  "Battery Capacity (kWh)": "battery_capacity",
  "Charging Standard": "charging_standard", // Added based on field names
  "Connector Type": "connector_type",
  "Range (KMs/charge)": "range",
  Image: "image", // Assuming an image field exists
  Link: "link",
};

export const EVTableHeaderList = [
  {
    key: requiredFieldsKeyname["Vehicle Type"],
    value: "Vehicle Type",
    type: FieldType.string,
  },
  // {
  //   key: requiredFieldsKeyname["Category (2W/3W/4W/Others)"],
  //   value: "Category (2W/3W/4W/Others)",
  //   type: FieldType.string,
  // },
  {
    key: requiredFieldsKeyname["Brand"],
    value: "Brand",
    type: FieldType.string,
  },
  {
    key: requiredFieldsKeyname["Model"],
    value: "Model",
    type: FieldType.string,
  },
  {
    key: requiredFieldsKeyname["Battery Capacity (kWh)"],
    value: "Battery Capacity (kWh)",
    type: FieldType.string,
  },
  {
    key: requiredFieldsKeyname["Charging Standard"],
    value: "Charging Standard",
    type: FieldType.string,
  },
  {
    key: requiredFieldsKeyname["Connector Type"],
    value: "Connector Type",
    type: FieldType.string,
  },
  {
    key: requiredFieldsKeyname["Range (KMs/charge)"],
    value: "Range (KMs/charge)",
    type: FieldType.string,
  },

  // {
  //   key: requiredFieldsKeyname["Link"],
  //   value: "Link",
  //   type: FieldType.string,
  // },
];
