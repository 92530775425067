import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  mdiApplicationEditOutline,
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiClose,
  mdiComment,
  mdiCommentAlert,
  mdiCommentBookmark,
  mdiCommentEye,
  mdiFileDocument,
  mdiFileExcel,
  mdiHistory,
  mdiMicrosoftExcel,
  mdiNoteEdit,
  mdiPencilOutline,
  mdiRefresh,
  mdiTrayArrowDown,
} from "@mdi/js";
import { mdiDeleteForever } from "@mdi/js";
import { Icon } from "@mdi/react";
import ModalDialog from "../../new_components/common/modal";
import InventoryCreate from "./components/create_widget";
import {
  CreateInventoryApi,
  // CreateInventoryApiV2,
  DeleteInventoryApi,
  GetInventoryListApi,
  UpdateInventoryApi,
} from "../../../service/repos/inventory_repo";
import {
  InventoryImportData,
  InventoryModel,
} from "../../../service/models/inventory/inventory_model";

import { MODULE, ACTION, InventoryStatus } from "../../../utils/enums";
import Pagination from "../../new_components/common/pagination";
import LoadingWidget from "../../components/loading_spinner";

import DeviceAllocationScreen from "./allocation";

import FileDropZone from "../../new_components/common/file_drop_zone";
import { parse } from "papaparse";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { urls } from "../../../utils/urls";
import SearchFieldWidget from "../../new_components/search_field";
import FilterDropDown from "../../new_components/common/filter_drop_down";
import { GetWareHouseListApi } from "../../../service/repos/warehouse_repo";
import { WarehouseModel } from "../../../service/models/inventory/warehouse_model";

import ExportInventory from "./components/export_widget";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../new_components/common/drawer_modal";
import HistoryWidget from "./components/history_widget";
import CommentWidget from "./components/comment_widget";
import { GetProductsApi } from "../../../service/repos/products_repo";
import ProductModel from "../../../service/models/product_model";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { OrderDevice } from "../orders/order_detail/order_devices/order_device_row";
import EmployeeSearchWidget from "../../new_components/emp_search_widget";
import { UserModel } from "../../../context_providers/auth/user_model";
interface InventoryListingProps {}
// const data = [
//   {
//     device_id: "L5addvs",
//     warehouse: "Bangalore hub",
//     city: "bangalore",
//     state: "Karnataka",
//     product: "Kazam 3.3",
//     mobile: "9353987496",
//     sim_no: "1234567905255",
//     sim_ismi: "165464444",
//     sim_status: "deactive",
//     plan_name: "RS 35",
//     module_imei: "5646444",
//     order_id: "ORD-1",
//   },
// ];

interface FilterData {
  product_id?: string[];
  status?: string[];
  search?: string;
  page: number;
  count: number;
  all?: any;
  warehouse_id?: string[];
  fromDate?: Date;
  toDate?: Date;
  responsible_id?: string;
  responsible_name?: string;
}

const defaultFilter: FilterData = {
  page: 1,
  count: 100,
};
const InventoryListing: FunctionComponent<InventoryListingProps> = () => {
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const [filter, setFilter] = useState<FilterData>(defaultFilter);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState<InventoryModel[]>([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showAllot, setShowAllot] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [selected, setSelected] = useState<InventoryModel[]>([]);
  const [showUpdate, setShowUpdate] = useState(false);
  const [warehouses, setWarehouses] = useState<WarehouseModel[]>([]);
  const [showExport, setShowExport] = useState(false);
  const [showHistory, setShowHistory] = useState(0);
  const [showExistingDevices, setExistingDevices] = useState(false);
  const [existingDevicesList, setExistingDevicesList] = useState([]);
  const [showComment, setShowComment] = useState(0);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<ProductModel[]>([]);
  const getProducts = async () => {
    const res = await GetProductsApi();
    if (res.success) {
      setProducts(res.data);
    }
  };

  const getWarehouses = async () => {
    const res = await GetWareHouseListApi({ page: 0, all: true, count: 0 });
    console.log({ res });

    if (res.success) {
      setWarehouses(res.data.data!);
    }
  };
  useEffect(() => {
    getWarehouses();
    getProducts();
  }, []);
  useEffect(() => {
    getInventories();
  }, [filter, search]);

  const getInventories = async () => {
    setLoading(true);

    const res = await GetInventoryListApi(
      search ? { page: 1, search, count: 0, all: true } : filter
    );

    if (res.success) {
      // if (res.data.length > 0) {
      setInventories(res.data.inventories);
      if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);
      // }
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const onSubmit = async (data: InventoryModel[]) => {
    setLoading(true);
    // const res = await CreateInventoryApiV2(data);
    const res = await CreateInventoryApi(data);

    if (res.success) {
      const data = res.data;
      if (data.created.length > 0) {
        // setInventories((o) => [...data.created, ...o]);
        setShowCreate(false);
        getInventories();
      }
      if (data.exist.length > 0) {
        alert("Device id exists " + `[${data.exist}]`);
      }
      if (data.invalid.length > 0) {
        alert("Device invalid " + `[${data.invalid}]`);
      }
      if (res.data.created.length)
        showToast({
          type: ToastType.success,
          text: `Created ${res.data.created.length} records.`,
        });
    } else {
      if (res.existing_device && res.existing_device === true) {
        showToast({ type: ToastType.error, text: res.message ?? "" });
        setExistingDevices(true);
        setExistingDevicesList(res.existingDevices);
      } else {
        showToast({ type: ToastType.error, text: res.error ?? "" });
      }
    }
    setLoading(false);
  };
  const onUpdate = async (temp: InventoryModel[]) => {
    setLoading(true);

    const res = await UpdateInventoryApi(temp);

    if (res.success) {
      getInventories();
      setSelected([]);
      setShowUpdate(false);
      return;

      // setInventories((o) =>
      //   o.map((d) => {

      //     if (d.id === data?.id) return res.data;
      //     else return d;
      //   })
      // );
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const downloadExistingDevices = () => {
    const csvContent = [
      [
        "device_id",
        "product_id",
        "product_name",
        "warehouse_id",
        "status",
        "responsible_email",
      ], // Header row
      ...existingDevicesList.map((device: any) => [
        device.device_id,
        device.product_id,
        device.product_name,
        device.warehouse_id,
        device.status,
        device.responsible_email,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "existing_devices.csv";
    link.click();
    URL.revokeObjectURL(url); // Clean up the URL object
  };

  const onDelete = async (device_ids: string[]) => {
    setLoading(true);

    const res = await DeleteInventoryApi(device_ids);

    if (res.success) {
      getInventories();
      const { invalid_ids, delivered_ids } = res.data;
      if (invalid_ids.length > 0) {
        showToast({
          type: ToastType.warning,
          text: `Invalid device IDs : [${invalid_ids}]`,
        });
      }
      if (delivered_ids.length > 0) {
        showToast({
          type: ToastType.warning,
          text: `Delivered device IDs : [${delivered_ids}]`,
        });
      }
      setSelected([]);
      return;
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [createDisabled, setCreateDisabled] = useState(true);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canCreate, setCanCreate] = useState(false);

  useEffect(() => {
    setCanDelete(
      isAuthorised({
        module: MODULE.device_inventory_panel,
        action: [ACTION.DELETE],
      })
    );
    setCanUpdate(
      isAuthorised({
        module: MODULE.device_inventory_panel,
        action: [ACTION.UPDATE],
      })
    );
    setCanCreate(
      isAuthorised({
        module: MODULE.device_inventory_panel,
        action: [ACTION.CREATE],
      })
    );
  }, []);

  useEffect(() => {
    let delivered = false;

    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      if (
        [InventoryStatus.installed, InventoryStatus.delivered].includes(
          element.status
        ) &&
        element.allocations.find((od) =>
          [InventoryStatus.installed, InventoryStatus.delivered].includes(
            od.status
          )
        )
      ) {
        delivered = true;
        break;
      }
    }

    const disable = selected.length === 0 || !canDelete || delivered;

    setDeleteDisabled(disable);
    setUpdateDisabled(disable);
    setCreateDisabled(!canCreate);
  }, [selected, canCreate]);

  return (
    <>
      {showHistory !== 0 && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistory !== 0}
          onClose={() => setShowHistory(0)}
          title="History"
        >
          <HistoryWidget inventory_id={showHistory} />
        </DrawerModal>
      )}
      {showComment !== 0 && (
        <DrawerModal
          onClose={() => setShowComment(0)}
          title="Note"
          show={showComment !== 0}
          location={DrawerModalLoaction.right}
        >
          <CommentWidget inventory_id={showComment} />
        </DrawerModal>
      )}
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportInventory filter={filter} />
        </ModalDialog>
      )}
      {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Inventory"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              device_id,product_id,product_name,warehouse_id,mobile_number,
              module_imei,sim_imsi,plan_name,sim_no,sim_status,
              status,responsible_email
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<InventoryImportData>(files[0], {
                  transformHeader(header, index) {
                    return header.trim();
                  },
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    console.log({ res });

                    if (res.length === 0) {
                      showToast({
                        type: ToastType.warning,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("device_id") === -1 ||
                      keys.indexOf("product_id") === -1 ||
                      keys.indexOf("warehouse_id") === -1
                    ) {
                      showToast({
                        type: ToastType.warning,
                        text: "Required fields are [device_id,product_id,product_name,warehouse_id]",
                      });
                      return;
                    }
                    const filteredData: InventoryModel[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const {
                        device_id,
                        product_id,
                        product_name,
                        warehouse_id,
                        mobile_number,
                        module_imei,
                        sim_imsi,
                        plan_name,
                        sim_no,
                        sim_status,
                        status,
                        order_id,
                        delivery_date,
                        installation_date,
                        org_name,
                        org_namespace,
                        return_back_date,
                        responsible_email,
                      } = res[index];
                      if (
                        !device_id &&
                        !product_id &&
                        !warehouse_id &&
                        !responsible_email
                      )
                        continue;
                      if (
                        !device_id ||
                        !product_id ||
                        !warehouse_id ||
                        !responsible_email
                      ) {
                        console.log({
                          device_id,
                          product_id,
                          warehouse_id,
                          responsible_email,
                          index,
                        });

                        showToast({
                          type: ToastType.warning,
                          text:
                            `Either Device id, product_id, warehouse_id, responsible_email missing at row ` +
                            (index + 2),
                        });
                        return;
                      }
                      const allocations: OrderDevice[] = [];
                      if (order_id) {
                        allocations.push({
                          active: true,
                          id: "",
                          order_id,
                          device_id,
                          product_id: 0,
                          status,
                          org_namespace,
                          org_name,
                          product_name,
                          installation_date,
                          return_back_date,
                          delivery_date,
                          item_id: "",
                          created_by: "",
                          created_at: new Date(),
                          created_by_name: "",
                          updated_by: "",
                          updated_by_name: "",
                        });
                      }
                      filteredData.push({
                        warehouse_id: Number(warehouse_id),
                        device_id,
                        device: {
                          device_id,
                          product_id: Number(product_id),
                          product_name,
                          mobile_number,
                          module_imei,
                          sim_imsi,
                          plan_name,
                          sim_no,
                          sim_status,
                        },
                        allocations,
                        status,
                        responsible_email,
                      });
                    }

                    // Bulk upload
                    setShowImport(false);
                    onSubmit(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      <div className={`px-3 py-5 h-screen  `}>
        {showCreate && (
          <ModalDialog
            show={showCreate}
            onClose={() => setShowCreate(false)}
            title={"Add Inventory"}
          >
            <InventoryCreate warehouses={warehouses} onSubmit={onSubmit} />
          </ModalDialog>
        )}
        {showUpdate && (
          <ModalDialog
            show={showUpdate}
            onClose={() => setShowUpdate(false)}
            title={"Update Inventory"}
          >
            <InventoryCreate
              warehouses={warehouses}
              data={selected}
              onSubmit={onUpdate}
            />
          </ModalDialog>
        )}

        <div className="flex flex-wrap justify-between items-end my-3 text-sm">
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            Device Inventory{" "}
            <button onClick={() => getInventories()}>
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex flex-wrap gap-2  ">
            <SearchFieldWidget
              defaulValue={search}
              onSubmit={(s) => setSearch(s)}
              onClear={() => setSearch("")}
            />
            <button
              disabled={createDisabled}
              onClick={() => setShowExport(true)}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
            >
              <Icon path={mdiArrowTopRight} size="15"></Icon> Export
            </button>
            <button
              disabled={createDisabled}
              onClick={() => setShowImport(true)}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center "
            >
              <Icon path={mdiArrowBottomLeft} size="15"></Icon>
              Import
            </button>
            <button
              disabled={createDisabled}
              onClick={() => setShowCreate(true)}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white"
            >
              ADD
            </button>
          </div>
        </div>
        {/* {showExistingDevices !== false &&
          (() => {
            console.log("Hello World");
            return (
              <div className="bg-red-600 rounded-lg border px-2 py-1">
                Hello World
              </div>
            );
          })()} */}

        {showExistingDevices !== false &&
          existingDevicesList.length > 0 &&
          (() => {
            return (
              <div className="rounded-lg border border-red-800 m-2 px-4 py-3">
                <h2 className="text-lg font-bold">
                  Some Device IDs Already Exist
                </h2>
                <p className="mt-2">
                  The following device IDs already exist in the inventory.
                  Please review the list.
                </p>
                {/* <ul className="list-disc ml-6 mt-2">
                  {existingDevicesList.map((device, index) => (
                    <li key={index}>
                      <strong>Device ID:</strong> {device.device_id},{" "}
                      <strong>Status:</strong> {device.status}
                    </li>
                  ))}
                </ul> */}
                <button
                  onClick={downloadExistingDevices}
                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
                >
                  Download Device IDs
                </button>
              </div>
            );
          })()}

        {/* <div className="mt-3">
          <Pagination
            itemCount={inventories.length}
            page={page}
            total={total}
            count={count}
            onChange={(p) => setPage(p)}
          />
        </div> */}
        <div className="flex flex-wrap gap-2 justify-between items-end">
          <div className="flex gap-2 text-sm">
            <button
              disabled={deleteDisabled}
              onClick={() => {
                const confirm = window.confirm(
                  "Are you sure to delete permanently ?"
                );
                if (confirm) {
                  onDelete(selected.map((s) => s.device_id));
                }
              }}
              className={`${
                deleteDisabled
                  ? "cursor-not-allowed  text-gray-300"
                  : "hover:bg-black hover:text-white"
              } flex gap-2 items-center border rounded-md px-3 py-1`}
            >
              <Icon path={mdiDeleteForever} className="h-4 w-4 "></Icon>Delete
            </button>
            <button
              disabled={updateDisabled}
              onClick={() => {
                setShowUpdate(true);
              }}
              className={`${
                updateDisabled
                  ? "cursor-not-allowed  text-gray-300"
                  : "hover:bg-black hover:text-white"
              } flex gap-2 items-center border rounded-md px-3 py-1`}
            >
              <Icon path={mdiNoteEdit} className="h-4 w-4 "></Icon>Update
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            <FilterDropDown
              selectedOptions={filter.status}
              placeholder="Status"
              onSelect={function (val: any): void {
                setFilter((old) => ({
                  ...old,
                  status: [...(old.status ?? []), val],
                }));
              }}
              onClearAll={function (): void {
                setFilter((old) => ({ ...old, status: undefined }));
              }}
              onDelete={function (val: any): void {
                setFilter((old) => ({
                  ...old,
                  status: old.status?.filter((v) => v !== val),
                }));
              }}
              options={Object.values(InventoryStatus).map((s) => ({
                label: s,
                value: s,
              }))}
            />
            <FilterDropDown
              selectedOptions={filter.product_id}
              placeholder="Product"
              onSelect={function (val: any): void {
                setFilter((old) => ({
                  ...old,
                  product_id: [...(old.product_id ?? []), val],
                }));
              }}
              onClearAll={function (): void {
                setFilter((old) => ({ ...old, product_id: undefined }));
              }}
              onDelete={function (val: any): void {
                setFilter((old) => ({
                  ...old,
                  product_id: old.product_id?.filter((v) => v !== val),
                }));
              }}
              options={products.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
            />
            <FilterDropDown
              selectedOptions={filter.warehouse_id}
              placeholder="Warehouse"
              onSelect={function (val: any): void {
                setFilter((old) => ({
                  ...old,
                  warehouse_id: [...(old.warehouse_id ?? []), val],
                }));
              }}
              onClearAll={function (): void {
                setFilter((old) => ({ ...old, warehouse_id: undefined }));
              }}
              onDelete={function (val: any): void {
                setFilter((old) => ({
                  ...old,
                  warehouse_id: old.warehouse_id?.filter((v) => v !== val),
                }));
              }}
              options={warehouses.map((w) => ({
                label: w.name,
                value: w.id,
              }))}
            />
            <div className="w-52 grid grid-cols-8  ">
              <div className="col-span-6">
                <EmployeeSearchWidget
                  withBtn={false}
                  emp={
                    filter.responsible_id && filter.responsible_name
                      ? {
                          uid: filter.responsible_id!,
                          name: filter.responsible_name!,
                        }
                      : undefined
                  }
                  onSelect={function (d: UserModel): void {
                    setFilter((o) => ({
                      ...o,
                      responsible_id: d.uid,
                      responsible_name: d.first_name + " " + d.last_name,
                    }));
                  }}
                />
              </div>
              <div className="col-span-2  text-xs flex justify-center items-center">
                <button
                  className={`${
                    (!filter.responsible_id || !filter.responsible_name) &&
                    "cursor-not-allowed text-gray-400"
                  }`}
                  disabled={!filter.responsible_id || !filter.responsible_name}
                  onClick={() =>
                    setFilter((o) => ({
                      ...o,
                      responsible_id: undefined,
                      responsible_name: undefined,
                    }))
                  }
                >
                  <Icon path={mdiClose} size={0.8} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selected.length > 0 &&
                        selected.length === inventories.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected([...inventories]);
                        } else {
                          setSelected([]);
                        }
                      }}
                    />
                  </div>
                </th>
                <th className="px-2 py-2"></th>
                <th className="px-2 py-2">Created on</th>
                <th className="px-2 py-2">Device ID</th>
                <th className="px-2 py-2">Org namespace</th>
                <th>Product</th>
                <th>Warehouse</th>

                {/* <th>City</th> */}
                {/* <th>State</th> */}
                {/* <th>Mobile</th> */}
                {/* <th>Sim No</th>
                <th>Sim ISMI</th> */}
                {/* <th>sim status</th>
                <th>Plan name</th> */}
                {/* <th>Module IMEI</th> */}
                {/* <th>Order </th> */}
                {/* <th>Res person </th> */}
                <th>Status</th>
                <th>Responsible</th>
                <th>Order Id</th>
                {/* <th> </th> */}
              </tr>
            </thead>
            <tbody className="">
              {inventories.length > 0 ? (
                inventories.map((d, i) => {
                  const allotment = d.allocations.find((od) =>
                    [
                      InventoryStatus.installed,
                      InventoryStatus.delivered,
                    ].includes(od.status)
                  );

                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={
                              selected.find(
                                (v) => v.device_id === d.device_id
                              ) !== undefined
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelected((old) => [...old, d]);
                              } else {
                                setSelected((old) =>
                                  old.filter(
                                    (inv) => inv.device_id !== d.device_id
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          {" "}
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowComment(d.id)}
                          >
                            <Icon path={mdiCommentBookmark} size="16"></Icon>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowHistory(d.id)}
                          >
                            <Icon path={mdiHistory} size="16"></Icon>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        {moment(d.created_at).format("DD MMM YYYY")}
                      </td>
                      <td
                        className="px-3 py-2  "
                        // onClick={() => setUpdateData(d)}
                      >
                        {d.device_id}
                      </td>
                      <td className="px-3 py-2">
                        {allotment?.org_namespace ?? "--"}
                      </td>
                      <td className="px-3 py-2">{d.device.product_name}</td>
                      <td className="px-3 py-2">
                        {d.warehouse?.name || "---"}
                      </td>

                      {/* <td className="px-3 py-2">{d.city}</td>
                    <td className="px-3 py-2">{d.state}</td> */}
                      {/* <td className="px-3 py-2">{d.mobile_number}</td> */}
                      {/* <td className="px-3 py-2">{d.sim_no}</td>
                    <td className="px-3 py-2">{d.sim_imsi}</td> */}
                      {/* <td className="px-3 py-2">{d.sim_status}</td> */}
                      {/* <td className="px-3 py-2">{d.plan_name}</td> */}
                      {/* <td className="px-3 py-2">{d.module_imei}</td> */}
                      {/* <td className="px-3 py-2">{d.order_id ?? "--"}</td>
                    <td className="  cursor-pointer">{d.emp_name ?? "--"}</td> */}
                      <td className="px-3 py-2">{d.status}</td>
                      <td className="px-3 py-2">
                        {d.responsible_name || "--"}
                      </td>
                      <td className="px-3 py-2">
                        {" "}
                        {allotment?.order_id ? (
                          <NavLink
                            className=" text-purple-500"
                            target="_blank"
                            to={"/orders/" + allotment?.order_id + "/devices"}
                          >
                            {allotment?.order_id}
                          </NavLink>
                        ) : (
                          <>N/A</>
                        )}
                      </td>
                      {/* <td className=" ">
                      <div className="flex justify-center gap-2">
                        {isAuthorised(
                          MODULE.INVENTORY,
                          [ACTION.UPDATE],
                          auth.auth
                        ) && (
                          <button
                            onClick={() => setUpdateData(d)}
                            className="hover:text-blue-400  "
                          >
                            <Icon
                              path={mdiPencilOutline}
                              className="h-4 w-4 "
                            ></Icon>
                          </button>
                        )}
                        {isAuthorised(
                          MODULE.INVENTORY,
                          [ACTION.DELETE],
                          auth.auth
                        ) &&
                          d.status.toLowerCase() !== "installed" && (
                            <button
                              onClick={() => {
                                const confirm = window.confirm(
                                  "Are you sure to delete permanently ?"
                                );
                                if (confirm) {
                                  onDelete(d.device_id);
                                }
                              }}
                              className=" hover:text-blue-400"
                            >
                              <Icon
                                path={mdiDeleteForever}
                                className="h-4 w-4 "
                              ></Icon>
                            </button>
                          )}
                      </div>
                    </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={inventories.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>

        <LoadingWidget loading={loading} />
      </div>
      {showAllot && (
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-white w-full h-full z-20">
          <DeviceAllocationScreen
            onClose={() => {
              setShowAllot(false);
              getInventories();
            }}
          />
        </div>
      )}
    </>
  );
};

export default InventoryListing;