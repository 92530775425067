import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { AttachmentModel } from "../../../../service/models/attachment_modal";
import { InvoiceModel } from "../../../../service/models/orders/invoice_model";
import { GetInvoiceApi } from "../../../../service/repos/invoice_service";
import { formToObject } from "../../../../utils/formDataToJson";
import TextField from "../../../components/form_components/text_field";
import LoadingWidget from "../../../components/loading_spinner";
import FileDropZone from "../../../new_components/common/file_dropdown";
import ModalDialog from "../../../new_components/common/modal";
import Pagination from "../../../new_components/common/pagination";
import StadiumButton from "../../../new_components/common/stadium_button";
import CreateInvoice from "./components/create_invoice";

interface InvoiceListingScreenProps {}

const InvoiceListingScreen: FunctionComponent<
  InvoiceListingScreenProps
> = () => {
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [search, setSearch] = useState("");

  const heads = ["Date", "Invoice #", "Ref #", "Ref type", "Action"];
  const getInvoices = async (search?: string) => {
    setLoading(true);
    const res = await GetInvoiceApi({ page, count, search });
    console.log(res);

    if (res.success) {
      setInvoices(res.data.invoices);
      if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);
    } else {
      setInvoices([]);
      setTotal(0);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (page !== 0) getInvoices();
  }, [page]);

  return (
    <>
      {/* {showCreateInvoice && (
          <ModalDialog
            show={showCreateInvoice}
            title="Upload Invoice"
            onClose={() => setShowCreateInvoice(false)}
          >
           
          </ModalDialog>
        )} */}
      {/* <div className="flex justify-end">
          <StadiumButton onTap={() => setShowCreateInvoice(true)}>
            Upload
          </StadiumButton>
        </div> */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-5 px-3 py-5 ">
        <div className="col-span-3" style={{ maxHeight: "70vh" }}>
          <div className="flex flex-wrap justify-between">
            <h3 className="text-lg font-semibold">Invoices</h3>
            <form
              id="searchForm"
              name="searchForm"
              className=" flex items-center rounded shadow my-1 justify-between "
              onSubmit={(e) => {
                e.preventDefault();

                setPage(1);

                getInvoices(search);

                // dispatch(FilterOrderThunk(data));
              }}
            >
              {/* <label htmlFor="search">Search</label> */}
              <input
                required
                id="search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className=" px-3 py-2 w-full  rounded focus:outline-none"
                type="text"
                placeholder="Search by order id "
              />
              {search && (
                <div className=" flex items-center  ">
                  <button
                    onClick={() => {
                      setSearch("");

                      getInvoices();
                    }}
                    form="searchForm"
                    type="reset"
                    className=" rounded-full hover:bg-gray-200  mx-1 p-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
              <div className="border-l-2 border-gray-300 flex items-center  ">
                <button
                  form="searchForm"
                  type="submit"
                  className=" rounded-full hover:bg-gray-200  mx-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 p-1  "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>{" "}
          </div>
          <div className="border flex justify-center h-full">
            <div className=" overflow-auto h-full ">
              <div className="grid grid-cols-5">
                {heads.map((h) => (
                  <div
                    key={h}
                    className="px-3 py-2 text-sm font-medium text-center text-black uppercase border-b"
                  >
                    {h}
                  </div>
                ))}

                {invoices.map((invoice) => {
                  return [
                    <div className=" px-3 py-2 whitespace-nowrap text-xs font-medium text-gray-900  border">
                      {moment(invoice.created_at).format("DD MMM YYYY hh:mm a")}
                    </div>,
                    <div className="text-center px-3 py-2 whitespace-nowrap text-xs font-medium  border">
                      {invoice.invoice_no ?? "---"}
                    </div>,
                    <div className="text-center px-3 py-2 whitespace-nowrap text-xs font-medium  border">
                      {invoice.ref_id}
                    </div>,
                    <div className="text-center px-3 py-2 whitespace-nowrap text-xs font-medium  border">
                      {invoice.ref_type}
                    </div>,
                    <div className="text-center  px-3 py-2 text-xs font-medium text-blue-300 cursor-pointer hover:text-red-600 border">
                      <a className="d-block" target="_blank" href={invoice.url}>
                        View
                      </a>
                    </div>,
                  ];
                })}
              </div>

              {invoices.length === 0 && (
                <div className="text-lg text-gray-400 font-semibold my-10 flex justify-center ">
                  <div className=""> No Invoice found !</div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <Pagination
              itemCount={invoices.length}
              page={page}
              total={total}
              count={count}
              onChange={(p) => {
                setPage(p);
              }}
            />
          </div>
        </div>
        <div className="hidden md:block lg:block col-span-2">
          <CreateInvoice
            onSuccess={(data) => {
              if (page === 1) setInvoices([data, ...invoices]);
            }}
          />
        </div>
      </div>
      <LoadingWidget loading={loading} />
    </>
  );
};

export default InvoiceListingScreen;
