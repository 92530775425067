import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Info, X } from "react-feather";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  defaultEmpValue,
  UserModel,
} from "../../../../../context_providers/auth/user_model";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  GetReportingManager,
  UpdateEmpDetails,
} from "../../../../../service/repos/users_repo";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import {
  ACTION,
  Departments,
  EmpType,
  MODULE,
  SubDepartments,
} from "../../../../../utils/enums";
import HrmPopup from "../../components/HrmPopup";
import HrmInput from "../../components/Input";
import HrmLoader from "../../components/loading";
import HrmSelect from "../../components/Select";

interface DetailsProps {
  userData: UserModel;
}

const Details: FunctionComponent<DetailsProps> = ({ userData }) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserModel>(
    userData ?? defaultEmpValue
  );
  const { isAuthorised } = useAuth();
  const { showToast } = useToast();
  const [lineManager, setLineManager] = useState<
    {
      id: any;
      name: string;
      dep: string;
      sub_dep: string;
    }[]
  >([]);
  const tempDate = formData?.emp_profile?.doj;

  const getLineManager = async () => {
    const res = await GetReportingManager();
    setLineManager(res.data ?? []);
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    if (name.includes(".")) {
      const fields = name.split(".");

      if (fields.length === 3) {
        setFormData((prev: any) => {
          const tempFormData = prev;
          tempFormData[fields[0]][fields[1]][fields[2]] = value;
          return { ...tempFormData };
        });
      } else if (fields.length === 2) {
        setFormData((prev: any) => {
          const tempFormData = prev;
          tempFormData[fields[0]][fields[1]] = value;
          return { ...tempFormData };
        });
      }
    } else setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const finalData: UserModel = {
      ...formData,
    };

    if (finalData.emp_profile?.emp_type === EmpType.Intern) {
      finalData.emp_profile.date_of_earned_leave_start = null;
    }

    const res = await UpdateEmpDetails(finalData);

    if (res.success) {
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else showToast({ type: ToastType.error, text: res.error ?? "" });

    setLoading(false);
  };

  useEffect(() => {
    getLineManager();
  }, []);

  useEffect(() => {
    if (
      isAuthorised({
        module: MODULE.hrm_leave,
        action: [ACTION.UPDATE],
      })
    ) {
      setShowEdit(true);
    }
  }, []);

  return (
    <div className="relative">
      <div className="absolute top-3 right-3">
        {showEdit &&
          (disabled ? (
            <button
              className="py-2 px-4 rounded-md text-white font-semibold bg-hrmBlue"
              onClick={() => setDisabled(false)}
            >
              Edit details
            </button>
          ) : (
            <button
              className="py-2 px-4 rounded-md text-white font-semibold bg-hrmRed"
              onClick={() => setDisabled(true)}
            >
              Cancel
            </button>
          ))}
      </div>
      <div className="pt-12 lg:pt-32">
        <form action="" onSubmit={submit}>
          {!disabled && (
            <div className="">
              <input
                type="radio"
                id="active"
                name="status"
                value="true"
                checked={formData.emp_profile?.active}
                onChange={(e) => {
                  const val = Boolean(e.target.value);
                  setFormData((o) => ({
                    ...o,
                    emp_profile: {
                      ...o.emp_profile!,
                      active: val,
                    },
                  }));
                }}
              />
              <label htmlFor="active" className="font-semibold text-sm ml-2">
                Active
              </label>
              {formData.emp_profile?.dol ? (
                <>
                  <input
                    type="radio"
                    id="inactive"
                    name="inactive"
                    value="false"
                    checked={!formData.emp_profile?.active}
                    onChange={(e) => {
                      const val = !Boolean(e.target.value);
                      setFormData((o) => ({
                        ...o,
                        emp_profile: {
                          ...o.emp_profile!,
                          active: val,
                        },
                      }));
                    }}
                    className="ml-4"
                  />{" "}
                  <label
                    htmlFor="inactive"
                    className="font-semibold text-sm ml-2"
                  >
                    Inactive
                  </label>
                </>
              ) : (
                <div className="text-sm text-gray-500 font-semibold">
                  *To set employee to inactive please set a date of leaving
                </div>
              )}
            </div>
          )}
          <div
            className={`${
              disabled ? "grid  lg:grid-cols-3" : "grid  lg:grid-cols-3"
            } gap-8 lg:gap-12 mt-6`}
          >
            <HrmInput
              type="text"
              name="first_name"
              label="First name"
              defaultValue={formData?.first_name}
              disabled={disabled}
              required={true}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="last_name"
              label="Last name"
              defaultValue={formData?.last_name}
              disabled={disabled}
              required={true}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="mobile"
              label="Mobile"
              defaultValue={formData?.mobile}
              disabled={disabled}
              required={true}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="email"
              label="Email"
              defaultValue={formData?.email}
              disabled={disabled}
              required={true}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="emp_profile.designation"
              label="Designation"
              defaultValue={formData?.emp_profile?.designation}
              disabled={disabled}
              required={true}
              onChange={handleChange}
            />

            <HrmSelect
              name="emp_profile.department"
              label="Department"
              defaultValue={formData.emp_profile?.department}
              disabled={disabled}
              required={true}
              options={Object.values(Departments)}
              onChange={handleChange}
            />

            <HrmSelect
              name="emp_profile.sub_dep"
              label="Sub department"
              defaultValue={formData.emp_profile?.sub_dep}
              disabled={disabled}
              required={true}
              options={
                formData.emp_profile?.department
                  ? SubDepartments[formData.emp_profile?.department]
                  : []
              }
              onChange={handleChange}
            />
            {/* Line manager */}
            <div className="">
              <div className="">
                <div className={`text-sm text-gray-400 font-semibold  `}>
                  Line manager
                </div>
                <select
                  name="line_manager"
                  disabled={disabled}
                  className={` w-full ${
                    disabled ? "bg-transparent" : "py-3 px-4 bg-white shadow-md"
                  }  focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer`}
                  onChange={(e) => {
                    const data = lineManager.find(
                      (d) => d.id === e.target.value
                    );

                    setFormData((o) => ({
                      ...o,
                      emp_profile: {
                        ...o.emp_profile!,
                        reporting_to_id: data?.id,
                        reporting_to_name: data?.name,
                      },
                    }));
                  }}
                >
                  <option
                    value={formData?.emp_profile?.reporting_to_name}
                    hidden
                  >
                    {formData?.emp_profile?.reporting_to_name}
                  </option>
                  {lineManager.map((d, i) => (
                    <option value={d.id} className="text-black" key={i}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>{" "}
              {!disabled && (
                <div className="mt-2 flex items-center">
                  <label htmlFor="reporting">Is also a line manager?</label>

                  <input
                    type="checkbox"
                    className="ml-3"
                    id="reporting"
                    name="line_manager"
                    value=""
                    disabled={disabled}
                    checked={formData.emp_profile?.isReportingManager}
                    onChange={(e) =>
                      setFormData((o) => ({
                        ...o,
                        emp_profile: {
                          ...o.emp_profile!,
                          isReportingManager: e.target.checked,
                        },
                      }))
                    }
                  />
                </div>
              )}
            </div>

            <HrmSelect
              name="emp_profile.zone"
              label="Zone"
              defaultValue={formData.emp_profile?.zone}
              disabled={disabled}
              required={false}
              options={[
                "North zone",
                "South zone",
                "West zone",
                "East zone",
                "Central zone",
                "All",
              ]}
              onChange={handleChange}
            />
            <div className="">
              <div className={`text-sm text-gray-400 font-semibold  `}>
                Date of joining
              </div>
              <input
                type="date"
                disabled={disabled}
                className={` ${
                  disabled ? "bg-transparent" : "py-3 px-4 bg-white shadow-md"
                }  w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1`}
                value={
                  formData?.emp_profile?.doj
                    ? moment(formData.emp_profile?.doj).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value)!;
                  setFormData((o) => ({
                    ...o,
                    emp_profile: {
                      ...o.emp_profile!,
                      doj: date,
                    },
                  }));
                }}
              />
            </div>

            <div className="">
              <div className={`text-sm text-gray-400 font-semibold  `}>
                Date of leaving
              </div>
              <input
                type="date"
                disabled={disabled}
                className={` ${
                  disabled ? "bg-transparent" : "py-3 px-4 bg-white shadow-md"
                }  w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1`}
                value={
                  formData?.emp_profile?.dol
                    ? moment(formData.emp_profile?.dol).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value)!;
                  setFormData((o) => ({
                    ...o,
                    emp_profile: {
                      ...o.emp_profile!,
                      dol: date,
                    },
                  }));
                }}
              />
            </div>

            <div className="">
              <div className={`text-sm text-gray-400 font-semibold  `}>
                Date of birth
              </div>
              <input
                type="date"
                disabled={disabled}
                className={` ${
                  disabled ? "bg-transparent" : "py-3 px-4 bg-white shadow-md"
                }  w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1`}
                value={
                  formData.dob ? moment(formData.dob).format("YYYY-MM-DD") : ""
                }
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value)!;
                  setFormData((o) => ({
                    ...o,
                    dob: date,
                  }));
                }}
              />
            </div>
            <HrmInput
              type="text"
              name="emp_profile.emp_id"
              label="Employee ID"
              defaultValue={formData.emp_profile?.emp_id}
              disabled={disabled}
              required={false}
              onChange={handleChange}
            />
            <HrmSelect
              name="emp_profile.emp_type"
              label="Employee type"
              defaultValue={formData.emp_profile?.emp_type}
              disabled={disabled}
              required={false}
              options={Object.values(EmpType)}
              onChange={handleChange}
            />

            {formData.emp_profile?.emp_type === EmpType.Fulltime && (
              <div className="">
                <div className={`text-sm text-gray-400 font-semibold  `}>
                  Date of Earned leave start
                  <Info
                    className="inline ml-2 cursor-pointer"
                    size={16}
                    onClick={() => setShowInfo(true)}
                  />
                </div>
                <input
                  type="date"
                  disabled={disabled}
                  className={` ${
                    disabled ? "bg-transparent" : "py-3 px-4 bg-white shadow-md"
                  }  w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1`}
                  value={
                    formData?.emp_profile?.date_of_earned_leave_start
                      ? moment(
                          formData.emp_profile?.date_of_earned_leave_start
                        ).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    date.setHours(0, 0, 0, 0);
                    setFormData((o) => ({
                      ...o,
                      emp_profile: {
                        ...o.emp_profile!,
                        date_of_earned_leave_start: date,
                      },
                    }));
                  }}
                />
              </div>
            )}

            <HrmInput
              type="text"
              name="emp_profile.emergency_contact"
              label="Emergency contact"
              defaultValue={formData.emp_profile?.emergency_contact}
              disabled={disabled}
              required={false}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="emp_profile.blood_group"
              label="Blood group"
              defaultValue={formData.emp_profile?.blood_group}
              disabled={disabled}
              required={false}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="emp_profile.permanent_address"
              label="Permanent address"
              defaultValue={formData.emp_profile?.permanent_address}
              disabled={disabled}
              required={false}
              onChange={handleChange}
            />

            <HrmSelect
              name="emp_profile.education"
              label="Education"
              defaultValue={formData.emp_profile?.education}
              disabled={disabled}
              required={false}
              options={["Under graduate", "Post graduate"]}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="emp_profile.education_degree_name"
              label="Education degree name"
              defaultValue={formData?.emp_profile?.education_degree_name}
              disabled={disabled}
              required={false}
              onChange={handleChange}
            />
            <HrmInput
              type="text"
              name="emp_profile.linked_in"
              label="Linkedin URL"
              defaultValue={formData?.emp_profile?.linked_in}
              disabled={disabled}
              required={false}
              onChange={handleChange}
            />
          </div>
          {showEdit && !disabled && (
            <div className="mt-8 flex flex-col items-center">
              {loading ? (
                <HrmLoader className="w-12 h-12" />
              ) : (
                <button className="py-3 px-8 rounded-md text-white bg-hrmBlue font-semibold">
                  Save changes
                </button>
              )}
            </div>
          )}
        </form>
      </div>
      {showInfo && (
        <HrmPopup>
          <div className="py-12 px-6 bg-white w-1/2 rounded-lg relative">
            <X
              className="cursor-pointer absolute top-2 right-2"
              onClick={() => setShowInfo(false)}
            />
            <div className="text-xl font-bold">
              Date of earned leave start rules::
            </div>
            <ul className="pl-3 list-disc mt-6">
              <li>
                If an employee is hired as a full-time worker from the
                beginning, their date of earned leave start should be{" "}
                <b> same </b> as date of joining
              </li>
              <br />
              <li>
                If an employee's status changes from intern to full-time, their
                date of joining should be updated to reflect the date they began
                as a full-time worker.
              </li>
            </ul>
          </div>
        </HrmPopup>
      )}
    </div>
  );
};

export default Details;
