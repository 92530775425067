import { DiscountDataModel } from "../service/models/orders/order_discount_model";
import OrderItemModel from "../service/models/orders/order_item_model";
import { DiscountType, TransactionStatus, TransactionType } from "./enums";

export const GetOrderItemTotalAmount = (items: OrderItemModel[]) => {
  return items.reduce((pv: number, item) => pv + item.qty * item.price, 0);
};

export const GetOrderAmount = (items: OrderItemModel[]) => {
  let total = 0;
  items.forEach((item: OrderItemModel) => {
    total = total + item.qty * item.price;
  });
  return total;
};

export const GetOrderDiscount = (discounts: any) => {
  let total = 0;
  discounts.forEach((discount: any) => {
    total = total + discount.amount;
  });
  return total;
};
export const CheckDiscountApplied = (
  type: string,
  discounts: DiscountDataModel[]
) => {
  let flag = false;
  discounts.forEach((discount: DiscountDataModel) => {
    if (discount.type === type) {
      flag = true;
    }
  });
  return flag;
};

export const GetOrderExcess = (excess: any) => {
  let total = 0;
  excess.forEach((exce: any) => {
    total = total + exce.amount;
  });
  return total;
};

export const GetOrderTransactionAmount = (transactions: any) => {
  let total = 0;

  transactions.forEach((tra: any) => {
    if (
      tra.type === TransactionType.PAYMENT &&
      tra.status === TransactionStatus.SUCCESS
    )
      total = total + tra.amount;
    if (
      tra.type === TransactionType.REFUND &&
      tra.status === TransactionStatus.SUCCESS
    )
      total = total - tra.amount;
  });
  return total;
};

export const currencyFormat = (rs: number, currency = "INR") => {
  if (!rs) rs = 0;
  if (!isNaN(rs)) rs = Number(rs);
  return rs.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: "currency",
    currency,
  });
};

const isValidCurrencyCode = (code: string) => {
  const validCurrencies = ["USD", "EUR", "INR", "GBP", "JPY", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD"]; // Extend as needed
  return validCurrencies.includes(code);
};

// export const currencyFormat = (rs: number, currency = "INR") => {
//   if (!rs) rs = 0;
//   if (!isNaN(rs)) rs = Number(rs);
//   const validatedCurrency = isValidCurrencyCode(currency) ? currency : "INR";
//   return rs.toLocaleString("en-IN", {
//     maximumFractionDigits: 2,
//     minimumFractionDigits: 0,
//     style: "currency",
//     currency: validatedCurrency,
//   });
// };

