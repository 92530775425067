import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useRef, useState } from "react";
import "./index.css";
interface AutoCompleteFieldProps<T> {
  onSelect?: (data: { label: any; value: T }) => void;
  suggestions?: { label: any; value: T }[];
  onChange?: (data: string) => void;
  value?: string;
  placeholder?: string;
  loading: boolean;
  disabled?: boolean;
  onAddNew?: () => void;
  defaultShow?: boolean;
}

const AutoCompleteField = <T extends { id?: string }>({
  placeholder,
  value,
  onChange,
  suggestions,
  onSelect,
  loading,
  disabled = false,
  onAddNew,
  defaultShow = false,
}: AutoCompleteFieldProps<T>): JSX.Element => {
  // const AutoCompleteField: FunctionComponent<AutoCompleteFieldProps> = ({
  //   placeholder,
  //   value,
  //   onChange,
  //   suggestions,
  //   onSelect,
  //   loading,
  //   disabled = false,
  //   onAddNew,
  // }) => {
  const [show, setShow] = useState(
    defaultShow === undefined ? true : defaultShow
  );
  // const [mySuggestion, setMySuggestion] = useState<
  //   { label: any; value: T }[] | undefined
  // >();
  const ref = useRef<any>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  useEffect(() => {
    if (suggestions && suggestions.length) {
      setShow(true);
    }
  }, [suggestions]);

  // let suggestionsListComponent;
  // // if (loading) {
  // //   suggestionsListComponent = (
  // //     <div className="flex flex-col   border rounded py-2 absolute bg-white z-10 w-full max-h-72 overflow-y-auto overflow-x-hidden">
  // //       <div className="px-2 text-xs">Loading....</div>
  // //     </div>
  // //   );
  // // } else {
  // if (suggestions && show) {
  //   // if (suggestions.length) {
  //   suggestionsListComponent = (
  //     <div className="flex flex-col   border rounded py-2 absolute bg-white z-40 w-full max-h-72 overflow-y-auto overflow-x-hidden divide-y">
  //       {suggestions.map((suggestion, index) => {
  //         return (
  //           <div
  //             className="  text-xs hover:bg-gray-100   px-2 py-1 cursor-pointer"
  //             key={index}
  //             onClick={() => {
  //               if (onSelect) onSelect(suggestion);
  //             }}
  //           >
  //             {suggestion.label}
  //           </div>
  //         );
  //       })}
  //       {loading && (
  //         <div className="flex  text-xs hover:bg-gray-100   px-2 py-1 cursor-pointer">
  //           <p className="text-gray-300"> Loading ...</p>
  //         </div>
  //       )}

  //       {onAddNew && (
  //         <div
  //           onClick={onAddNew}
  //           className="flex  justify-between text-xs hover:bg-gray-100   px-2 py-1 cursor-pointer"
  //         >
  //           <p className="text-red-300"> Not Found ?</p>
  //           <div className="flex items-center gap-1 text-myPrimaryColor">
  //             <Icon path={mdiPlus} className="w-5 h-5"></Icon>
  //             <p>Add new</p>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  //   // } else {
  //   // suggestionsListComponent = (
  //   //   <div className="flex flex-col   border rounded py-2 absolute bg-white z-10 w-full max-h-72 overflow-y-auto overflow-x-hidden">
  //   //     <div className="text-xs px-2">No suggestions available.</div>
  //   //   </div>
  //   // );
  //   // }
  //   // }
  // }

  return (
    <div ref={ref} className="relative w-full  ">
      <input
        autoFocus
        disabled={disabled}
        autoComplete="off"
        id=""
        name="autocomplete"
        value={value}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full     border"
        type="text"
        placeholder={placeholder ?? "Search"}
      />
      {show && suggestions && (
        <div className="flex flex-col    border rounded  absolute bg-white z-40 w-full  ">
          <div className="flex flex-col        w-full max-h-72 overflow-y-auto overflow-x-hidden divide-y">
            {suggestions.map((suggestion, index) => {
              return (
                <div
                  className="  text-xs hover:bg-gray-100   px-2 py-1 cursor-pointer"
                  key={index}
                  onClick={() => {
                    if (onSelect) onSelect(suggestion);
                  }}
                >
                  {suggestion.label}
                </div>
              );
            })}
          </div>
          {loading && (
            <div className="flex  text-xs hover:bg-gray-100   px-2 py-1 cursor-pointer">
              <p className="text-gray-300"> Loading ...</p>
            </div>
          )}

          {onAddNew && (
            <div
              onClick={onAddNew}
              className="flex  justify-between text-xs hover:bg-gray-100   p-2  cursor-pointer  "
            >
              <p className="text-red-300"> Not Found ?</p>
              <div className="flex items-center gap-1 text-myPrimaryColor">
                <Icon path={mdiPlus} className="w-5 h-5"></Icon>
                <p>Add new</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutoCompleteField;
