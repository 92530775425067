import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingWidget from "../../../../context_providers/modal/loader";
import { useModal } from "../../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import TableView from "../../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { currencyFormat } from "../../../../utils/orders_utils";
import { urls } from "../../../../utils/urls";
import { accountTxnType } from "../../models/common_model";
import { LedgerModel } from "../../models/ledger_model";
import { SalesVoucherModel } from "../../models/sale_model ";

interface LedgerSummeryListScreenProps {}

const LedgerSummeryListScreen: FunctionComponent<
  LedgerSummeryListScreenProps
> = () => {
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/ledgers/summery`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<
    { debit: number; credit: number; ledger: LedgerModel; id: any }[]
  >([]);
  const [search, setSearch] = useState("");
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString({ ...prop, type }));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ page, count, search });
  }, [page, type]);
  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  // return <LedgerCreateScreen />;

  const onSelect = (d: {
    debit: number;
    credit: number;
    ledger: LedgerModel;
    id: any;
  }) => {
    navaigation.push(urls.account_ledger_entries + "/" + d.ledger.id);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex flex-wrap justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            <p className="border-l-4 border-myPrimaryColor pl-2">
              Ledger summary
            </p>
            <button
              title="Refersh"
              onClick={() => fetchData({ page, count, search })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex text-sm items-center gap-1 ">
            <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            />

            <select
              title="Debitor/Creditor"
              name=""
              id=""
              className="  focus:outline-none focus:ring-1 ring-blue-400 border border-gray-300 rounded px-3 py-1 cursor-pointer mt-1 text-sm font-semibold"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {Object.values(accountTxnType).map((el, i) => {
                return <option value={el}>{el}</option>;
              })}
              <option value={""}>All</option>
            </select>

            {/* <div
              onClick={() => {
                showModal({
                  title: `Add Ledger`,
                  type: ModalType.modal,
                  container(id) {
                    return (
                      <LedgerCreateScreen
                        onClose={(d) => {
                          if (d)
                            setData((o) => {
                              o.pop();
                              return [d, ...o];
                            });
                          setTotal((o) => o + 1);
                          onClose(id);
                        }}
                      />
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              colspan: 4,
              key: "party",
              title: "Particulars",
              type: FieldType.long_string,
            },

            {
              key: "debit",
              title: "Debit",
              type: FieldType.currency,
            },
            {
              key: "credit",
              title: "Credit",
              type: FieldType.currency,
            },
            {
              key: "credit",
              title: "Closing Balance",
              type: FieldType.currency,
            },
          ]}
          rows={data.map((data, i) => {
            let bal = data.credit - data.debit;
            let type = "";
            if (bal >= 0) {
              type = accountTxnType.creditor;
            } else {
              type = accountTxnType.debitor;
              bal = bal * -1;
            }

            return {
              data: data,
              values: [
                data.ledger?.name,
                currencyFormat(data.debit),
                currencyFormat(data.credit),
                `${currencyFormat(bal)} ${type}`,
              ],
            };
          })}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default LedgerSummeryListScreen;
