import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { debounce } from "lodash";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { getItemsRepo } from "../repository/items_repository";
import { ItemFilterData, ItemModelV2 } from "../../models/item_model";
import AutoCompleteField from "../../../../ui/new_components/common/autocomplete";
import ItemDescriptionAutoCompleteField from "./item_description_autocomplete";

const defaultFilter: ItemFilterData = {
  page: 1,
  count: 10,
};

interface ItemNameSearchWidgetProps {
  type?: string;
  value?: string;
  onSelect: (data: any) => void;
  placeholder?: string;
  emp?: { uid: string; name: string };
  disabled?: boolean;
  withBtn?: boolean;
}

// const ItemNameSearchWidget: FunctionComponent<ItemNameSearchWidgetProps> = ({
//   type,
//   value,
//   onSelect,
//   placeholder,
//   disabled,
// }) => {
//   const { showToast } = useToast();
//   const [edit, setEdit] = useState(false);
//   const [filter, setFilter] = useState<ItemFilterData>(defaultFilter);
//   const [items, setItems] = useState<ItemModelV2[]>([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     if (filter && edit) {
//       debouncedHandler(filter);
//     }
//   }, [filter]);

//   useEffect(() => {
//     console.log("Edit state changed in Name:", edit); // Log edit variable whenever it changes
//   }, [edit]);

//   const searchItems = async (search: string) => {
//     setLoading(true);
//     filter.type = type;
//     const res = await getItemsRepo(filter);
//     if (res.success) {
//       setItems(res.data.data);
//     } else {
//       setItems([]);
//       showToast({ type: ToastType.error, text: res.error ?? "" });
//     }
//     setLoading(false);
//   };
//   const debouncedHandler = useCallback(
//     debounce((query) => {
//       if (query) searchItems(query);
//     }, 500),
//     [filter]
//   );
//   return (
//     <div className="flex gap-1 items-center w-full">
//       {edit ? (
//         <AutoCompleteField
//           loading={loading}
//           value={value}
//           placeholder={placeholder ?? "Items"}
//           onSelect={(val) => {
//             console.log("AutoCompleteField Component", val.value);
//             setEdit(false);

//             // onSelect(val.value);
//           }}
//           suggestions={items.map((e) => ({
//             label: (
//               <div className="text-xs">
//                 <b>{e.name}</b>
//               </div>
//             ),

//             value: e,
//           }))}
//           onChange={(str) => {
//             setFilter((o) => ({
//               ...o,
//               item_name: str,
//             }));
//             onSelect(str);
//           }}
//         />
//       ) : (
//         <div
//           className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full border"
//           onClick={() => {
//             if (!disabled) {
//               setEdit(true);
//               console.log("Edit in Name ", disabled);
//             }
//           }}
//         >
//           {value || placeholder}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ItemNameSearchWidget;

const ItemNameSearchWidget: FunctionComponent<ItemNameSearchWidgetProps> = ({
  type,
  value,
  onSelect,
  placeholder,
  disabled,
}) => {
  const { showToast } = useToast();
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState<ItemFilterData>(defaultFilter);
  const [items, setItems] = useState<ItemModelV2[]>([]);
  const [loading, setLoading] = useState(false);
  const widgetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      widgetRef.current &&
      !widgetRef.current.contains(event.target as Node)
    ) {
      setEdit(false); // Close the autocomplete when clicking outside
    }
  };

  useEffect(() => {
    if (edit) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [edit]);

  useEffect(() => {
    console.log("Edit state changed in Name:", edit); // Log edit variable whenever it changes
  }, [edit]);

  const searchItems = async (search: string) => {
    setLoading(true);
    filter.type = type;
    const res = await getItemsRepo(filter);
    if (res.success) {
      setItems(res.data.data);
    } else {
      setItems([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) searchItems(query);
    }, 500),
    [filter]
  );

  useEffect(() => {
    if (filter && edit) {
      debouncedHandler(filter);
    }
  }, [filter]);

  return (
    <div className="flex gap-1 items-center w-full" ref={widgetRef}>
      {edit ? (
        <AutoCompleteField
          loading={loading}
          value={value}
          placeholder={placeholder ?? "Items"}
          onSelect={(val) => {
            setEdit(false);
            // onSelect(val.value);
          }}
          suggestions={items.map((e) => ({
            label: (
              <div className="text-xs">
                <b>{e.name}</b>
              </div>
            ),
            value: e,
          }))}
          onChange={(str) => {
            setFilter((o) => ({
              ...o,
              item_name: str,
            }));
            onSelect(str);
          }}
        />
      ) : (
        <div
          className="text-sm px-3 py-2 h-8 rounded focus:outline-none w-full border"
          onClick={() => {
            if (!disabled) {
              setEdit(true);
            }
          }}
        >
          {value || placeholder}
        </div>
      )}
    </div>
  );
};

export default ItemNameSearchWidget;
