import { mdiRefresh, mdiPlus, mdiExport } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ModalType,
  useModal,
} from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  getHeaders,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { urls } from "../../../utils/urls";
import { PartyModel } from "../models/party_model";

interface PartyListScreenProps {}

const columnsMap: { [k: string]: any } = {
  name: "Name",
  type: "Type",
  contact_name: "Contact Name",
  contact_mobile: "Contact Mobile",
  contact_email: "Contact Email",
  address_type: "Address Type",
  city: "City",
  state: "State",
  country: "Country",
  zip_code: "ZIP Code",
  full_address: "Full Address",
  bank_ac_no: "Bank A/C Number",
  bank_ifsc: "Bank IFSC",
  bank_name: "Bank Name",
  bank_branch: "Bank Branch",
  pan_no: "PAN Number",
  reg_type: "Registration Type",
  gst_no: "GST Number",
  attachments: "Attachments",
};

const PartyListScreen: FunctionComponent<PartyListScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const { showModal } = useModal();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PartyModel[]>([]);
  const [search, setSearch] = useState("");

  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const exportData = async () => {
    setLoading(true);
    try {
      const res = await fetch(url + "/export", {
        method: "post",
        body: JSON.stringify({ fields: exportFields }),
        headers: { ...getHeaders(), "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!res.ok) {
        return showToast({ type: ToastType.error, text: res.statusText });
      }

      const header = res.headers.get("Content-Disposition");
      const parts = header?.split(";");
      let downloadFilename = "";
      if (parts) {
        downloadFilename = parts[1].split("=")[1];
        downloadFilename = downloadFilename.substring(
          1,
          downloadFilename.length - 1
        );
      }
      const blob = await res.blob();

      const fileUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", downloadFilename ?? `export.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };

  let exportFields: string[] = [];

  useEffect(() => {
    fetchData({ page, count, search });
  }, [page]);
  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  // return (
  //   <section className="flex justify-center h-screen w-full">
  //     <div className="h-max m-auto">
  //       <PartyCreateScreen />
  //     </div>
  //   </section>
  // );
  const navaigation = useHistory();
  const onSelect = (d: PartyModel) => {
    navaigation.push(urls.procurement_masters_party + "/update/" + d.id);
  };
  const onDelete = async (d: PartyModel) => {
    const propmt = window.prompt("Type 'delete' to confirm.");
    if (!propmt || propmt?.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({ page, count, search });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex flex-wrap justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500border-l-4 border-myPrimaryColor pl-2 flex items-center gap-3">
            Parties
            <button
              title="Refersh"
              onClick={() => fetchData({ page, count, search })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            />
            <div
              onClick={() => {
                navaigation.push(urls.procurement_masters_party + "/create");

                // showModal({
                //   title: `Add Party`,
                //   type: ModalType.modal,
                //   container(id) {
                //     return (
                //       <section className="p-5">
                //         <PartyCreateScreen
                //           onClose={function (d?: PartyModel | undefined): void {
                //             if (d)
                //               setData((o) => {
                //                 o.pop();
                //                 return [d, ...o];
                //               });
                //             setTotal((o) => o + 1);
                //             onClose(id);
                //           }}
                //         />
                //       </section>

                //     );
                //   },
                // });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div>

            <div
              onClick={() => {
                showModal({
                  title: "Select Fields for Export",
                  type: ModalType.modal,
                  container: () => {
                    return (
                      <div>
                        <div className="flex flex-col justify-center ml-1 mr-10">
                          {Object.keys(columnsMap).map((c) => {
                            return (
                              <div className="flex" key={c}>
                                <input
                                  type="checkbox"
                                  id={c}
                                  className="mx-2"
                                  onChange={() => {
                                    exportFields.push(c);
                                  }}
                                />
                                <label htmlFor={c}>{columnsMap[c]}</label>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          onClick={() => {
                            exportData();
                          }}
                          className="flex mx-auto mt-4 justify-center w-1/2 gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                        >
                          <Icon path={mdiExport} className="h-4 w-4"></Icon>
                          Export
                        </div>
                      </div>
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiExport} className="h-4 w-4"></Icon>Export
            </div>
          </div>
        </section>

        <TableView
          headers={[
            {
              key: "name",
              title: "Name",
              type: FieldType.string,
              onClick: () => {},
            },
            {
              key: "type",
              title: "Type",
              type: FieldType.string,
            },
            {
              key: "contact_name",
              title: "Contact person",
              type: FieldType.string,
            },
            {
              key: "contact_mobile",
              title: "Mobile",
              type: FieldType.string,
            },
            {
              key: "contact_email",
              title: "Email",
              type: FieldType.string,
            },
            {
              key: "party.mail_detail.address.city",
              title: "City",
              type: FieldType.string,
            },
            {
              key: "party.mail_detail.address.state",
              title: "State",
              type: FieldType.string,
            },
          ]}
          rows={data.map((party) => ({
            data: party,
            values: [
              party.name,
              party.type,
              party.mail_detail.contact_name,
              party.mail_detail.contact_mobile,
              party.mail_detail.contact_email,
              party.mail_detail.address.city,
              party.mail_detail.address.state,
            ],
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          onEdit={onSelect}
          onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PartyListScreen;
