import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import Pagination from "../../../../ui/new_components/common/pagination";
import LoadingWidget from "../../../../ui/components/loading_spinner";
import { TransferLogStatus } from "../../../../utils/enums";
import { PurchaseOrderModel } from "../../model/purchase_order_model";

export const GetTransferLogsApi = async (data: {
  log_type?: string;
  po_no: string;
  data: any;
  page?: number;
  count?: number;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/erp/purchase-order/${data.po_no}/logs${toQueryString(data)}`;
    const res = await fetchGet(url);
    // console.log("Result of log:", res)
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export interface TransferLogModel {
  id?: string;
  operation?: String;
  user_id: any;
  po_no: string;
  created_at?: number;
  user: string;
  log_type?: string;
  params?: any;
}
interface ActionHistoryProps {
  data: PurchaseOrderModel;
  po_no: string;
  log_type?: string;
}

const ActionHistory: FunctionComponent<ActionHistoryProps> = (props) => {
  const [logs, setLogs] = useState<TransferLogModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count] = useState(10);
  const [total, setTotal] = useState(0);
  // console.log("logs data", logs);
  useEffect(() => {
    GetTransferLogsApi({
      po_no: props.po_no,
      data: props.data,
      page,
      count,
      log_type: props.log_type,
    })
      .then((res) => {
        if (res.success) {
          setLogs(res.data.logs);

          if (res.data.metadata[0]) {
            setTotal(res.data.metadata[0].total);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    scrollBottom();
  }, [logs]);
  const scrollBottom = () => {
    const container = document.getElementById("history_wrap");
    if (container) container.scrollTop = container.scrollHeight;
  };
  return (
    <div className=" ">
      <div className="flex flex-col justify-between h-full">
        <div
          id="history_wrap"
          className="overflow-auto p-1 bg-gray-100"
          style={{ height: "88vh" }}
        >
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div key={i} className="  mb-2 shadow rounded bg-white p-1">
              {log.log_type ? (
                getTransferLogTemplate(log)
              ) : (
                <div>
                  <b>{log.user}</b>
                  <p className="text-sm"> {log.operation}</p>
                  <div className="flex justify-end items-end py-2">
                    <span className="text-xs italic">
                      {moment(log.created_at).format("lll")}
                    </span>
                  </div>
                  {/* <hr className="my-2" /> */}
                </div>
              )}
            </div>
          ))}
        </div>
        <Pagination
          page={page}
          total={total}
          count={count}
          itemCount={logs.length}
          onChange={(page: number) => setPage(page)}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ActionHistory;

const getTransferLogTemplate = (log: TransferLogModel) => {
  // console.log("log:", log);
  switch (log.log_type) {
    case TransferLogStatus.created:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has created the PO_NO #{" "}
            <b className="text-blue-500">{log.po_no}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.rejected:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had <b className="text-red-500">Rejected</b> the PO_NO #{" "}
            <b className="text-blue-500">{log.po_no}</b> with the reason{" "}
            <b className="text-red-500">"{log.params?.reason}"</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.attachment_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> <b className="text-blue-500">uploaded</b> an
            attachmnet to PO_NO # <b className="text-blue-500">{log.po_no}</b>{" "}
            under{" "}
            <b className="text-blue-500">"{log.params[0]?.category}"</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.attachment_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had <b className="text-red-500">deleted</b> an
            attachment to PO_NO # <b className="text-blue-500">{log.po_no}</b>{" "}
            with category{" "}
            <b className="text-blue-500">"{log.params[0]?.category}"</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.level1_approval:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had <b className="text-green-500">approved</b> the {log.log_type} of PO_NO #{" "}
            <b className="text-blue-500">{log.po_no}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.level2_approval:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had <b className="text-green-500">approved</b> the {log.log_type} of PO_NO #{" "}
            <b className="text-blue-500">{log.po_no}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.level3_approval:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had <b className="text-green-500">approved</b> the {log.log_type} of PO_NO #{" "}
            <b className="text-blue-500">{log.po_no}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.item_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">Updated </span> Item.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Updated Qty</th>
                <th>Price</th>
                <th>Updated Price</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-green-100 text-green-800">
                <td align="center">{log.params[0]?.item_name}</td>
                <td align="center">{log.params[0]?.unit_no}</td>
                <td align="center">{log.params[0]?.updated_unit_no}</td>
                <td align="center">{log.params[0]?.price}</td>
                <td align="center">{log.params[0]?.updated_price}</td>
                {/* <td align="center">
                  {log.params.item.price * log.params.item.qty}
                </td> */}
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.item_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-green-500 font-semibold">added </span> Item.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-green-100 text-green-800">
                {/* <td align="left">- Previous</td> */}
                <td align="center">{log.params[0].item_name}</td>
                <td align="center">{log.params[0].unit_no}</td>
                <td align="center">{log.params[0].price}</td>
                {/* <td align="center">
                  {log.params.pre_value.price * log.params.pre_value.unit_no}
                </td> */}
              </tr>
              {/* <tr className="border-b bg-green-100 text-green-800">
                <td align="left">+ New</td>
                <td align="center">{log.params.new_value.item.name}</td>
                <td align="center">{log.params.new_value.warehouse.name}</td>
                <td align="center">{log.params.new_value.projectFrom}</td>
                <td align="center">{log.params.new_value.unit_no}</td>
                <td align="center">{log.params.new_value.price}</td>
                <td align="center">
                  {log.params.new_value.price * log.params.new_value.unit_no}
                </td>
              </tr> */}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.item_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-red-500 font-semibold">Removed </span> Item.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b   bg-red-100 text-red-800">
                <td align="center">{log.params[0].item_name}</td>
                <td align="center">{log.params[0].unit_no}</td>
                <td align="center">{log.params[0].price}</td>
              </tr>
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case TransferLogStatus.field_updated:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">updated </span>.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="text-left p-2">Field</th>
                <th className="text-left p-2">Old Value</th>
                <th className="text-left p-2">New Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.old).map((key) => {
                const oldValue = log.params.old[key];
                const newValue = log.params.changes[key];

                // Function to format dates
                const formatDate = (date: any) => {
                  return date ? moment(date).format("DD-MM-YYYY") : date;
                };

                // Render the party name if the key is 'party'
                const getPartyName = (party: any) => {
                  if (party && party.name) {
                    return party.name; // Return the name of the party if available
                  }
                  return "No party name available"; // Fallback message if no name is present
                };

                return (
                  <tr key={key} className="border-b">
                    <td className="p-2">{key}</td>
                    <td className="p-2 text-gray-700">
                      {/* Check if the key is 'party' and display the name */}
                      {key === "party" || key === "warehouse"
                        ? getPartyName(oldValue)
                        : key === "delivery_date" || key === "valid_till_date"
                        ? formatDate(oldValue)
                        : JSON.stringify(oldValue)}
                    </td>
                    <td className="p-2 text-gray-700">
                      {/* Check if the key is 'party' and display the name */}
                      {key === "party" || key === "warehouse"
                        ? newValue
                        : key === "delivery_date" || key === "valid_till_date"
                        ? formatDate(newValue)
                        : JSON.stringify(newValue)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    default:
      return <>Not handled {log.log_type}</>;
  }
};
