import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import { ItemFilterData, ItemModelV2 } from "../../models/item_model";

export const createItemRepo = async (data: ItemModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items`;
  const response = await fetchPost(url, data);
  return response;
};

export const saveAsDraftRepo = async (data: ItemModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/save-draft`;
  const response = await fetchPost(url, data);
  return response;
};

export const updateItemRepo = async (data: ItemModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${data.id}`;
  const response = await fetchPut(url, data);
  return response;
};

export const getItemsRepo = async (prop: ItemFilterData) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items`;
  const response = await fetchGet(url + toQueryString(prop));
  return response;
};

export const getSingleItemRepo = async (id: string) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items`;
  const res = await fetchGet(url + "/" + id);
  return res;
};

export const deleteItem = async (data: ItemModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${data.id}`;
  const res = await fetchDelete(url, data);
  return res;
};
export const approveItem = async (data: ItemModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/approve`;
  const res = await fetchPut(url, data);
  return res;
};

export const rejectItem = async (data: ItemModelV2) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/reject`;
  const res = await fetchPut(url, data);
  return res;
};

export const bulkApproveItemList = async (data: ItemModelV2[]) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/bulk-approve`;
  const res = await fetchPut(url, data);
  return res;
};
