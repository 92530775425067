import moment from "moment";
import { ItemLogsModel } from "../../models/item_model";
import { MasterItemActions } from "../enums/enums";

export const GetItemLogTemplate = (log: ItemLogsModel) => {
  const renderRows = (params: any) => {
    const rows: JSX.Element[] = [];

    const traverseParams = (obj: { [x: string]: any }, path: string[] = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (
          typeof value === "object" &&
          value !== null &&
          "original" in value &&
          "updated" in value
        ) {
          const fieldName = [...path, key].join(" ").replace(/_/g, " ");
          rows.push(
            <tr key={fieldName} className="border-b">
              <td className="text-left capitalize font-semibold">
                {fieldName}
              </td>
              <td className="text-center bg-red-100 text-red-800">
                {value.original || "-"}
              </td>
              <td className="text-center bg-green-100 text-green-800">
                {value.updated || "-"}
              </td>
            </tr>
          );
        } else if (typeof value === "object" && value !== null) {
          traverseParams(value, [...path, key]);
        }
      });
    };

    traverseParams(params);
    return rows;
  };

  switch (log.operation) {
    case MasterItemActions.Created:
      return (
        <>
          <p className="text-sm">
            <b>{log.created_by}</b> has created the Item #{" "}
            <b className="text-blue-500">{log.item_code}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case MasterItemActions.Updated: {
      const rows = renderRows(log.params);

      return (
        <>
          <p className="text-sm">
            <b>{log.created_by}</b> has updated the voucher.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Field</th>
                <th>Previous Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    }

    case MasterItemActions.Approved: {
      const rows = renderRows(log.params);

      return (
        <>
          <p className="text-sm">
            <b>{log.created_by}</b> has{" "}
            <b className="text-blue-500">Approved</b> the Item.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Field</th>
                <th>Previous Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    }
    case MasterItemActions.Rejected: {
      const rows = renderRows(log.params);

      return (
        <>
          <p className="text-sm">
            <b>{log.created_by}</b> has <b className="text-red-500">Rejected</b>{" "}
            the Item.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th>Field</th>
                <th>Previous Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    }

    default:
      return <>Not handled {log.operation}</>;
  }
};
