import { parseNumber } from "../../../../../utils/import_export_parser";

export const formToObject = (form: FormData) => {
  let data: { [key: string]: any } = {};
  //  let i = 0;
  for (let [key, value] of form) {
    const t: any = value;
    if (!isNaN(t)) {
      data[key] = parseInt(t);
    } else if (t === "true") {
      data[key] = true;
    } else if (t === "false") {
      data[key] = false;
    } else {
      data[key] = value;
    }
  }
  return data;
};

export const customEVehicleFormToObject = (form: FormData) => {
  let data: { [key: string]: any } = {};
  //  let i = 0;
  for (let [key, value] of form) {
    const t: any = value;
    if (t === "") {
      // Preserve empty strings as they are
      data[key] = "";
    } else if (!isNaN(t)) {
      data[key] = parseNumber(t);
    } else if (t === "true") {
      data[key] = true;
    } else if (t === "false") {
      data[key] = false;
    } else {
      data[key] = value;
    }
  }
  return data;
};
