import { Note, Tune } from "@material-ui/icons";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { ComplaintLogModel } from "../../../../service/models/complaint_model";
import { InventoryLogModel } from "../../../../service/models/inventory/inventory_model";
import { GetInventoryLogsApi } from "../../../../service/repos/inventory_repo";
import { getComplaintLogs } from "../../../../service/repos/support/complaint_repo";
import { inventoryLogs } from "../../../../utils/enums";
import LoadingWidget from "../../../components/loading_spinner";
export interface InventoryLog {
  id?: string;
  user_id: any;
  user_name: string;
  inventory_id: string;
  log_type: string;
  params?: any;
  created_at?: number;
}

interface HistoryWidgetProps {
  inventory_id: number;
}

const HistoryWidget: FunctionComponent<HistoryWidgetProps> = ({
  inventory_id,
}) => {
  const { showToast } = useToast();

  const [logs, setLogs] = useState<any[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const getComments = async () => {
    setLoading(true);
    const res = await GetInventoryLogsApi(inventory_id);
    if (res.success) {
      console.log(res.data);
      setLogs(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getComments();
  }, []);
  return (
    <>
      <div className="flex-col justify-between h-full">
        <div
          className="overflow-auto p-1 bg-gray-200"
          style={{ height: "97%" }}
        >
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div key={i} className="  mb-2 shadow rounded bg-white p-1">
              {log.log_type ? (
                getInventoryLogTemplate(log)
              ) : (
                <div>
                  <p className="text-sm"> {log.log}</p>
                  <div className="flex justify-end items-end py-2">
                    <span className="text-xs italic">
                      {log.created_at
                        ? moment(log.created_at).format("lll")
                        : "--"}
                    </span>
                  </div>
                  {/* <hr className="my-2" /> */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>{" "}
      <LoadingWidget loading={loading} />
    </>
  );
};

export default HistoryWidget;
const getInventoryLogTemplate = (log: InventoryLog) => {
  switch (log.log_type) {
    case inventoryLogs.created:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has created the inventory. Device ID :{" "}
            <b className="text-blue-500">{log.params.device_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.updated:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has updated the inventory.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Field</th>
                <th>Previous value</th>
                <th>New value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.changes).map((l, i) => (
                <tr key={i} className="border-b">
                  <td align="center">{l}</td>
                  <td align="center" className="text-red-400">
                    {log.params.old[l]}
                  </td>
                  <td align="center" className="text-green-400">
                    {log.params.changes[l]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has deleted the inventory. Device ID :{" "}
            <b className="text-blue-500">{log.params.device_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.commented:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has commented.{" "}
          </p>
          <p className="text-xs italic">{log.params.comment}</p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.complaint:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has created complaint with ID :{" "}
            <b className="text-blue-500">{log.params.complaint_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.installed:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has marked as Installed to Order ID :
            <b className="text-blue-500">{log.params.order_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.delivered:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has marked as Delivered to Order ID :
            <b className="text-blue-500">{log.params.order_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.defect:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has marked as defect
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.missing:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has marked as missing
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.returned:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> marked as returned from Order Id:
            <b className="text-blue-500">{log.params.order_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
    case inventoryLogs.org_changed:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b> has updated the organization.
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="">Field</th>
                <th>Previous value</th>
                <th>New value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.changes).map((l, i) => (
                <tr key={i} className="border-b">
                  <td align="center">{l}</td>
                  <td align="center" className="text-red-400">
                    {log.params.old[l]}
                  </td>
                  <td align="center" className="text-green-400">
                    {log.params.changes[l]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );

    default:
      return (
        <>
          <p className="text-sm">
            <b>{log.user_name}</b>
          </p>{" "}
          <p>
            <span className="text-xs italic">{log.log_type} </span>
            Not handled
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {log.created_at ? moment(log.created_at).format("lll") : "--"}
            </span>
          </div>
        </>
      );
  }
};
