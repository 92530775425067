import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { fetchGet, toQueryString } from "../../../../service/api_client";
import Pagination from "../../../../ui/new_components/common/pagination";
import LoadingWidget from "../../../../ui/components/loading_spinner";
import { PaymentRequestAdvanceModel } from "../../model/payment_request_model";

export const PaymentRequestLogStatus = {
  created: "created",
  updated: "updated",
  pr_status_update: "PR Status Updated",
  payment_status_update: "Payment Status",
  attachment_added: "Attachment Added",
  attachment_deleted: "Attachment Deleted",
};

export const GetAdvancePRLogsApi = async (data: {
  log_type?: string;
  pr_id: string;
  data: any;
  page?: number;
  count?: number;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/erp/purchase-order/advance_payment_request/${
      data.pr_id
    }/logs${toQueryString(data)}`;
    const res = await fetchGet(url);
    // console.log("Result of log:", res)
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export interface AdvancePaymentRequestLogModel {
  id?: string;
  operation?: String;
  user_id: any;
  pr_id: string;
  created_at?: number;
  user: string;
  log_type?: string;
  params?: any;
}
interface ActionHistoryProps {
  data: PaymentRequestAdvanceModel;
  pr_id: string;
  log_type?: string;
}

const ActionHistoryAdvance: FunctionComponent<ActionHistoryProps> = (props) => {
  const [logs, setLogs] = useState<AdvancePaymentRequestLogModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count] = useState(10);
  const [total, setTotal] = useState(0);
  // console.log("logs data", logs);
  useEffect(() => {
    GetAdvancePRLogsApi({
      pr_id: props.pr_id,
      data: props.data,
      page,
      count,
      log_type: props.log_type,
    })
      .then((res) => {
        if (res.success) {
          setLogs(res.data.logs);

          if (res.data.metadata[0]) {
            setTotal(res.data.metadata[0].total);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    scrollBottom();
  }, [logs]);
  const scrollBottom = () => {
    const container = document.getElementById("history_wrap");
    if (container) container.scrollTop = container.scrollHeight;
  };
  return (
    <div className=" ">
      <div className="flex flex-col justify-between h-full">
        <div
          id="history_wrap"
          className="overflow-auto p-1 bg-gray-100"
          style={{ height: "88vh" }}
        >
          {logs.length === 0 && (
            <p className="text-center my-10">No History!</p>
          )}
          {logs.map((log, i) => (
            <div key={i} className="  mb-2 shadow rounded bg-white p-1">
              {log.log_type ? (
                getAdvancePRLogTemplate(log)
              ) : (
                <div>
                  <b>{log.user}</b>
                  <p className="text-sm"> {log.operation}</p>
                  <div className="flex justify-end items-end py-2">
                    <span className="text-xs italic">
                      {moment(log.created_at).format("lll")}
                    </span>
                  </div>
                  {/* <hr className="my-2" /> */}
                </div>
              )}
            </div>
          ))}
        </div>
        <Pagination
          page={page}
          total={total}
          count={count}
          itemCount={logs.length}
          onChange={(page: number) => setPage(page)}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ActionHistoryAdvance;

const getAdvancePRLogTemplate = (log: AdvancePaymentRequestLogModel) => {
  // console.log("log:", log);
  switch (log.log_type) {
    case PaymentRequestLogStatus.created:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has <b className="text-blue-500">created</b> the PR_ID #{" "}
            <b className="text-blue-500">{log.pr_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case PaymentRequestLogStatus.attachment_added:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> <b className="text-blue-500">uploaded</b> an
            attachment to PR_ID # <b className="text-blue-500">{log.pr_id}</b>{" "}
            under <b className="text-blue-500">"{log.params[0]?.category}"</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case PaymentRequestLogStatus.attachment_deleted:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had <b className="text-red-500">deleted</b> an
            attachment to PR_ID # <b className="text-blue-500">{log.pr_id}</b>{" "}
            with category{" "}
            <b className="text-blue-500">"{log.params[0]?.category}"</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case PaymentRequestLogStatus.payment_status_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had{" "}
            <b className="text-green-500">updated</b> Payment Status to the{" "}
            <b className="text-green-500">{log.params?.payment_status}</b> of PR_ID #{" "}
            <b className="text-blue-500">{log.pr_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case PaymentRequestLogStatus.pr_status_update:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> had{" "}
            <b className="text-green-500">updated</b> PR Status to the{" "}
            <b className="text-green-500">{log.params?.pr_status}</b> of PR_ID #{" "}
            <b className="text-blue-500">{log.pr_id}</b>
          </p>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    case PaymentRequestLogStatus.updated:
      return (
        <>
          <p className="text-sm">
            <b>{log.user}</b> has{" "}
            <span className="text-yellow-500 font-semibold">
              updated PR_ID: {log.pr_id}
            </span>
            .
          </p>
          <table className="border rounded w-full overflow-auto text-xs">
            <thead className="border rounded">
              <tr>
                <th className="text-left p-2">Field</th>
                <th className="text-left p-2">Old Value</th>
                <th className="text-left p-2">New Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(log.params.old).map((key) => {
                const oldValue = log.params.old[key];
                const newValue = log.params.changes[key];

                // Function to format dates
                const formatDate = (date: any) => {
                  return date ? moment(date).format("DD-MM-YYYY") : date;
                };

                // Render the party name if the key is 'party'
                const getPartyName = (party: any) => {
                  if (party && party.name) {
                    return party.name; // Return the name of the party if available
                  }
                  return "No party name available"; // Fallback message if no name is present
                };

                return (
                  <tr key={key} className="border-b">
                    <td className="p-2">{key}</td>
                    <td className="p-2 text-gray-700">
                      {/* Check if the key is 'party' and display the name */}
                      {key === "party" || key === "warehouse"
                        ? getPartyName(oldValue)
                        : key === "delivery_date" || key === "valid_till_date"
                        ? formatDate(oldValue)
                        : JSON.stringify(oldValue)}
                    </td>
                    <td className="p-2 text-gray-700">
                      {/* Check if the key is 'party' and display the name */}
                      {key === "party" || key === "warehouse"
                        ? newValue
                        : key === "delivery_date" || key === "valid_till_date"
                        ? formatDate(newValue)
                        : JSON.stringify(newValue)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-end items-end py-1">
            <span className="text-xs italic">
              {moment(log.created_at).format("lll")}
            </span>
          </div>
        </>
      );
    default:
      return <>Not handled {log.log_type}</>;
  }
};
